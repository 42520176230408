import React, { useState } from "react";
import styled from "styled-components";
import Collapsible from "react-collapsible";
import { DayPicker } from "react-day-picker";
import { textToLowerCaseWithDash } from "../utils/common";
import {
  addMonths,
  // isSameMonth,
  format,
  isAfter,
  isBefore,
  isValid,
  parse,
} from "date-fns";

// Images & Icons
import dropdown_green_icon from "../assets/icons/dropdown_green_icon.svg";

function FilterModal({
  show,
  handleClose,
  statusList,
  roleList,
  topics,
  mentorList,
  filterKey,
  handleOnFilter,
  dateTimeButtonList,
  isFilterChanged,
  user_detail,
  filterStatus,
  filterTopic,
  filterDateTime,
  filterMentor,
  filterRole,
  smallScreen,
  internalDashboard,
  customDateTime,
  customMentorLabel,
}) {
  const [isOpen, setIsOpen] = useState({
    0: true,
    1: false,
    2: false,
    3: false,
  });
  const today = new Date();
  const nextMonth = addMonths(new Date(), 1);
  const [month, setMonth] = useState(nextMonth);
  const [selectedDateTimeButton, setSelectedDateTimeButton] = useState(null);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  const handleFromChange = (e) => {
    setFromValue(e.target.value);
    const date = parse(e.target.value, "y-MM-dd", new Date());
    if (!isValid(date)) {
      return handleOnFilter("date-time", "datetime", {
        from: undefined,
        to: undefined,
      });
    }
    if (filterKey.dateTime?.to && isAfter(date, filterKey.dateTime.to)) {
      handleOnFilter("date-time", "datetime", {
        from: filterKey.dateTime.to,
        to: date,
      });
    } else {
      handleOnFilter("date-time", "datetime", {
        from: date,
        to: filterKey.dateTime?.to,
      });
      setMonth(date);
    }
  };

  const handleToChange = (e) => {
    setToValue(e.target.value);
    const date = parse(e.target.value, "y-MM-dd", new Date());

    if (!isValid(date)) {
      return handleOnFilter("date-time", "datetime", {
        from: filterKey.dateTime?.from,
        to: undefined,
      });
    }
    if (filterKey.dateTime?.from && isBefore(date, filterKey.dateTime.from)) {
      handleOnFilter("date-time", "datetime", {
        from: date,
        to: filterKey.dateTime.from,
      });
    } else {
      handleOnFilter("date-time", "datetime", {
        from: filterKey.dateTime?.from,
        to: date,
      });
      setMonth(date - 1);
    }
  };

  const handleRangeSelect = (range) => {
    handleOnFilter("date-time", "datetime", range);
    if (range?.from) {
      setFromValue(format(range.from, "y-MM-dd"));
    } else {
      setFromValue("");
    }
    if (range?.to) {
      setToValue(format(range.to, "y-MM-dd"));
    } else {
      setToValue("");
    }
  };

  function handleChooseDateTime(type) {
    setSelectedDateTimeButton(type);
    switch (type) {
      case "this-week":
        let curr = new Date();
        let first = curr.getDate() - curr.getDay();
        let last = first + 6;

        let from = new Date(curr.setDate(first));
        let to = new Date(curr.setDate(last));

        if (from) {
          setFromValue(format(from, "y-MM-dd"));
        } else {
          setFromValue("");
        }
        if (to) {
          setToValue(format(to, "y-MM-dd"));
        } else {
          setToValue("");
        }

        handleOnFilter("date-time", "datetime", { from, to });
        break;
      case "this-month":
        const now = new Date();
        const date = new Date(now.getFullYear(), now.getMonth(), 1);

        const dates = [];

        while (date.getMonth() === now.getMonth()) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
        if (dates[0]) {
          setFromValue(format(dates[0], "y-MM-dd"));
        } else {
          setFromValue("");
        }
        if (dates[dates.length - 1]) {
          setToValue(format(dates[dates.length - 1], "y-MM-dd"));
        } else {
          setToValue("");
        }
        handleOnFilter("date-time", "datetime", {
          from: dates[0],
          to: dates[dates.length - 1],
        });
        break;
      default:
        break;
    }
  }

  function goToDate() {
    setMonth(month);
  }

  return (
    <>
      <Modal show={show} smallScreen={smallScreen} user_detail={user_detail}>
        <ButtonGroup>
          {Object.keys(filterKey).some(
            (key) =>
              filterKey[key].length > 0 ||
              (filterKey[key].from && filterKey[key].to)
          ) && (
            <Button
              color="#AAAAAA"
              onClick={() => {
                handleOnFilter("reset");
                setFromValue("");
                setToValue("");
              }}
            >
              Reset
            </Button>
          )}
          <Button
            color={
              user_detail &&
              user_detail.role &&
              user_detail.role.id &&
              +user_detail.role.id === 3
                ? "#2c65f7"
                : "#00BB99"
            }
            disabled={!isFilterChanged}
            onClick={(e) => {
              e.stopPropagation();
              isFilterChanged && handleOnFilter("apply-filter");
            }}
          >
            Apply Filter
          </Button>
        </ButtonGroup>
        {filterRole ? (
          <CustomCollapsible
            open
            transitionTime={150}
            onOpen={() => setIsOpen((prev) => ({ ...prev, 0: true }))}
            onClose={() => setIsOpen((prev) => ({ ...prev, 0: false }))}
            trigger={
              <Title user_detail={user_detail}>
                <ImageContainer open={isOpen[0]}>
                  <img
                    src={dropdown_green_icon}
                    alt="dropdown_green_icon"
                    width={12}
                    height={8}
                  />
                </ImageContainer>
                Role
              </Title>
            }
          >
            <Content smallScreen={smallScreen}>
              <CheckBoxButton>
                <input
                  type="checkbox"
                  id={textToLowerCaseWithDash("select-all-status")}
                  name={textToLowerCaseWithDash("select-all-status")}
                  value={textToLowerCaseWithDash("select-all-status")}
                  onChange={(e) => {
                    if (filterKey.role.length < roleList.length) {
                      for (let i = 0; i < roleList.length; i++) {
                        handleOnFilter("role", roleList[i], true);
                      }
                    } else {
                      for (let i = 0; i < roleList.length; i++) {
                        handleOnFilter("role", roleList[i], false);
                      }
                    }
                  }}
                  checked={filterKey.role.length >= roleList.length}
                />
                <label
                  htmlFor={textToLowerCaseWithDash("select-all-status")}
                  style={{
                    fontSize: 12,
                    cursor: "pointer",
                    fontWeight:
                      filterKey.role.length >= roleList.length
                        ? "bold"
                        : "normal",
                  }}
                >
                  Select All
                </label>
              </CheckBoxButton>
              {roleList.map((role, index) => (
                <CheckBoxButton key={index}>
                  <input
                    type="checkbox"
                    id={textToLowerCaseWithDash(role)}
                    name={textToLowerCaseWithDash(role)}
                    value={role}
                    onChange={(e) => {
                      handleOnFilter("role", e.target.value, e.target.checked);
                    }}
                    checked={filterKey.role.includes(role)}
                  />
                  <label
                    htmlFor={textToLowerCaseWithDash(role)}
                    style={{
                      fontSize: 12,
                      cursor: "pointer",
                      fontWeight: filterKey.role.includes(role)
                        ? "bold"
                        : "normal",
                    }}
                  >
                    {role}
                  </label>
                </CheckBoxButton>
              ))}
            </Content>
          </CustomCollapsible>
        ) : null}
        {filterStatus ? (
          <CustomCollapsible
            open
            transitionTime={150}
            onOpen={() => setIsOpen((prev) => ({ ...prev, 0: true }))}
            onClose={() => setIsOpen((prev) => ({ ...prev, 0: false }))}
            trigger={
              <Title user_detail={user_detail}>
                <ImageContainer open={isOpen[0]}>
                  <img
                    src={dropdown_green_icon}
                    alt="dropdown_green_icon"
                    width={12}
                    height={8}
                  />
                </ImageContainer>
                Status
              </Title>
            }
          >
            <Content smallScreen={smallScreen}>
              <CheckBoxButton>
                <input
                  type="checkbox"
                  id={textToLowerCaseWithDash("select-all-status")}
                  name={textToLowerCaseWithDash("select-all-status")}
                  value={textToLowerCaseWithDash("select-all-status")}
                  onChange={(e) => {
                    if (filterKey.status.length < statusList.length) {
                      for (let i = 0; i < statusList.length; i++) {
                        handleOnFilter("status", statusList[i], true);
                      }
                    } else {
                      for (let i = 0; i < statusList.length; i++) {
                        handleOnFilter("status", statusList[i], false);
                      }
                    }
                  }}
                  checked={filterKey.status.length >= statusList.length}
                />
                <label
                  htmlFor={textToLowerCaseWithDash("select-all-status")}
                  style={{
                    fontSize: 12,
                    cursor: "pointer",
                    fontWeight:
                      filterKey.status.length >= statusList.length
                        ? "bold"
                        : "normal",
                  }}
                >
                  Select All
                </label>
              </CheckBoxButton>
              {statusList.map((status, index) => (
                <CheckBoxButton key={index}>
                  <input
                    type="checkbox"
                    id={textToLowerCaseWithDash(status)}
                    name={textToLowerCaseWithDash(status)}
                    value={status}
                    onChange={(e) => {
                      handleOnFilter(
                        "status",
                        e.target.value,
                        e.target.checked
                      );
                    }}
                    checked={filterKey.status.includes(status)}
                  />
                  <label
                    htmlFor={textToLowerCaseWithDash(status)}
                    style={{
                      fontSize: 12,
                      cursor: "pointer",
                      fontWeight: filterKey.status.includes(status)
                        ? "bold"
                        : "normal",
                    }}
                  >
                    {status}
                  </label>
                </CheckBoxButton>
              ))}
            </Content>
          </CustomCollapsible>
        ) : null}
        {filterTopic ? (
          <CustomCollapsible
            open
            transitionTime={150}
            onOpen={() => setIsOpen((prev) => ({ ...prev, 1: true }))}
            onClose={() => setIsOpen((prev) => ({ ...prev, 1: false }))}
            trigger={
              <Title user_detail={user_detail}>
                <ImageContainer open={isOpen[1]}>
                  <img
                    src={dropdown_green_icon}
                    alt="dropdown_green_icon"
                    width={12}
                    height={8}
                  />
                </ImageContainer>
                Topic
              </Title>
            }
          >
            <Content smallScreen={smallScreen}>
              <TopicButtonGroup>
                <div>
                  <input
                    type="checkbox"
                    id={textToLowerCaseWithDash("select-all-topic")}
                    name={textToLowerCaseWithDash("select-all-topic")}
                    value={textToLowerCaseWithDash("select-all-topic")}
                    onChange={(e) => {
                      if (filterKey.topic.length < topics.length) {
                        for (let i = 0; i < topics.length; i++) {
                          handleOnFilter(
                            "topic",
                            topics[i].id.toString(),
                            true
                          );
                        }
                      } else {
                        for (let i = 0; i < topics.length; i++) {
                          handleOnFilter(
                            "topic",
                            topics[i].id.toString(),
                            false
                          );
                        }
                      }
                    }}
                    style={{
                      display: "none",
                    }}
                    checked={filterKey.topic.length >= topics.length}
                  />
                  <TopicButton
                    checked={filterKey.topic.length >= topics.length}
                    htmlFor={textToLowerCaseWithDash("select-all-topic")}
                    user_detail={user_detail}
                  >
                    Select All
                  </TopicButton>
                </div>
                {topics.map((topic, index) => (
                  <div key={index}>
                    <input
                      type="checkbox"
                      id={textToLowerCaseWithDash(topic.title)}
                      name={textToLowerCaseWithDash(topic.title)}
                      value={topic.id.toString()}
                      onChange={(e) => {
                        handleOnFilter(
                          "topic",
                          e.target.value,
                          e.target.checked
                        );
                      }}
                      style={{
                        display: "none",
                      }}
                      checked={filterKey.topic.includes(topic.id.toString())}
                    />
                    <TopicButton
                      checked={filterKey.topic.includes(topic.id.toString())}
                      htmlFor={textToLowerCaseWithDash(topic.title)}
                      user_detail={user_detail}
                    >
                      {topic.title}
                    </TopicButton>
                  </div>
                ))}
              </TopicButtonGroup>
            </Content>
          </CustomCollapsible>
        ) : null}
        {filterDateTime ? (
          <CustomCollapsible
            open={internalDashboard}
            transitionTime={150}
            onOpen={() => setIsOpen((prev) => ({ ...prev, 2: true }))}
            onClose={() => setIsOpen((prev) => ({ ...prev, 2: false }))}
            trigger={
              <Title user_detail={user_detail}>
                <ImageContainer open={isOpen[2]}>
                  <img
                    src={dropdown_green_icon}
                    alt="dropdown_green_icon"
                    width={12}
                    height={8}
                  />
                </ImageContainer>
                {customDateTime ? customDateTime : "Date/Time"}
              </Title>
            }
          >
            <Content smallScreen={smallScreen}>
              <CustomDayPickerForm>
                <CustomDayPickerInput
                  size={12}
                  placeholder="YYYY-MM-DD"
                  value={fromValue}
                  onChange={handleFromChange}
                />
                -
                <CustomDayPickerInput
                  size={12}
                  placeholder="YYYY-MM-DD"
                  value={toValue}
                  onChange={handleToChange}
                  disabled={!filterKey.dateTime.from}
                />
              </CustomDayPickerForm>
              <DayPickerContainer user_detail={user_detail}>
                <DayPicker
                  mode="range"
                  selected={filterKey.dateTime}
                  onSelect={(value) => {
                    setSelectedDateTimeButton(null);
                    handleOnFilter("date-time", "datetime", value);
                    handleRangeSelect(value);
                  }}
                  numberOfMonths={smallScreen ? 1 : 2}
                  pagedNavigation
                  captionLayout="dropdown"
                  fromYear={2015}
                  toYear={2030}
                  month={month}
                  onMonthChange={setMonth}
                  goToDate={goToDate}
                />
              </DayPickerContainer>
              <TopicButtonGroup>
                {dateTimeButtonList.map((button, index) => (
                  <CheckBoxButton onClick={() => setMonth(today)} key={index}>
                    <input
                      type="radio"
                      id={textToLowerCaseWithDash(button)}
                      name={"date-time"}
                      value={textToLowerCaseWithDash(button)}
                      onChange={() => {
                        handleChooseDateTime(textToLowerCaseWithDash(button));
                      }}
                      style={{
                        display: "none",
                      }}
                    />
                    <TopicButton
                      checked={
                        textToLowerCaseWithDash(button) ===
                        selectedDateTimeButton
                      }
                      htmlFor={textToLowerCaseWithDash(button)}
                      style={{
                        fontSize: 12,
                        cursor: "pointer",
                      }}
                      user_detail={user_detail}
                    >
                      {button}
                    </TopicButton>
                  </CheckBoxButton>
                ))}
              </TopicButtonGroup>
            </Content>
          </CustomCollapsible>
        ) : null}
        {filterMentor ? (
          <CustomCollapsible
            transitionTime={150}
            onOpen={() => setIsOpen((prev) => ({ ...prev, 3: true }))}
            onClose={() => setIsOpen((prev) => ({ ...prev, 3: false }))}
            trigger={
              <Title user_detail={user_detail}>
                <ImageContainer open={isOpen[3]}>
                  <img
                    src={dropdown_green_icon}
                    alt="dropdown_green_icon"
                    width={12}
                    height={8}
                  />
                </ImageContainer>
                {customMentorLabel ? customMentorLabel : "Mentor"}
              </Title>
            }
          >
            <Content smallScreen={smallScreen}>
              <TopicButtonGroup>
                <div>
                  <input
                    type="checkbox"
                    id={textToLowerCaseWithDash("select-all-mentor")}
                    name={textToLowerCaseWithDash("select-all-mentor")}
                    value={textToLowerCaseWithDash("select-all-mentor")}
                    onChange={(e) => {
                      if (filterKey.mentor.length < mentorList.length) {
                        for (let i = 0; i < mentorList.length; i++) {
                          handleOnFilter("mentor", mentorList[i], true);
                        }
                      } else {
                        for (let i = 0; i < mentorList.length; i++) {
                          handleOnFilter("mentor", mentorList[i], false);
                        }
                      }
                    }}
                    style={{
                      display: "none",
                    }}
                    checked={filterKey.mentor.length >= mentorList.length}
                  />
                  <TopicButton
                    checked={filterKey.mentor.length >= mentorList.length}
                    htmlFor={textToLowerCaseWithDash("select-all-mentor")}
                    user_detail={user_detail}
                  >
                    Select All
                  </TopicButton>
                </div>
                {mentorList.map((mentor, index) => (
                  <div key={index}>
                    <input
                      type="checkbox"
                      id={textToLowerCaseWithDash(mentor)}
                      name={textToLowerCaseWithDash(mentor)}
                      value={mentor}
                      onChange={(e) => {
                        handleOnFilter(
                          "mentor",
                          e.target.value,
                          e.target.checked
                        );
                      }}
                      style={{
                        display: "none",
                      }}
                      checked={filterKey.mentor.includes(mentor)}
                    />
                    <TopicButton
                      checked={filterKey.mentor.includes(mentor)}
                      htmlFor={textToLowerCaseWithDash(mentor)}
                      user_detail={user_detail}
                    >
                      {mentor}
                    </TopicButton>
                  </div>
                ))}
              </TopicButtonGroup>
            </Content>
          </CustomCollapsible>
        ) : null}
      </Modal>
      <ModalOverlay show={show} onClick={handleClose}></ModalOverlay>
    </>
  );
}

export default FilterModal;

const Modal = styled.div`
  position: absolute;
  background-color: #fff;
  width: ${({ smallScreen }) => (smallScreen === true ? "332px" : "690px")};
  min-height: 400px;
  border: ${({ user_detail }) =>
    user_detail &&
    user_detail.role &&
    user_detail.role.id &&
    +user_detail.role.id === 3
      ? "1px solid #2c65f7"
      : "1px solid #00bb99"};
  border-radius: 10px;
  margin-top: 10px;
  display: ${({ show }) => (show === "true" ? "inline-block" : "none")};
  padding: 35px 25px;
  z-index: 10000;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  transition: all 150ms ease;
`;

const ModalOverlay = styled.div`
  display: ${({ show }) => (show === "true" ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: #0f172a;
  z-index: 3000;
  opacity: ${({ show }) => (show === "true" ? "0.5" : "0")};
  transition: all 150ms ease;
`;

const CustomCollapsible = styled(Collapsible)`
  width: 80%;
`;

const Title = styled.h5`
  font-size: 14px;
  font-weight: 700;
  color: ${({ user_detail }) =>
    user_detail &&
    user_detail.role &&
    user_detail.role.id &&
    +user_detail.role.id === 3
      ? "#2c65f7"
      : "#00bb99"};
  display: flex;
  gap: 10px;
  user-select: none;
  :hover {
    opacity: 0.75;
  }
`;

const Content = styled.div`
  margin-left: ${({ smallScreen }) => (smallScreen ? "0px" : "22px")};
  margin-bottom: 10px;
`;

const ImageContainer = styled.div`
  transition: all 150ms ease;
  transform: ${({ open }) => (open ? "rotate(-180deg)" : "rotate(0deg)")};
`;

const ButtonGroup = styled.div`
  position: absolute;
  right: 0;
  padding: 0 25px;
  display: flex;
  gap: 2px;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ color }) => (color ? color : "#00BB99")};
  opacity: ${({ disabled }) => (disabled ? "0.4" : "1")};
  pointer: ${({ disabled }) => (disabled ? "none" : "auto")};
  font-size: 12px;
  font-weight: 400;
  padding: 4px 12px;
  margin-top: -4px;
  user-select: none;
  :hover {
    font-weight: ${({ disabled }) => (disabled ? "400" : "700")};
  }
`;

const CheckBoxButton = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  user-select: none;
  margin-bottom: 10px;
`;

const TopicButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 10px;
`;

const TopicButton = styled.label`
  background-color: ${({ checked, user_detail }) =>
    checked &&
    user_detail &&
    user_detail.role &&
    user_detail.role.id &&
    +user_detail.role.id === 3
      ? "#2c65f7"
      : checked
      ? "#00BB99"
      : "transparent"};
  color: ${({ checked, user_detail }) =>
    checked
      ? "white"
      : !checked &&
        user_detail &&
        user_detail.role &&
        user_detail.role.id &&
        +user_detail.role.id === 3
      ? "#2c65f7"
      : "#00BB99"};
  font-size: 12px;
  font-weight: 700;
  padding: 6px 12px;
  border: ${({ user_detail }) =>
    user_detail &&
    user_detail.role &&
    user_detail.role.id &&
    +user_detail.role.id === 3
      ? "1px solid #2c65f7"
      : "1px solid #00bb99"};
  border-radius: 40px;
  user-select: none;
  transition: all 150ms ease;
  cursor: pointer;
`;

const DayPickerContainer = styled.div`
  --rdp-cell-size: 33px;
  --rdp-accent-color: ${({ user_detail }) =>
    user_detail &&
    user_detail.role &&
    user_detail.role.id &&
    +user_detail.role.id === 3
      ? "#2c65f7"
      : "#00bb99"};
  --rdp-background-color: #ebfcf9;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  h2 {
    font-size: 14px;
  }
  span {
    font-size: 12px;
  }
  .rdp {
    margin: 12px 0;
  }
  .rdp-head_cell {
    color: ${({ user_detail }) =>
      user_detail &&
      user_detail.role &&
      user_detail.role.id &&
      +user_detail.role.id === 3
        ? "#2c65f7"
        : "#00bb99"};
  }
`;
const CustomDayPickerForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
`;

const CustomDayPickerInput = styled.input`
  font-size: 13px;
  padding: 5px 10px;
  text-align: center;
`;
