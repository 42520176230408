import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarComponent from "../components/SidebarComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import LoadingModalMobile from "../components/LoadingModalMobile";

// Styles
import "../styles/courses.css";

// react bootstrap
import Col from "react-bootstrap/Col";

// Images & Icons
import empty_session_2_icon from "../assets/icons/empty_session_2_icon.svg";
import my_course_trash_icon from "../assets/icons/my_course_trash_icon.svg";
import course_length_icon from "../assets/icons/course_length_icon.svg";

// Apis
import {
  getAllStudentCourses,
  addCourseToStudentCourse,
  deleteStudentCourse,
} from "../apis/clientApis/courses";
import { getAllCourses } from "../apis/courses";

// Helpers
import getTimeFromSeconds from "../helpers/get-time-from-seconds";
import { device } from "../helpers/device";
import capitalizeFirstLetter from "../helpers/capitalize_first_letter";

import font_size from "../lib/styling/font_size";

// sweetalert
import Swal from "sweetalert2";

// react-responsive
import { useMediaQuery } from "react-responsive";

const Courses = () => {
  const navigate = useNavigate();

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [studentCourses, setStudentCourses] = useState([]);
  const [studentCourseIds, setStudentCourseIds] = useState([]);
  const [showRemoveModal, setShowRemoveModal] = useState({
    isOpen: false,
    courseDetail: null,
  });

  const handleFetchAllCourses = async (finalStudentCourseIds) => {
    setIsLoading(true);
    const result = await getAllCourses();
    if (result && result.data) {
      let finalCourses = result.data;
      finalCourses =
        finalCourses && Array.isArray(finalCourses) && finalCourses.length > 0
          ? finalCourses.map((course) => {
              const { id, course_sections } = course || {};
              let totalCourseLesson = 0;
              let totalCourseDuration = 0;
              for (let cs = 0; cs < course_sections.length; cs++) {
                const { lessons, total_course_duration_in_seconds } =
                  course_sections[cs];
                totalCourseLesson += lessons.length;
                totalCourseDuration += total_course_duration_in_seconds;
              }
              totalCourseDuration = getTimeFromSeconds(totalCourseDuration);
              totalCourseDuration = `${
                totalCourseDuration.hours
                  ? `${
                      totalCourseDuration.hours > 1
                        ? `${totalCourseDuration.hours} hours`
                        : `${totalCourseDuration.hours} hour`
                    }`
                  : ``
              } ${
                totalCourseDuration.minutes
                  ? `${
                      totalCourseDuration.minutes > 1
                        ? `${totalCourseDuration.minutes} mins`
                        : `${totalCourseDuration.minutes} min`
                    }`
                  : ``
              } ${
                !totalCourseDuration.hours && totalCourseDuration.seconds
                  ? `${
                      totalCourseDuration.seconds > 1
                        ? `${totalCourseDuration.seconds} secs`
                        : `${totalCourseDuration.seconds} sec`
                    }`
                  : ``
              }`;
              let totalCourseLessonAndDuration = `${
                totalCourseLesson > 1
                  ? `${totalCourseLesson} lessons`
                  : `${totalCourseLesson} lesson`
              },${totalCourseDuration}`;
              course = {
                ...course,
                total_course_lesson_and_duration: totalCourseLessonAndDuration,
                added_to_users_course: finalStudentCourseIds
                  ? finalStudentCourseIds.includes(id)
                  : studentCourseIds.includes(id),
              };
              return course;
            })
          : [];
      setCourses(finalCourses);
    }
    setIsLoading(false);
  };

  const handleFetchStudentCourses = async () => {
    setIsLoading(true);
    const result = await getAllStudentCourses();
    if (result && result.data) {
      let finalStudentCourses = result.data;
      finalStudentCourses =
        finalStudentCourses &&
        Array.isArray(finalStudentCourses) &&
        finalStudentCourses.length > 0
          ? finalStudentCourses.map((studentCourse) => {
              const { Course: { course_sections } = {} } = studentCourse || {};
              let totalCourseLesson = 0;
              let totalCourseDuration = 0;
              for (let cs = 0; cs < course_sections.length; cs++) {
                const { lessons, total_course_duration_in_seconds } =
                  course_sections[cs];
                totalCourseLesson += lessons.length;
                totalCourseDuration += total_course_duration_in_seconds;
              }
              totalCourseDuration = getTimeFromSeconds(totalCourseDuration);
              totalCourseDuration = `${
                totalCourseDuration.hours
                  ? `${
                      totalCourseDuration.hours > 1
                        ? `${totalCourseDuration.hours} hours`
                        : `${totalCourseDuration.hours} hour`
                    }`
                  : ``
              } ${
                totalCourseDuration.minutes
                  ? `${
                      totalCourseDuration.minutes > 1
                        ? `${totalCourseDuration.minutes} mins`
                        : `${totalCourseDuration.minutes} min`
                    }`
                  : ``
              } ${
                !totalCourseDuration.hours && totalCourseDuration.seconds
                  ? `${
                      totalCourseDuration.seconds > 1
                        ? `${totalCourseDuration.seconds} secs`
                        : `${totalCourseDuration.seconds} sec`
                    }`
                  : ``
              }`;
              let totalCourseLessonAndDuration = `${
                totalCourseLesson > 1
                  ? `${totalCourseLesson} lessons`
                  : `${totalCourseLesson} lesson`
              },${totalCourseDuration}`;
              studentCourse = {
                ...studentCourse,
                Course: {
                  ...studentCourse["Course"],
                  total_course_lesson_and_duration:
                    totalCourseLessonAndDuration,
                },
              };
              return studentCourse;
            })
          : [];
      let finalStudentCourseIds = [];
      let studentCoursesResponse = result.data;
      for (let scr = 0; scr < studentCoursesResponse.length; scr++) {
        const { course_id } = studentCoursesResponse[scr];
        finalStudentCourseIds.push(course_id);
      }
      setStudentCourseIds(finalStudentCourseIds);
      setStudentCourses(finalStudentCourses);
      handleFetchAllCourses(finalStudentCourseIds);
    }
    setIsLoading(false);
  };

  const handleAddCourseToStudentCourse = async (course_id) => {
    setIsLoading(true);
    const result = await addCourseToStudentCourse(course_id);
    if (result && result.data) {
      let finalStudentCourseIds = studentCourseIds;
      finalStudentCourseIds.push(course_id);
      setStudentCourseIds(finalStudentCourseIds);
      handleFetchStudentCourses();
    }
    setIsLoading(false);
  };

  const handleRemoveCourseFromMyCourseList = async (
    student_course_id,
    course_name
  ) => {
    setIsLoading(true);
    const response = await deleteStudentCourse(student_course_id);
    if (response && response.data && response.data.success) {
      setShowRemoveModal({
        isOpen: false,
        courseDetail: null,
      });
      handleFetchStudentCourses();
      Swal.fire({
        title: "Deleted!",
        text: `Course ${course_name} file has been removed.`,
        icon: "success",
      });
    }
    setIsLoading(false);
  };

  const removeComponentModal = (showRemoveModal) => {
    const {
      courseDetail,
      courseDetail: { Course: { course_name } = {} } = {},
    } = showRemoveModal;
    let student_course_id = courseDetail.id;
    return (
      <div className="fullscreenContainer">
        <div
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            maxWidth: smallScreen ? "90%" : "38vw",
            textAlign: "center",
          }}
        >
          <div
            className="contentFullScreen"
            style={{ margin: smallScreen ? "2em" : "3em" }}
          >
            <div
              className="contentHeaderContainer"
              style={{
                fontSize: smallScreen ? "22px" : font_size.title1,
                fontWeight: "bold",
              }}
            >
              {`Are you sure you want to remove "${course_name}" course from your list?`}
            </div>
            <div
              className="contentHeaderContainer"
              style={{ fontSize: smallScreen ? "15px" : font_size.title2 }}
            >
              If yes, all your watched lesson history will be removed
              permanently
            </div>
            <div
              style={{
                display: "flex",
                gap: "1em",
                marginTop: "1em",
                fontSize: smallScreen ? "14px" : "",
                fontWeight: "bold",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f3f3f3",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleRemoveCourseFromMyCourseList(
                    student_course_id,
                    course_name
                  )
                }
              >
                <div style={{ margin: "0.75em 1.5em" }}>Yes, remove it!</div>
              </div>
              <div
                style={{
                  backgroundColor: "#2c65f7",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setShowRemoveModal({
                    isOpen: false,
                    courseDetail: null,
                  })
                }
              >
                <div style={{ margin: "0.75em 1.5em", color: "#ffffff" }}>
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    handleFetchStudentCourses();
    handleFetchAllCourses();
  }, []);

  return (
    <div>
      {showRemoveModal && showRemoveModal.isOpen
        ? removeComponentModal(showRemoveModal)
        : null}
      <HeaderComponent />
      <SidebarComponent />
      <SpinnerComponent
        isShown={isLoading && !smallScreen && !mediumScreen}
        styles={{
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "290px",
        }}
      />
      {isLoading && (smallScreen || mediumScreen) ? (
        <LoadingModalMobile />
      ) : (
        <>
          {!isLoading &&
          courses &&
          Array.isArray(courses) &&
          courses.length > 0 ? (
            <>
              <CourseContainer>
                <CourseInnerContainer className="myBookingInnerContainer">
                  <Col
                    style={{
                      textAlign: "left",
                      marginBottom: "1vw",
                    }}
                  >
                    <PageTitle>All Courses</PageTitle>
                  </Col>
                  {smallScreen ? (
                    <>
                      <div>
                        {courses.map((data) => {
                          const {
                            id,
                            course_name,
                            course_image_url,
                            total_course_lesson_and_duration,
                            added_to_users_course,
                          } = data || {};
                          return (
                            <EachMobileDataMainContainer key={id}>
                              <EachMobileDataContainer>
                                <div
                                  className="courseImageContainer"
                                  style={{ marginBottom: "0.5em" }}
                                  onClick={() => navigate(`/courses/${id}`)}
                                >
                                  <img
                                    src={course_image_url}
                                    alt="course cover"
                                  />
                                </div>
                                <div
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                    marginBottom: "0.5em",
                                  }}
                                  onClick={() => navigate(`/courses/${id}`)}
                                >
                                  {capitalizeFirstLetter(course_name)}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "0.5em",
                                    alignItems: "center",
                                  }}
                                  onClick={() => navigate(`/courses/${id}`)}
                                >
                                  <img
                                    src={course_length_icon}
                                    alt="course length icon"
                                    style={{
                                      width: "20px",
                                      height: "auto",
                                    }}
                                  />
                                  <div style={{ fontSize: "15px" }}>
                                    {total_course_lesson_and_duration}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    className="buttonAddToMyCourseContainer"
                                    style={{
                                      backgroundColor: added_to_users_course
                                        ? "#cccccc"
                                        : "#2c65f7",
                                    }}
                                    onClick={() =>
                                      added_to_users_course
                                        ? null
                                        : handleAddCourseToStudentCourse(id)
                                    }
                                  >
                                    <div>
                                      {added_to_users_course
                                        ? "Added"
                                        : "Add to my course"}
                                    </div>
                                  </div>
                                </div>
                              </EachMobileDataContainer>
                            </EachMobileDataMainContainer>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="studentCoursesCardContainer">
                        {courses.map((course, idx) => {
                          const {
                            id,
                            course_name,
                            course_image_url,
                            total_course_lesson_and_duration,
                            added_to_users_course,
                          } = course || {};
                          return (
                            <div key={idx} className="courseCardContainer">
                              <div onClick={() => navigate(`/courses/${id}`)}>
                                <div className="courseImageContainer">
                                  <img
                                    src={course_image_url}
                                    alt="course cover"
                                  />
                                </div>
                                <div className="courseTitleStyle">
                                  {capitalizeFirstLetter(course_name)}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "0.5em",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={course_length_icon}
                                    alt="course length icon"
                                    style={{
                                      width: "20px",
                                      height: "auto",
                                    }}
                                  />
                                  <div className="courseLengthStyle">
                                    {total_course_lesson_and_duration}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="buttonAddToMyCourseContainer"
                                style={{
                                  backgroundColor: added_to_users_course
                                    ? "#cccccc"
                                    : "#2c65f7",
                                }}
                                onClick={() =>
                                  added_to_users_course
                                    ? null
                                    : handleAddCourseToStudentCourse(id)
                                }
                              >
                                <div>
                                  {added_to_users_course
                                    ? "Added"
                                    : "Add to my course"}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </CourseInnerContainer>
              </CourseContainer>
              <CourseContainer>
                <MyCourseInnerContainer
                  smallScreen={smallScreen}
                  mediumScreen={mediumScreen}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: smallScreen ? "column" : "row",
                      justifyContent: "space-between",
                      alignItems: smallScreen ? "" : "center",
                    }}
                  >
                    <Col
                      style={{
                        marginTop: "20px",
                        textAlign: "left",
                        marginBottom: "1vw",
                      }}
                    >
                      <PageTitle>My Courses</PageTitle>
                    </Col>
                    <Col
                      style={{
                        marginTop: smallScreen ? "0" : "50px",
                        textAlign: smallScreen ? "left" : "right",
                        marginRight: smallScreen ? "3vw" : "0px",
                        marginBottom: "1vw",
                      }}
                    >
                      <div
                        style={{
                          fontSize: font_size.title2,
                          color: "#2c65f7",
                        }}
                      >{`${
                        studentCourses.length > 1
                          ? `${studentCourses.length} courses`
                          : `${studentCourses.length} course`
                      } on your list`}</div>
                    </Col>
                  </div>
                  {smallScreen ? (
                    <>
                      <div>
                        {studentCourses.map((studentCourse, idx) => {
                          const {
                            course_id,
                            Course: {
                              course_name,
                              course_image_url,
                              total_course_lesson_and_duration,
                            } = {},
                          } = studentCourse || {};
                          return (
                            <EachMobileDataMainContainer
                              key={idx}
                              style={{
                                backgroundColor: smallScreen ? "#ffffff" : "",
                              }}
                            >
                              <EachMobileDataContainer>
                                <div
                                  className="courseImageContainer"
                                  style={{ marginBottom: "0.5em" }}
                                  onClick={() =>
                                    navigate(`/courses/${course_id}`)
                                  }
                                >
                                  <img
                                    src={course_image_url}
                                    alt="course cover"
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    onClick={() =>
                                      navigate(`/courses/${course_id}`)
                                    }
                                  >
                                    <div
                                      style={{
                                        fontSize: "25px",
                                        fontWeight: "bold",
                                        marginBottom: "0.5em",
                                      }}
                                    >
                                      {capitalizeFirstLetter(course_name)}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "0.5em",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={course_length_icon}
                                        alt="course length icon"
                                        style={{
                                          width: "20px",
                                          height: "auto",
                                        }}
                                      />
                                      <div className="courseLengthStyle">
                                        {total_course_lesson_and_duration}
                                      </div>
                                    </div>
                                  </div>
                                  <ButtonSecondary
                                    onClick={() =>
                                      // handleRemoveCourse(studentCourse)
                                      setShowRemoveModal({
                                        isOpen: true,
                                        courseDetail: studentCourse,
                                      })
                                    }
                                  >
                                    <img
                                      src={my_course_trash_icon}
                                      alt="my_course_trash_icon"
                                      style={{
                                        width: "50px",
                                        height: "auto",
                                        objectFit: "scale-down",
                                      }}
                                    />
                                  </ButtonSecondary>
                                </div>
                              </EachMobileDataContainer>
                            </EachMobileDataMainContainer>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      {studentCourses &&
                      Array.isArray(studentCourses) &&
                      studentCourses.length > 0 ? (
                        <div className="studentCoursesCardContainer">
                          {studentCourses.map((studentCourse, idx) => {
                            const {
                              Course: {
                                id,
                                course_name,
                                course_image_url,
                                total_course_lesson_and_duration,
                              } = {},
                            } = studentCourse || {};
                            return (
                              <div key={idx} className="courseCardContainer">
                                <div>
                                  <div
                                    className="courseImageContainer"
                                    onClick={() => navigate(`/courses/${id}`)}
                                  >
                                    <img
                                      src={course_image_url}
                                      alt="course cover"
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <div
                                        className="courseTitleStyle"
                                        onClick={() =>
                                          navigate(`/courses/${id}`)
                                        }
                                      >
                                        {capitalizeFirstLetter(course_name)}
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "0.5em",
                                          alignItems: "center",
                                        }}
                                        onClick={() =>
                                          navigate(`/courses/${id}`)
                                        }
                                      >
                                        <img
                                          src={course_length_icon}
                                          alt="course length icon"
                                          style={{
                                            width: "20px",
                                            height: "auto",
                                          }}
                                        />
                                        <div className="courseLengthStyle">
                                          {total_course_lesson_and_duration}
                                        </div>
                                      </div>
                                    </div>
                                    <ButtonSecondary
                                      onClick={() =>
                                        // handleRemoveCourse(studentCourse)
                                        setShowRemoveModal({
                                          isOpen: true,
                                          courseDetail: studentCourse,
                                        })
                                      }
                                    >
                                      <img
                                        src={my_course_trash_icon}
                                        alt="my_course_trash_icon"
                                        style={{
                                          width: "50px",
                                          height: "auto",
                                          objectFit: "scale-down",
                                        }}
                                      />
                                    </ButtonSecondary>
                                  </div>
                                </div>
                                {/* <div
                                  className="buttonAddToMyCourseContainer"
                                  style={{ backgroundColor: "#d33" }}
                                  onClick={() => handleRemoveCourse(studentCourse)}
                                >
                                  <div>Remove</div>
                                </div> */}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div>
                          <div
                            className="sessionHistoryInnerContainerEmptySession"
                            style={{
                              padding: "0",
                              margin: "0",
                              height: "50vh",
                            }}
                          >
                            <img
                              src={empty_session_2_icon}
                              alt="empty_session_2_icon"
                            />
                            <h4 style={{ fontSize: "22px" }}>
                              You have not added any course yet
                            </h4>
                            <p style={{ fontSize: "18px" }}>
                              Only added course will appear here
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </MyCourseInnerContainer>
              </CourseContainer>
            </>
          ) : !isLoading ? (
            <div
              className="noDataMainContainer"
              style={{
                paddingLeft: !smallScreen && !mediumScreen ? "230px" : "",
              }}
            >
              <div
                className="sessionHistoryInnerContainerEmptySession"
                style={{ padding: "0" }}
              >
                <img src={empty_session_2_icon} alt="empty_session_2_icon" />
                <h4 style={{ fontSize: "22px" }}>No course yet</h4>
                {/* <p style={{ fontSize: "18px" }}>
                  Only completed booking session will appear here
                </p> */}
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Courses;

const PageTitle = styled.h1`
  font-size: 36px;
  color: #2c65f7;
  font-weight: bold;
`;

const ButtonSecondary = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  :hover {
    font-weight: bold;
  }
`;

const EachMobileDataMainContainer = styled.div`
  border-radius: 10px;
  width: 100%;
  border: 1px solid #2c65f7;
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
`;

const EachMobileDataContainer = styled.div`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
`;

const CourseContainer = styled.div`
  @media ${device.laptopM} {
    padding-left: 290px;
  }
  @media ${device.laptopL} {
    padding-left: 290px;
  }
`;

const CourseInnerContainer = styled.div`
  padding: 80px 8vw 30px 8vw;
  text-align: left;
  @media ${device.tablet} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptop} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopM} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopL} {
    padding: 130px 3vw 30px 3vw;
  }
`;

const MyCourseInnerContainer = styled.div`
  padding: 0px 8vw 100px 8vw;
  text-align: left;
  background-color: #d3d3d3;
  @media ${device.tablet} {
    padding: 0px 3vw 100px 3vw;
  }
  @media ${device.laptop} {
    padding: 0px 3vw 100px 3vw;
  }
  @media ${device.laptopM} {
    padding: 0px 3vw 30px 3vw;
    background-color: #ffffff;
  }
  @media ${device.laptopL} {
    padding: 0px 3vw 30px 3vw;
    background-color: #ffffff;
  }
`;
