import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useDispatch, useSelector } from "react-redux";

// reducers
import { SET_USER_DETAIL } from "../store/actions";

// axios
import axios from "axios";

import styled from "styled-components";

// moment js
import moment from "moment";

// Swal
import Swal from "sweetalert2";

// react bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

// Core UI Icons
import * as icon from "@coreui/icons";
import CIcon from "@coreui/icons-react";

// Apis
import { getAllTopics } from "../apis/topics";
import {
  getAllMentorTopics,
  getAllMentorSchedules,
  getMentorSchedulesAllTime,
  getScheduleTimeslot,
  createMentorSchedule,
  createMultipleTopics,
  editMentorSchedule,
  deleteMentorSchedule,
} from "../apis/internalApis/mentors";
import { getUserDetail, updateUserRole } from "../apis/internalApis/users";
import {
  getAllCountries,
  getAllIndustries,
  getCityByCountryNameAndStateName,
  getStatesByCountryName,
} from "../apis/general";
import {
  logoutUser,
  updateEmailFromProfile,
  updatePasswordFromProfile,
  updateUserDetail,
  updateUserImageUrl,
} from "../apis/authentications";
import { getAllRoles } from "../apis/internalApis/roles";

// Helpers
import { createCountriesOptions } from "../helpers/get-country-options";
import { validatePhoneNumber } from "../helpers/validate-phone-number";
import { createStateOptions } from "../helpers/get-state-options";
import { createCityOptions } from "../helpers/get-city-options";
import capitalizeFirstLetter from "../helpers/capitalize_first_letter";

// Lib
import fontSize from "../lib/styling/font_size";

// Images & Icons
import arrow_left_icon_dark from "../assets/icons/arrow_left_icon_dark.png";
import edit_icon from "../assets/icons/edit_icon.png";
import dayoff_empty_schedule_icon from "../assets/icons/dayoff_empty_schedule_icon.png";
import warning_black_icon from "../assets/icons/warning_black_icon.png";
import add_mentor_icon from "../assets/icons/add_mentor_icon.png";
import clock_black_icon from "../assets/icons/clock_black_icon.png";
import ccs_mentor_icon from "../assets/icons/ccs_mentor_icon.png";
import email_password_update_success from "../assets/icons/email_password_update_success.png";

// Components
import CustomDropdown from "../components/input/CustomDropdown";
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import RadioButton from "../components/input/RadioButton";
import ReactDatePicker from "../components/input/DatePicker";
import TagsInput from "../components/input/TagsInput";
import ModalComponent from "../components/ModalComponent";
import MentorButton from "../components/button/MentorButton";
import ItemSlider from "../components/layout/Slider";
import ReactDayPicker from "../components/input/DayPicker";
import AlertGreenComponent from "../components/AlertGreenComponent";
import GreenButton from "../components/button/GreenButton";
import SpinnerComponent from "../components/SpinnerComponent";
import InputText from "../components/input/InputText";
import InputDropdown from "../components/input/InputDropdown";
import Tooltip from "../components/tooltip/Tooltip";

const InternalMentorDetail = () => {
  const navigate = useNavigate();
  const { user_id } = useParams();
  const dispatch = useDispatch();

  const userLoginDetail = useSelector((state) => state.userDetail);

  const [isLoading, setLoading] = useState(false);
  const [topics, setTopics] = useState([]);
  const [mentor, setMentor] = useState(null);
  const [mentorSchedules, setMentorSchedules] = useState(null);

  const [infoName, setInfoName] = useState("personal");
  const [isModalAssignedTopic, setModalAssignedTopic] = useState(false);
  const [isModalSchedule, setModalSchedule] = useState(false);
  const [industries, setIndustries] = useState([]);
  // const [genders, setGenders] = useState(["male", "female"]);
  const [timeslots, setTimeslots] = useState([
    "Single slot",
    "Multiple time slots",
  ]);
  const [mentorSchedulesAll, setMentorSchedulesAll] = useState([]);
  const [skills, setSkills] = useState([]);
  const [schedule, setSchedule] = useState(mentorSchedules);
  const [allTopicsMentor, setAllTopicsMentor] = useState([]);
  const [assignedGender, setAssignedGender] = useState(null);
  // const [assignedTitle, setAssignedTitle] = useState(null);
  // const [assignedCountry, setAssignedCountry] = useState(null);
  const [assignedIndustry, setAssignedIndustry] = useState(null);
  const [assignedQualification, setAssignedQualification] = useState(null);
  const [assignedIdentification, setAssignedIdentification] = useState(null);
  const [assignedTopic, setAssignedTopic] = useState(null);
  const [scheduleEditMode, setScheduleEditMode] = useState(false);
  const [assignedTimeslot, setAssignedTimeslot] = useState("");
  const [errorSkills, setErrorSkills] = useState(null);
  const [errorGenders, setErrorGenders] = useState(null);
  // const [errorTitle, setErrorTitle] = useState(null);
  const [errorIndustry, setErrorIndustry] = useState(null);
  const [errorQualification, setErrorQualification] = useState(null);
  const [errorIdentification, setErrorIdentification] = useState(null);
  const [errorDate, setErrorDate] = useState(null);
  const [date, setDate] = useState(null);
  // const [isError, setIsError] = useState(false);
  const [isFormDisabled, setFormDisabled] = useState(true);
  const [isModal, setModal] = useState(false);
  const [topicMentorState, setTopicMentorState] = useState([]);
  const [mentorTopics, setMentorTopics] = useState([]);
  const [mentorTopicsId, setMentorTopicsId] = useState([]);
  const [topicAlert, setTopicAlert] = useState(false);
  const [assignedScheduleDate, setAssignedScheduleDate] = useState(new Date());
  const [timeslotLimit, setTimeslotLimit] = useState(3);
  const [multipleTimeslot, setMultipleTimeslot] = useState([]);
  const [singleStartTime, setSingleStartTime] = useState(null);
  const [singleEndTime, setSingleEndTime] = useState(null);
  const [insertedStartTime, setInsertedStartTime] = useState([]);
  const [insertedEndTime, setInsertedEndTime] = useState([]);
  const [scheduleEditData, setScheduleEditData] = useState();
  const [deletedTimeslots, setDeletedTimeslots] = useState([]);
  const [isScheduleAvailable, setScheduleAvailable] = useState(null);
  const [timezone, setTimezone] = useState(null);
  const [startTimes, setStartTimes] = useState([]);
  const [endTimes, setEndTimes] = useState([]);
  const [newEndTime, setNewEndTime] = useState([]);
  const [filteredStartTime, setFilteredStartime] = useState([]);
  const [coachingTopics, setCoachingTopics] = useState([]);
  const [coachingTopicsEdit, setCoachingTopicsEdit] = useState([]);
  const [assignedRole, setAssignedRole] = useState(null);
  const [roleOptions, setRoleOptions] = useState([]);

  const [newEmailAddress, setNewEmailAddress] = useState("");
  const [isChangingEmail, setChangingEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [repeatPasswordType, setRepeatPasswordType] = useState("password");
  const [isChangingPassword, setChangingPassword] = useState(false);

  // phone number state
  const [isOpenPhoneDropdown, setIsOpenPhoneDropdown] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({
    number: "",
    icon: null,
    phone_code: "",
    country_id: "",
  });
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const [provinceValue, setProvinceValue] = useState(null);
  const [cityValue, setCityValue] = useState(null);

  const [isModalAssignedRole, setIsModalAssignedRole] = useState(false);

  const handleFetchTopics = async () => {
    const result = await getAllTopics();
    if (result && result.data) {
      let finalTopics = result.data;
      setTopics(finalTopics);
      setCoachingTopics(
        finalTopics
          .map(({ id, title, description }) => {
            return {
              id,
              name: title,
              description,
            };
          })
          .filter((item) =>
            isModalSchedule
              ? mentorTopics.includes(item.name)
              : !mentorTopics.includes(item.name)
          )
      );
    }
  };

  const handleFetchMentorDetail = async (user_id) => {
    setLoading(true);
    const result = await getUserDetail(user_id);
    if (result && result.data && result.data) {
      let mentorDetail = result.data;

      const { user_details } = mentorDetail || {};

      let countryIcon;
      let number;
      let phone_code;
      let country_id;
      let label;
      let code;

      if (user_details && user_details.country && user_details.country.id) {
        country_id = user_details.country.id;
      }
      if (user_details && user_details.country && user_details.country.code) {
        code = user_details.country.code;
        countryIcon = icon[`cif${user_details.country.code}`];
      }
      if (user_details && user_details.phone_number) {
        number = user_details.phone_number;
      }
      if (
        user_details &&
        user_details.country &&
        user_details.country.phone_code
      ) {
        phone_code = user_details.country.phone_code;
      }
      if (user_details && user_details.country && user_details.country.name) {
        label = user_details.country.name;
        if (user_details && user_details.country && user_details.country.name) {
          label = user_details.country.name;
          await handleGetStateOptions(user_details.country.name);
          if (user_details && user_details.province) {
            setProvinceValue({
              value: user_details.province,
              label: user_details.province,
            });
            await handleGetCityOptions(
              user_details.country.name,
              user_details.province
            );
            if (user_details && user_details.city) {
              setCityValue({
                value: user_details.city,
                label: user_details.city,
              });
            }
          }
        }
      }

      for (const key in user_details) {
        setValue(key, user_details[key]);
      }

      setMentor(mentorDetail);
      setSkills(
        (mentorDetail &&
          mentorDetail.user_details &&
          mentorDetail.user_details.skills) ||
          []
      );
      setAssignedGender(
        mentorDetail &&
          mentorDetail.user_details &&
          mentorDetail.user_details.gender
      );
      // setAssignedTitle(mentorDetail.user_details.mentor_title);
      setAssignedRole(
        mentorDetail && mentorDetail.role && mentorDetail.role.name
      );
      setAssignedIndustry({
        value:
          mentorDetail &&
          mentorDetail.user_details &&
          mentorDetail.user_details.industry &&
          mentorDetail.user_details.industry.id,
        id:
          mentorDetail &&
          mentorDetail.user_details &&
          mentorDetail.user_details.industry &&
          mentorDetail.user_details.industry.id,
        name:
          mentorDetail &&
          mentorDetail.user_details &&
          mentorDetail.user_details.industry &&
          mentorDetail.user_details.industry.name,
      });
      setAssignedQualification(
        mentorDetail &&
          mentorDetail.user_details &&
          mentorDetail.user_details.education_qualification
      );
      setAssignedIdentification(
        mentorDetail &&
          mentorDetail.user_details &&
          mentorDetail.user_details.identification_type
      );
      setDate(
        mentorDetail &&
          mentorDetail.user_details &&
          mentorDetail.user_details.date_of_birth &&
          new Date(mentorDetail.user_details.date_of_birth)
      );
      setPhoneNumber({
        ...phoneNumber,
        icon: countryIcon,
        phone_code,
        number,
        country_id,
        value: { label, value: code, phone_code, id: country_id },
      });
      handleFetchRoleOptions(mentorDetail);
      fetchAllCountries();
    }
    setLoading(false);
  };

  const handleFetchMentorTopics = async () => {
    const result = await getAllMentorTopics(user_id);
    if (result && result.data) {
      let finalTopicsMentor = result.data;
      setTopicMentorState(finalTopicsMentor);

      if (
        finalTopicsMentor &&
        Array.isArray(finalTopicsMentor) &&
        finalTopicsMentor.length > 0
      ) {
        setMentorTopics(finalTopicsMentor.map((value) => value.title));
        setMentorTopicsId(finalTopicsMentor.map((value) => value.id));
      }
    }
  };

  const handleFetchRoleOptions = async (mentorDetail) => {
    const result = await getAllRoles();
    if (result && result.data) {
      let finalRoleOptions = result.data;

      if (
        mentorDetail &&
        mentorDetail.role &&
        mentorDetail.role.id &&
        userLoginDetail &&
        userLoginDetail.role &&
        userLoginDetail.role.id &&
        mentorDetail.role.id !== userLoginDetail.role.id
      ) {
        // if mentor detail is not user login (not my profile page)
        finalRoleOptions = finalRoleOptions.filter(
          (role) => role.id !== userLoginDetail.role.id
        );
      }
      setRoleOptions(finalRoleOptions);
    }
  };

  const handleFetchMentorSchedules = async (user_id) => {
    const result = await getAllMentorSchedules(user_id);
    if (result && result.data) {
      let finalMentorSchedules = result.data;

      setMentorSchedules(finalMentorSchedules);
      setSchedule(finalMentorSchedules);
      if (finalMentorSchedules && finalMentorSchedules[0]) {
        setScheduleEditData(finalMentorSchedules[0]);
        if (finalMentorSchedules[0].topic_id) {
          setAssignedTopic(finalMentorSchedules[0].topic_id);
        }
        if (finalMentorSchedules[0].is_multiple_slot) {
          setAssignedTimeslot("Multiple time slots");
        } else {
          setAssignedTimeslot("Single slot");
        }
        setScheduleAvailable(finalMentorSchedules[0].is_available);
        setCoachingTopicsEdit(
          finalMentorSchedules.map((value) => {
            return {
              id: value.topic.id,
              name: value.topic.title,
              description: value.topic.description,
            };
          })
        );
      }
    }
  };

  const handleFetchMentorSchedulesAllTime = async (user_id) => {
    const result = await getMentorSchedulesAllTime(user_id);
    if (result && result.data) {
      let finalMentorSchedulesAllTime = result.data;
      setMentorSchedulesAll(finalMentorSchedulesAllTime);
      setAllTopicsMentor(
        finalMentorSchedulesAllTime
          .filter((value) => {
            return new Date(value.date) > new Date();
          })
          .map((item) => item.topic_id)
          .sort((a, b) => a - b)
      );
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    setValue,
  } = useForm({ mode: "all" });

  // const mentorsTitle = [
  //   {
  //     name: "Mr",
  //   },
  //   {
  //     name: "Mrs",
  //   },
  //   {
  //     name: "Ms",
  //   },
  //   {
  //     name: "Miss",
  //   },
  // ];

  const identifications = [
    {
      name: "KTP",
    },
    {
      name: "Passport",
    },
    {
      name: "Others",
    },
  ];

  const qualifications = [
    {
      name: "Diploma",
    },
    {
      name: "Bachelor's Degree",
    },
    {
      name: "Professional Degree",
    },
    {
      name: "Master's Degree",
    },
    {
      name: "Doctorate",
    },
  ];

  const timeArray = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ];

  const [timeAvailable, setTimeAvailable] = useState(
    timeArray.map((value) => {
      return {
        id: value,
        name: timeslotsRender(assignedScheduleDate, value),
      };
    })
  );

  // Handle date change
  function handleDate(date) {
    const value = date;
    setDate(value);
    if (value === "") {
      setErrorDate("Please choose your date of birth");
    } else {
      setErrorDate(null);
    }
  }

  // Get date only
  function dateHandler(date) {
    return new Date(date).getDate();
  }

  // Day handler
  function dayHandler(date) {
    let day = new Date(date).getDay();
    switch (day) {
      case 0:
        return "Sun";
      case 1:
        return "Mon";
      case 2:
        return "Tue";
      case 3:
        return "Wed";
      case 4:
        return "Thu";
      case 5:
        return "Fri";
      case 6:
        return "Sat";
      default:
        break;
    }
  }

  // Timeslot handler
  function timeslotsHandler(date, hour = "00") {
    const selectedDate = new Date(date).getDate();
    const selectedMonth = new Date(date).getMonth();
    const selectedYear = new Date(date).getFullYear();
    return new Date(
      moment()
        .set("date", selectedDate)
        .set("month", selectedMonth)
        .set("year", selectedYear)
        .set("hour", hour)
        .set("minute", "00")
        .set("second", "00")
        .set("millisecond", "000")
    );
  }

  // Timeslot render
  function timeslotsRender(date, hour) {
    const zeroPad = (num, places) => String(num).padStart(places, "0");
    const selectedDate = new Date(date).getUTCDate();
    const timeSelected = moment()
      .set("date", selectedDate)
      .set("hour", hour)
      .set("minute", "00");
    return zeroPad(new Date(timeSelected).getHours(), 2) + ":00";
  }

  // Get hours and minutes only
  function getHoursAndMinutes(date) {
    const zeroPad = (num, places) => String(num).padStart(places, "0");
    const hours = new Date(date).getHours();
    const minutes = new Date(date).getMinutes();
    return zeroPad(hours, 2) + ":" + zeroPad(minutes, 2);
  }

  // Get hour range
  function calculateHourRange(startDate, endDate) {
    const convertedStartDate = new Date(startDate);
    const convertedEndDate = new Date(endDate);

    let seconds = Math.floor((convertedEndDate - convertedStartDate) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    return (hours = Math.ceil(hours - days * 24));
  }

  // Handle schedule date
  function handleScheduleDate(date, month) {
    if (!date) {
      setAssignedScheduleDate(new Date());
    } else {
      setAssignedScheduleDate(timeslotsHandler(date));
    }
  }

  // Handle dropdown change
  function handleChangeDropdown(value, key, setter) {
    setter(value);
    if (key && key === "assignedRole") {
      setIsModalAssignedRole(true);
    }
    if (key && key === "province") {
      setCityValue(null);
      const countryName = phoneNumber.value.label;
      const provinceName = value.label;
      handleGetCityOptions(countryName, provinceName);
    }
  }

  // Error handler from react-hook-form
  function ErrorHandler(name) {
    return (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <ErrorInput>{message}</ErrorInput>}
      />
    );
  }

  // Handle gender
  function handleGender(e) {
    const value = e.target.value;
    if (value) {
      setAssignedGender(value);
      if (value === null) {
        setErrorGenders("Please choose your gender");
      } else {
        setErrorGenders(null);
      }
    }
  }

  // Handle timeslots - schedule
  function handleTimeslots(e) {
    setAssignedTimeslot(e.target.value);
  }

  // Handle select startTime
  function handleSelectStartTime(value, index) {
    if (!assignedTopic) return;
    if (assignedTimeslot === "Single slot") {
      const lastStartIndex = insertedStartTime
        .map((value) => new Date(value).getHours())
        .sort((a, b) => a - b);
      const startIndexValue = lastStartIndex[lastStartIndex.length - 1];
      if (value >= timeArray[0] && value < startIndexValue) {
        setNewEndTime(
          timeArray
            .filter((time) => time > value)
            .filter((newTime) => {
              let filteredStart = insertedStartTime.filter(
                (newStart) => new Date(newStart).getHours() > value
              );
              return newTime <= new Date(filteredStart[0]).getHours();
            })
            .map((value) => {
              return {
                id: value,
                name: timeslotsRender(assignedScheduleDate, value),
              };
            })
        );
      } else {
        setNewEndTime(
          timeArray
            .filter((time) => time > value)
            .map((item) => {
              return {
                id: item,
                name: timeslotsRender(assignedScheduleDate, item),
              };
            })
        );
      }
      setSingleStartTime(timeslotsHandler(assignedScheduleDate, value));
    } else {
      // Check if array index is empty or not
      const lastStartIndex = insertedStartTime
        .map((value) => new Date(value).getHours())
        .sort((a, b) => a - b);
      const startIndexValue = lastStartIndex[lastStartIndex.length - 1];
      if (value >= timeArray[0] && value < startIndexValue) {
        setNewEndTime(
          timeArray
            .filter((time) => time > value)
            .filter((newTime) => {
              let filteredStart = insertedStartTime.filter(
                (newStart) => new Date(newStart).getHours() > value
              );
              return newTime <= new Date(filteredStart[0]).getHours();
            })
            .map((value) => {
              return {
                id: value,
                name: timeslotsRender(assignedScheduleDate, value),
              };
            })
        );
      } else {
        setNewEndTime(
          timeArray
            .filter((time) => time > value)
            .map((item) => {
              return {
                id: item,
                name: timeslotsRender(assignedScheduleDate, item),
              };
            })
        );
      }
      if (!insertedStartTime[index]) {
        setInsertedStartTime([
          ...insertedStartTime,
          timeslotsHandler(assignedScheduleDate, value),
        ]);
      } else {
        setInsertedStartTime(
          insertedStartTime.map((item, i) =>
            i === index ? timeslotsHandler(assignedScheduleDate, value) : item
          )
        );
      }
    }
  }

  // Handle select endTime
  function handleSelectEndTime(value, index) {
    if (!assignedTopic) return;
    if (assignedTimeslot === "Single slot") {
      setSingleEndTime(timeslotsHandler(assignedScheduleDate, value));
    } else {
      // Check if array index is empty or not
      if (!insertedEndTime[index]) {
        setInsertedEndTime([
          ...insertedEndTime,
          timeslotsHandler(assignedScheduleDate, value),
        ]);
      } else {
        setInsertedEndTime(
          insertedEndTime.map((item, i) =>
            i === index ? timeslotsHandler(assignedScheduleDate, value) : item
          )
        );
      }
    }
  }

  // Cancel edit
  function cancelEdit(e) {
    if (e) {
      e.preventDefault();
    }
    setModal(true);
  }

  // Handle secondary modal button (No)
  function handleCancel() {
    clearErrors([
      "first_name",
      "last_name",
      "gender",
      "company_name",
      "place_of_birth",
      "phone_number",
      "country_id",
      "province",
      "city",
      "postal_code",
      "address",
      "identification_number",
    ]);
    setErrorDate(null);
    setErrorIndustry(null);
    setErrorSkills(null);
    setErrorIdentification(null);
    setErrorQualification(null);
    handleFetchMentorDetail(user_id);
    setFormDisabled(true);
    setModal(false);
  }

  // Handle edit mentor
  async function editMentor(data, e) {
    // If form is disabled
    if (isFormDisabled) {
      e.preventDefault();
      setFormDisabled(false);

      // if form not disabled
    } else {
      try {
        let isValid = handleCheckValidationOtherInputUpdateMentor();

        if (isValid) {
          const response = await updateUserDetail(
            {
              ...data,
              phone_number: phoneNumber.number,
              country_id: phoneNumber.country_id,
              province: provinceValue.value,
              city: cityValue.value,
              gender: assignedGender,
              date_of_birth: date,
              industry_id: assignedIndustry.id,
              identification_type: assignedIdentification,
              skills: skills,
              education_qualification: assignedQualification,
              // mentor_title: assignedTitle,
            },
            user_id
          );
          if (response && response.data) {
            Swal.fire({
              customClass: {
                popup: "mentor-popup",
              },
              imageUrl:
                "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
              position: "top-end",
              text: "Mentor profile has been updated",
              width: "20em",
              heightAuto: "0.5em",
              showConfirmButton: false,
              timer: 1500,
            });
            setFormDisabled(true);
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Please fill all required elements",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      } catch (err) {
        console.log(err, "error line 831 internalMentorDetail.js <<");
      } finally {
        setModal(false);
        // setFormDisabled(true);
      }
    }
  }

  // Show assign topic modal - schedule
  function showTopicModal() {
    setModalAssignedTopic(true);
  }

  // Open modal schedule add mode
  function openAddSchedule() {
    setModalSchedule(true);
  }

  // Open modal schedule edit mode
  function openEditSchedule() {
    if (schedule.length === 0) return;
    setModalSchedule(true);
    setScheduleEditMode(true);
  }

  // Close modal schedule edit mode
  function closeModalSchedule() {
    setModalSchedule(false);
    setScheduleEditMode(false);
    setDeletedTimeslots([]);
  }

  // Add more topic - schedule
  function handleTopic(value) {
    setMentorTopicsId([...mentorTopicsId, value]);
    const topicsTitle = topics.filter((item) => value === item.id);
    setMentorTopics([
      ...mentorTopics,
      topicsTitle.map((value) => value.title)[0],
    ]);
    setTopicAlert(true);
    setTimeout(() => setTopicAlert(false), 3000);
  }

  // Submit topic - schedule
  async function saveTopics() {
    setLoading(true);
    try {
      const response = await createMultipleTopics(mentorTopicsId);
      Swal.fire({
        customClass: {
          popup: "mentor-popup",
        },
        imageUrl:
          "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
        position: "top-end",
        text: "Successfully assign topic(s)",
        width: "20em",
        heightAuto: "0.5em",
        showConfirmButton: false,
        timer: 1500,
      });
      setTopicMentorState(response.data);
    } catch (err) {
      console.log(err, "error line 895 internalMentorDetail.js <<");
    } finally {
      setLoading(false);
      setModalAssignedTopic(false);
    }
  }

  // Delete topic with validation
  function handleTopicTags(value) {
    let unchangedValue = mentorTopics;
    setMentorTopics(value);
    let topicFiltered = topics
      .filter((item) => value.includes(item.title))
      .map((item) => item.id);
    if (allTopicsMentor.every((element) => topicFiltered.includes(element))) {
      return;
    } else {
      Swal.fire("Oops", "This topic still has a future session book", "error");
      return setMentorTopics(unchangedValue);
    }
  }

  // Submit timeslot - schedule
  async function createSchedule() {
    setLoading(true);
    const startTime =
      assignedTimeslot === "Multiple time slots"
        ? insertedStartTime
        : [new Date(singleStartTime)];
    const endTime =
      assignedTimeslot === "Multiple time slots"
        ? insertedEndTime
        : [new Date(singleEndTime)];
    const body = {
      topic_id: assignedTopic,
      date: timeslotsHandler(assignedScheduleDate),
      is_multiple_slot:
        assignedTimeslot === "Multiple time slots" ? true : false,
      is_available: isScheduleAvailable,
    };
    const params = {
      startTime,
      endTime,
    };
    try {
      await createMentorSchedule(user_id, body, params);
      await handleFetchMentorSchedules(user_id);
      Swal.fire({
        customClass: {
          popup: "mentor-popup",
        },
        imageUrl:
          "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
        position: "top-end",
        text: "Schedule has been added",
        width: "20em",
        heightAuto: "0.5em",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      console.log(err, "error line 956 internalMentorDetail.js <<");
    } finally {
      setLoading(false);
      closeModalSchedule();
    }
  }

  // Edit timeslot - schedule
  async function editSchedule() {
    setLoading(true);
    const startTime =
      assignedTimeslot === "Multiple time slots"
        ? insertedStartTime
        : [new Date(singleStartTime)];
    const endTime =
      assignedTimeslot === "Multiple time slots"
        ? insertedEndTime
        : [new Date(singleEndTime)];
    const body = {
      topic_id: assignedTopic,
      date: assignedScheduleDate,
      is_available: isScheduleAvailable,
    };
    const params = {
      startTime: startTime,
      endTime: endTime,
      topic_id: assignedTopic,
      timeslots: deletedTimeslots,
    };
    try {
      await editMentorSchedule(user_id, body, params);
      Swal.fire({
        customClass: {
          popup: "mentor-popup",
        },
        imageUrl:
          "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
        position: "top-end",
        text: "Schedule has been edited",
        width: "20em",
        heightAuto: "0.5em",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      console.log(err, "error line 1001 internalMentorDetail.js <<");
    } finally {
      setLoading(false);
      closeModalSchedule();
    }
  }

  // Add timeslot limit
  function handleTimeslotLimit() {
    setTimeslotLimit(timeslotLimit + 1);
  }

  // Remove one multiple timeslot - schedule
  function removeTimeslot(startTime, endTime, index) {
    if (scheduleEditMode) {
      setTimeslotLimit(() => timeslotLimit - 1);
      setInsertedStartTime(
        insertedStartTime.filter((element, i) => i !== index)
      );
      setInsertedEndTime(insertedEndTime.filter((element, i) => i !== index));
      setDeletedTimeslots([
        ...deletedTimeslots,
        scheduleEditData.timeslots[index].id,
      ]);
    } else {
      setMultipleTimeslot(multipleTimeslot.filter((element, i) => i !== index));
      setTimeslotLimit(() => timeslotLimit - 1);
      setInsertedStartTime(
        insertedStartTime.filter(
          (element) => new Date(element).getHours() !== startTime
        )
      );
      setInsertedEndTime(
        insertedEndTime.filter(
          (element) => new Date(element).getHours() !== endTime
        )
      );
    }
  }

  const handleOnChangePhoneNumber = (
    value,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    let parts = value.split(" ");
    let withoutCountryCode;

    if (phoneNumberState && phoneNumberState.phone_code) {
      withoutCountryCode = parts[1];
    } else {
      withoutCountryCode = parts.join(" ");
    }

    clearErrors("phone_number");

    setterPhoneNumber({
      ...phoneNumberState,
      number: withoutCountryCode,
    });
  };

  const handleChangePhoneNumberDropdown = (
    val,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    const {
      value: { code, phone_code, id },
      label = {},
    } = val || {};

    let countryIcon = "";
    if (code) {
      countryIcon = icon[`cif${code}`];
    }

    const countryName = label;
    setProvinceValue(null);
    setCityValue(null);
    setCityOptions([]);
    handleGetStateOptions(countryName);

    setterPhoneNumber({
      ...phoneNumberState,
      icon: countryIcon,
      phone_code: phone_code,
      country_id: id,
      value: { label, value: val.value },
    });
    clearErrors("country_id");
    setIsOpenPhoneDropdown(false);
  };

  const handleCheckValidationOtherInputUpdateMentor = () => {
    let inValidPhoneNumberInput = false;
    let invalidProvince = false;
    let invalidCity = false;
    let invalidDateOfBirth = false;
    let invalidGender = false;
    let invalidIdentificationType = false;
    let invalidIndustry = false;
    let invalidEducationalQualification = false;
    let invalidSkills = false;

    if (
      phoneNumber &&
      phoneNumber.country_id &&
      phoneNumber.number &&
      phoneNumber.phone_code
    ) {
      let isValidPhoneNumber = validatePhoneNumber(phoneNumber.number);
      if (!isValidPhoneNumber) {
        inValidPhoneNumberInput = true;
        setError("phone_number", { message: "Invalid phone number" });
      }
    } else {
      inValidPhoneNumberInput = true;
      if (!phoneNumber.country_id) {
        setError("country_id", { message: "Country is required" });
      }
      if (!phoneNumber.number) {
        setError("phone_number", { message: "Phone number is required" });
      }
    }

    if (!provinceValue || !provinceValue.value) {
      invalidProvince = true;
      setError("province", { message: "State / Province is required" });
    }

    if (!cityValue || !cityValue.value) {
      invalidCity = true;
      setError("city", { message: "City is required" });
    }

    if (!date) {
      invalidDateOfBirth = true;
      setErrorDate("Please choose your date of birth");
    }

    if (!assignedGender) {
      invalidGender = true;
      setErrorGenders("Please select your gender");
    }

    if (!assignedIdentification) {
      invalidIdentificationType = true;
      setErrorIdentification("Please select your identification");
    }

    if (!assignedIndustry || !assignedIndustry.id) {
      invalidIndustry = true;
      setErrorIndustry("Please select your industry");
    }

    if (!assignedQualification) {
      invalidEducationalQualification = true;
      setErrorQualification("Please select your qualification");
    }

    if (skills.length === 0) {
      invalidSkills = true;
      setErrorSkills("Please input your skills");
    }

    // if (!assignedTitle) setErrorTitle("Please select your title");

    if (
      inValidPhoneNumberInput !== true &&
      invalidProvince !== true &&
      invalidCity !== true &&
      invalidDateOfBirth !== true &&
      invalidGender !== true &&
      invalidIdentificationType !== true &&
      invalidIndustry !== true &&
      invalidEducationalQualification !== true &&
      invalidSkills !== true
    ) {
      return true;
    } else {
      return false;
    }
  };

  // Get all countries
  const fetchAllCountries = async () => {
    const response = await getAllCountries();
    if (response && response.data) {
      const { data } = response;
      if (data && Array.isArray(data) && data.length > 0) {
        setCountryOptions(createCountriesOptions(data));
      }
    }
  };

  const handleGetStateOptions = async (countryName) => {
    const result = await getStatesByCountryName({ country: countryName });
    if (result && result.data) {
      const { data: { states } = {} } = result.data || {};
      if (states && Array.isArray(states) && states.length > 0) {
        setStateOptions(createStateOptions(states));
      }
    }
  };

  const handleGetCityOptions = async (countryName, stateName) => {
    const result = await getCityByCountryNameAndStateName({
      country: countryName,
      state: stateName,
    });
    if (result && result.data) {
      const { data } = result.data || {};
      if (data && Array.isArray(data) && data.length > 0) {
        setCityOptions(createCityOptions(data));
      }
    }
  };

  const handleOnClickImageProfile = async (files) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    const result = await updateUserImageUrl(formData, mentor.user.id);
    if (result && result.data) {
      let finalMentor = {
        ...mentor,
        user: {
          ...mentor.user,
          user_details: {
            ...mentor.user.user_details,
            image_url: result.data.image_url,
          },
        },
      };
      setMentor(finalMentor);
      Swal.fire({
        customClass: {
          popup: "mentor-popup",
        },
        icon: "success",
        position: "top-end",
        text: "Mentor profile picture has been updated",
        width: "20em",
        heightAuto: "0.5em",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleFetchIndustries = async () => {
    const { data: industries } = await getAllIndustries();
    if (industries) {
      setIndustries(industries);
    }
  };

  function handleUpdateEmail() {
    Swal.fire({
      title: "Please wait",
      text: "We are processing your request",
      allowOutsideClick: false,
      didOpen: () => {
        setLoading(true);
        updateEmailFromProfile({
          prev_email: mentor?.email,
          new_email: newEmailAddress,
        })
          .then(() => {
            setLoading(false);
            Swal.fire({
              text: "You have successfully changed your email",
              imageUrl: email_password_update_success,
              imageHeight: 185,
              imageWidth: 255,
              showCloseButton: true,
            }).then(() => {
              logout();
            });
          })
          .catch((err) => {
            setLoading(false);
            Swal.fire({
              title: "Error",
              text: err.response.data.message,
              icon: "error",
              showCloseButton: true,
            });
          });
      },
    });
  }

  async function logout() {
    try {
      setLoading(true);
      const result = await logoutUser();
      if (result && result.data && result.data.success) {
        setLoading(false);
        dispatch(SET_USER_DETAIL(null));
        navigate("/login");
      }
    } catch (error) {
      console.log(error, "error line 1304 InternalMentorDetail.js <<");
      setLoading(false);
      // showAlert(error.response.data.message, "error");
    }
  }

  function checkRepeatPassword() {
    if (password && repeatPassword && password === repeatPassword) {
      return true;
    } else {
      return false;
    }
  }

  function handleUpdatePassword() {
    if (checkRepeatPassword()) {
      Swal.fire({
        title: "Please wait",
        text: "We are processing your request",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          updatePasswordFromProfile({ password })
            .then(() => {
              Swal.fire({
                text: "You have successfully changed the password",
                imageUrl: email_password_update_success,
                imageHeight: 185,
                imageWidth: 255,
                showCloseButton: true,
              }).then(() => {
                setPassword("");
                setRepeatPassword("");
                setChangingPassword(false);
              });
            })
            .catch((err) => {
              Swal.fire({
                title: "Error",
                text: err.response.data.message,
                icon: "error",
              });
            });
        },
      });
    } else {
      // showAlert("Password did not match", "error");
    }
  }

  // Handle change user role
  async function handleChangeUserRole() {
    setLoading(true);
    try {
      let finalRoleValue = roleOptions.filter(
        (roleOpt) => roleOpt.name === assignedRole
      );
      const response = await updateUserRole(user_id, {
        role_id: finalRoleValue[0].id,
        new_role_assigned: finalRoleValue[0].name,
      });
      if (response && response.data) {
        Swal.fire({
          customClass: {
            popup: "mentor-popup",
          },
          imageUrl:
            "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
          position: "top-end",
          text: "User role has been changed",
          width: "20em",
          heightAuto: "0.5em",
          showConfirmButton: false,
          timer: 1500,
        });
        setAssignedRole(finalRoleValue[0].name);
        if (finalRoleValue[0].name !== "Mentor") {
          navigate(`/internal/users/${response.data.updatedUser.id}`);
        }
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Internal server error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      console.log(err, "error line 1393 internalMentorDetail.js <<");
    } finally {
      setLoading(false);
      setModal(false);
    }
  }

  // Handle secondary modal button (No)
  function handleCancelModalAssignedRole(e) {
    setAssignedRole(mentor.role.name);
    setIsModalAssignedRole(false);
  }

  const handleDeleteSchedule = async () => {
    const mentor_schedule_id = schedule.filter(
      (sch) => sch.topic_id === +assignedTopic
    )[0].id;
    const result = await deleteMentorSchedule(mentor_schedule_id);
    if (result && result.data && result.data.success) {
      await handleFetchMentorSchedules(user_id);
      Swal.fire({
        customClass: {
          popup: "mentor-popup",
        },
        imageUrl:
          "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
        position: "top-end",
        text: result.data.message,
        width: "20em",
        heightAuto: "0.5em",
        showConfirmButton: false,
        timer: 1500,
      });
      closeModalSchedule();
    }
  };

  async function updateMentorSchedules() {
    setLoading(true);
    try {
      const mentorSchedules = await getAllMentorSchedules(user_id, {
        selectedDate: assignedScheduleDate,
      });

      const mentorSchedulesAllTime = await getMentorSchedulesAllTime(user_id);
      setSchedule(mentorSchedules.data);

      // Merge all start times and end times
      let allStartTime = mentorSchedules.data.map((value) => {
        return value.timeslots.map((item) => {
          return item.start_time;
        });
      });
      let allEndTime = mentorSchedules.data.map((value) => {
        return value.timeslots.map((item) => {
          return item.end_time;
        });
      });

      let mergedStartTime = [].concat.apply([], allStartTime);
      let mergedEndTime = [].concat.apply([], allEndTime);
      setStartTimes(mergedStartTime);
      setEndTimes(mergedEndTime);
      if (mentorSchedules.data.length !== 0 && mentorSchedulesAllTime.data) {
        setMentorSchedulesAll(mentorSchedulesAllTime.data);
        setScheduleAvailable(mentorSchedules.data[0]?.is_available);
        setAssignedTopic(mentorSchedules.data[0]?.topic_id);
        setAssignedTimeslot(
          mentorSchedules.data[0]?.is_multiple_slot
            ? "Multiple time slots"
            : "Single slot"
        );
        setTimeslotLimit(mentorSchedules.data[0]?.timeslots.length);
        if (mentorSchedules.data[0]?.is_multiple_slot) {
          if (!scheduleEditMode) {
            setAssignedTopic(null);
            setAssignedTimeslot("Single slot");
          } else {
            setInsertedStartTime(
              mentorSchedules.data[0]?.timeslots
                .map((value) => value.start_time)
                .sort((a, b) => {
                  let start = new Date(a).getHours();
                  let end = new Date(b).getHours();
                  if (start < end) return -1;
                  if (start > end) return 1;
                  return 0;
                })
            );
            setInsertedEndTime(
              mentorSchedules.data[0]?.timeslots
                .map((value) => value.end_time)
                .sort((a, b) => {
                  let start = new Date(a).getHours();
                  let end = new Date(b).getHours();
                  if (start < end) return -1;
                  if (start > end) return 1;
                  return 0;
                })
            );
          }
        } else {
          if (!scheduleEditMode) {
            setAssignedTimeslot("Single slot");
            setAssignedTopic(null);
          } else {
            setSingleStartTime(
              mentorSchedules.data[0]?.timeslots[0].start_time
            );
            setSingleEndTime(mentorSchedules.data[0]?.timeslots[0].end_time);
          }
        }
      } else {
        setScheduleAvailable(true);
        setAssignedTopic(null);
        setAssignedTimeslot("Single slot");
        setTimeslotLimit(3);
      }
    } catch (err) {
      console.log(err, "error line 1512 internalMentorDetail.js <<");
    } finally {
      setLoading(false);
    }
  }

  // Options renderer
  function renderOptions() {
    if (infoName === "personal") {
      return (
        <FormMentor onSubmit={handleSubmit((data, e) => editMentor(data, e))}>
          <Row>
            <Col>
              <FlexRow>
                {/* <InputContainer>
                    <InputLabel>Title</InputLabel>
                    <CustomDropdown
                      disabled={isFormDisabled}
                      value={assignedTitle || mentor.mentor_title}
                      placeholder="Select title &nbsp; &nbsp;"
                      options={mentorsTitle}
                      selectedKey="name"
                      action={(value) =>
                        handleChangeDropdown(value, "assignedTitle")
                      }
                    />
                    <ErrorInput>{errorTitle}</ErrorInput>
                  </InputContainer> */}
                <InputContainer>
                  <InputLabel>Gender</InputLabel>
                  <RadioButton
                    disabled={isFormDisabled}
                    name="gender"
                    data={["male", "female"]}
                    state={
                      assignedGender ||
                      (mentor &&
                        mentor.user_details &&
                        mentor.user_details.gender)
                    }
                    handleChange={handleGender}
                  />
                  <ErrorInput>{errorGenders}</ErrorInput>
                </InputContainer>
              </FlexRow>
              <FlexRow>
                <InputContainer>
                  <InputLabel>First Name</InputLabel>
                  <InputMentor
                    disabled={isFormDisabled}
                    placeholder="Input first name here"
                    {...register("first_name", {
                      required: "Please input your first name",
                      value:
                        mentor &&
                        mentor.user_details &&
                        mentor.user_details.first_name,
                    })}
                  />
                  {ErrorHandler("first_name")}
                </InputContainer>
                <InputContainer>
                  <InputLabel>Last Name</InputLabel>
                  <InputMentor
                    disabled={isFormDisabled}
                    placeholder="Input last name here"
                    {...register("last_name", {
                      required: "Please input your last name",
                      value:
                        mentor &&
                        mentor.user_details &&
                        mentor.user_details.last_name,
                    })}
                  />
                  {ErrorHandler("last_name")}
                </InputContainer>
              </FlexRow>
              <InputContainer>
                <InputLabel>Company/Organization</InputLabel>
                <InputMentor
                  disabled={isFormDisabled}
                  {...register("company_name", {
                    required: "Please input your company",
                    value:
                      mentor &&
                      mentor.user_details &&
                      mentor.user_details.company_name,
                  })}
                  placeholder="e.g: ABC company"
                />
                {ErrorHandler("company_name")}
              </InputContainer>
              <InputContainer>
                <InputLabel>Industry</InputLabel>
                <CustomDropdown
                  disabled={isFormDisabled}
                  value={
                    (assignedIndustry && assignedIndustry.name) ||
                    (mentor &&
                      mentor.user_details &&
                      mentor.user_details.industry &&
                      mentor.user_details.industry.name)
                  }
                  placeholder="Select one related industry &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                  options={industries}
                  selectedKey={"name"}
                  label={"assignedIndustry"}
                  action={(value) =>
                    handleChangeDropdown(
                      value,
                      "assignedIndustry",
                      setAssignedIndustry
                    )
                  }
                />
                <ErrorInput>{errorIndustry}</ErrorInput>
              </InputContainer>
              <InputContainer>
                <InputLabel>Phone Number</InputLabel>
                <div className="inputPhoneBox">
                  <div
                    className="inputPhoneCode"
                    onClick={() =>
                      !isFormDisabled
                        ? setIsOpenPhoneDropdown(!isOpenPhoneDropdown)
                        : null
                    }
                    style={{
                      border: isFormDisabled
                        ? "2px solid #9BDDCC"
                        : "2px solid #00bb99",
                    }}
                  >
                    {phoneNumber && phoneNumber.icon ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CIcon icon={phoneNumber.icon} size="xxl" />
                      </div>
                    ) : (
                      "Country"
                    )}
                  </div>
                  <InputText
                    placeholder={"Fill in phone number"}
                    background={
                      errors && errors.phone_number
                        ? "#FFFFFF"
                        : isFormDisabled
                        ? "#F5FBFA"
                        : "transparent"
                    }
                    name={"phoneNumberMyProfile"}
                    border={
                      isFormDisabled ? "2px solid #9BDDCC" : "2px solid #00bb99"
                    }
                    disabled={isFormDisabled}
                    functionSetter={handleOnChangePhoneNumber}
                    phoneNumberState={phoneNumber}
                    setterPhoneNumber={setPhoneNumber}
                    className={"userFormInputStyle"}
                    fontColor={isFormDisabled ? "#7f7f7f" : "#000000"}
                    value={
                      phoneNumber &&
                      phoneNumber.number &&
                      phoneNumber.phone_code
                        ? `(${phoneNumber.phone_code}) ${phoneNumber.number}`
                        : phoneNumber &&
                          phoneNumber.phone_code &&
                          !phoneNumber.number
                        ? `(${phoneNumber.phone_code}) `
                        : phoneNumber &&
                          phoneNumber.number &&
                          !phoneNumber.phone_code
                        ? phoneNumber.number
                        : ""
                    }
                  />
                </div>
                <ErrorInput>
                  {!phoneNumber.country_id &&
                  !phoneNumber.number &&
                  errors?.country_id &&
                  errors.country_id.message &&
                  errors?.phone_number &&
                  errors.phone_number.message
                    ? "Country and Phone Number is required"
                    : (!phoneNumber.country_id &&
                        errors?.country_id &&
                        errors.country_id.message) ||
                      (errors?.phone_number && errors.phone_number.message)}
                </ErrorInput>
                <div className="inputContainer" style={{ marginTop: "0" }}>
                  <div>
                    {isOpenPhoneDropdown ? (
                      <InputDropdown
                        options={countryOptions}
                        styles={{
                          marginTop: "1em",
                          border: "2px solid #00BB99",
                          background: "transparent",
                          borderRadius: "10px",
                          height: "48px",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        state={phoneNumber}
                        setter={setPhoneNumber}
                        functionSetter={handleChangePhoneNumberDropdown}
                      />
                    ) : null}
                  </div>
                </div>
                {/* <InputMentor
                    disabled={isFormDisabled}
                    {...register("phone_number", {
                      required: "Please input your phone number",
                      value: mentor.phone_number,
                    })}
                  /> */}
                {/* {ErrorHandler("phone_number")} */}
              </InputContainer>

              <FlexRow>
                <InputContainer>
                  <InputLabel>Country</InputLabel>
                  {/* <QuickFilterDropdown
                      isDisabled={isFormDisabled}
                      options={countries}
                      placeholder="Input country"
                      state={assignedCountry}
                      handleState={setAssignedCountry}
                    />
                    <ErrorInput>{errorCountry}</ErrorInput> */}
                  <InputDropdown
                    options={countryOptions}
                    styles={{
                      border: isFormDisabled
                        ? "2px solid #9BDDCC"
                        : "2px solid #00bb99",
                      background:
                        errors && errors.country_id
                          ? "#FFFFFF"
                          : isFormDisabled
                          ? "#F5FBFA"
                          : "transparent",
                      borderRadius: "10px",
                      height: "48px",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    placeholder="Select..."
                    disabled={isFormDisabled}
                    state={phoneNumber}
                    setter={setPhoneNumber}
                    functionSetter={handleChangePhoneNumberDropdown}
                    value={
                      phoneNumber &&
                      phoneNumber.value &&
                      phoneNumber.value.label
                        ? phoneNumber && phoneNumber.value
                        : null
                    }
                  />
                  <ErrorInput>
                    {!phoneNumber.country_id &&
                      errors?.country_id &&
                      errors.country_id.message}
                  </ErrorInput>
                </InputContainer>
                <InputContainer>
                  <InputLabel>State/Province</InputLabel>
                  <InputDropdown
                    options={stateOptions}
                    styles={{
                      border: isFormDisabled
                        ? "2px solid #9BDDCC"
                        : "2px solid #00bb99",
                      background:
                        errors && errors.province
                          ? "#FFFFFF"
                          : isFormDisabled
                          ? "#F5FBFA"
                          : "transparent",
                      borderRadius: "10px",
                      height: "48px",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    disabled={isFormDisabled}
                    setter={setProvinceValue}
                    state={"province"}
                    functionSetter={handleChangeDropdown}
                    value={provinceValue}
                  />
                  <ErrorInput>
                    {errors?.province && errors.province.message}
                  </ErrorInput>
                  {/* <InputMentor
                    disabled={isFormDisabled}
                    {...register("state_or_province", {
                      required: "Please input your state/province",
                      value: mentor.state_or_province,
                    })}
                  />
                  {ErrorHandler("state_or_province")} */}
                </InputContainer>
              </FlexRow>
              <FlexRow>
                <InputContainer>
                  <InputLabel>City</InputLabel>
                  <InputDropdown
                    options={cityOptions}
                    styles={{
                      border: isFormDisabled
                        ? "2px solid #9BDDCC"
                        : "2px solid #00bb99",
                      background:
                        errors && errors.city
                          ? "#FFFFFF"
                          : isFormDisabled
                          ? "#F5FBFA"
                          : "transparent",
                      borderRadius: "10px",
                      height: "48px",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    disabled={isFormDisabled}
                    setter={setCityValue}
                    state={"city"}
                    functionSetter={handleChangeDropdown}
                    value={cityValue}
                  />
                  <ErrorInput>{errors?.city && errors.city.message}</ErrorInput>
                  {/* <InputMentor
                    disabled={isFormDisabled}
                    {...register("city", {
                      required: "Please input your city",
                      value: mentor.city,
                    })}
                  />
                  {ErrorHandler("state_or_province")} */}
                </InputContainer>
                <InputContainer>
                  <InputLabel>Postal Code</InputLabel>
                  <InputMentor
                    disabled={isFormDisabled}
                    {...register("postal_code", {
                      required: "Please input your postal code",
                      value:
                        mentor &&
                        mentor.user_details &&
                        mentor.user_details.postal_code,
                    })}
                    placeholder="Input your postal code here"
                  />
                  {ErrorHandler("postal_code")}
                </InputContainer>
              </FlexRow>
              {mentor.id === userLoginDetail.id ? (
                <>
                  <InputContainer>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div className="userFormInputGroupFlexContainer">
                          <div
                            className="userFormInputLabel"
                            style={{
                              fontSize: fontSize.title2,
                            }}
                          >
                            {isChangingEmail ? "New email address" : "Email"}
                          </div>
                          {isChangingEmail ? (
                            <CancelText onClick={() => setChangingEmail(false)}>
                              Cancel
                            </CancelText>
                          ) : (
                            <InputAction
                              onClick={() =>
                                !isFormDisabled ? setChangingEmail(true) : null
                              }
                              disabled={isFormDisabled}
                            >
                              Change
                            </InputAction>
                          )}
                        </div>
                        {isChangingEmail ? (
                          <div className="userFormInputGroupFlexContainer">
                            <input
                              style={{
                                // width: smallScreen ? "100%" : "60%",
                                width: "60%",
                                background: "#FFFFFF",
                                border: "2px solid #00BB99",
                              }}
                              value={newEmailAddress}
                              onChange={(e) =>
                                setNewEmailAddress(e.target.value)
                              }
                              type="email"
                              placeholder="e.g: john@example.com"
                              className="userFormInputStyle"
                            />
                            {newEmailAddress !== "" ? (
                              <GreenButton
                                action={handleUpdateEmail}
                                width="130px"
                                height="30px"
                                textSize={fontSize.title3}
                                text="Update"
                                bgcolor={
                                  newEmailAddress ? "#00BB99" : "#AAAAAA"
                                }
                              />
                            ) : null}
                          </div>
                        ) : (
                          <div className="userFormInputGroupFlexContainer">
                            <div
                              style={{
                                fontSize: fontSize.title3,
                                fontWeight: "400",
                              }}
                            >
                              Your email address is
                              <b> {mentor?.email}</b>
                            </div>
                          </div>
                        )}
                      </Form.Group>
                    </div>
                  </InputContainer>
                  <InputContainer>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div className="userFormInputGroupFlexContainer">
                          <div
                            className="userFormInputLabel"
                            style={{
                              fontSize: fontSize.title2,
                            }}
                          >
                            {isChangingPassword ? (
                              <>
                                <span>New password</span>
                                <Tooltip content="Your password must at least 8 characters, including uppercase, lowercase, numbers, and special characters" />
                              </>
                            ) : (
                              <span>Password</span>
                            )}
                          </div>
                          {isChangingPassword ? (
                            <CancelText
                              onClick={() => setChangingPassword(false)}
                            >
                              Cancel
                            </CancelText>
                          ) : (
                            <InputAction
                              onClick={() =>
                                !isFormDisabled
                                  ? setChangingPassword(true)
                                  : null
                              }
                              disabled={isFormDisabled}
                            >
                              Change
                            </InputAction>
                          )}
                        </div>
                        {isChangingPassword ? (
                          <div>
                            <div
                              className="userFormInputGroupFlexContainer"
                              style={{ marginBottom: "10px" }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <input
                                  style={{
                                    width: "100%",
                                    background: "#FFFFFF",
                                    border: "2px solid #00BB99",
                                  }}
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  type={passwordType}
                                  placeholder="New password"
                                  className="userFormInputStyle"
                                />

                                <input
                                  autoComplete="off"
                                  style={{ display: "none" }}
                                  id="show1"
                                  type="checkbox"
                                  checked={passwordType === "text"}
                                  onChange={() =>
                                    setPasswordType((prev) =>
                                      prev === "password" ? "text" : "password"
                                    )
                                  }
                                />
                                <label
                                  htmlFor="show1"
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    margin: "0 12px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {passwordType === "password" ? (
                                    <AiFillEye size={25} />
                                  ) : (
                                    <AiFillEyeInvisible size={25} />
                                  )}
                                </label>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  marginTop:
                                    !repeatPassword || checkRepeatPassword()
                                      ? null
                                      : "1.3em",
                                }}
                              >
                                <div
                                  style={{
                                    position: "relative",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <input
                                    style={{
                                      width: "100%",
                                      background: "#FFFFFF",
                                      border: "2px solid #00BB99",
                                    }}
                                    value={repeatPassword}
                                    onChange={(e) =>
                                      setRepeatPassword(e.target.value)
                                    }
                                    type={repeatPasswordType}
                                    placeholder="Repeat password"
                                    className="userFormInputStyle"
                                  />
                                  <input
                                    autoComplete="off"
                                    style={{ display: "none" }}
                                    id="show2"
                                    type="checkbox"
                                    checked={repeatPasswordType === "text"}
                                    onChange={() =>
                                      setRepeatPasswordType((prev) =>
                                        prev === "password"
                                          ? "text"
                                          : "password"
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor="show2"
                                    style={{
                                      position: "absolute",
                                      right: 0,
                                      margin: "0 12px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {repeatPasswordType === "password" ? (
                                      <AiFillEye size={25} />
                                    ) : (
                                      <AiFillEyeInvisible size={25} />
                                    )}
                                  </label>
                                </div>
                                <small
                                  className="text-danger"
                                  style={{
                                    userSelect: "none",
                                    display: "flex",
                                    textAlign: "left",
                                  }}
                                >
                                  {!repeatPassword || checkRepeatPassword()
                                    ? null
                                    : "Password not match"}
                                </small>
                              </div>
                            </div>
                            <GreenButton
                              action={handleUpdatePassword}
                              width="130px"
                              height="30px"
                              textSize={fontSize.title3}
                              text="Update"
                              bgcolor={
                                password && repeatPassword
                                  ? "#00BB99"
                                  : "#AAAAAA"
                              }
                            />
                          </div>
                        ) : null}
                      </Form.Group>
                    </div>
                  </InputContainer>
                </>
              ) : null}
            </Col>
            <Col>
              {userLoginDetail.id !== mentor.id ? (
                <InputContainer>
                  <InputLabel>Email Address</InputLabel>
                  <InputMentor
                    disabled={true}
                    placeholder="e.g: ccs@mail.com"
                    {...register("email", {
                      required: "Please input your email address",
                      value: mentor && mentor.email,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "email format is invalid, please try again..",
                      },
                    })}
                  />
                  {/* {ErrorHandler("email")} */}
                </InputContainer>
              ) : null}
              <InputContainer>
                <InputLabel>Address</InputLabel>
                <TextAreaMentor
                  disabled={isFormDisabled}
                  {...register("address", {
                    required: "Please input your address",
                    value:
                      mentor &&
                      mentor.user_details &&
                      mentor.user_details.address,
                  })}
                  placeholder="e.g: Sudirman street block 88 unit S"
                />
                {ErrorHandler("address")}
              </InputContainer>
              {/* <InputContainer>
                  <InputLabel>Role/Position</InputLabel>
                  <InputMentor
                    disabled={isFormDisabled}
                    {...register("role", {
                      required: "Please input your role/position",
                      value: mentor.role,
                    })}
                  />
                  {ErrorHandler("role")}
                </InputContainer> */}
              <FlexRow>
                <InputContainer>
                  <InputLabel>Place of Birth</InputLabel>
                  <InputMentor
                    disabled={isFormDisabled}
                    {...register("place_of_birth", {
                      required: "Please input your place of birth",
                      value:
                        mentor &&
                        mentor.user_details &&
                        mentor.user_details.place_of_birth,
                    })}
                    placeholder="Input your place of birth here"
                  />
                  {ErrorHandler("place_of_birth")}
                </InputContainer>
                <InputContainer>
                  <InputLabel>Date of Birth</InputLabel>
                  <ReactDatePicker
                    disabled={isFormDisabled}
                    date={
                      date ||
                      (mentor &&
                        mentor.user_details &&
                        mentor.user_details.date_of_birth)
                    }
                    handleDate={(date) => handleDate(date)}
                    format="dd/MM/yyyy"
                  />
                  <ErrorInput>{errorDate}</ErrorInput>
                </InputContainer>
              </FlexRow>
              <InputContainer>
                <InputLabel>Area of Interest / Skills</InputLabel>
                <TagsInput
                  disabled={isFormDisabled}
                  hidePlusBtn={isFormDisabled}
                  tags={
                    skills ||
                    (mentor &&
                      mentor.user_details &&
                      mentor.user_details.skills) ||
                    []
                  }
                  handleTags={setSkills}
                />
                <ErrorInput>{errorSkills}</ErrorInput>
              </InputContainer>
              <InputContainer>
                <InputLabel>Identification type</InputLabel>
                <CustomDropdown
                  disabled={isFormDisabled}
                  value={
                    assignedIdentification ||
                    (mentor &&
                      mentor.user_details &&
                      mentor.user_details.identification_type)
                  }
                  placeholder="Select type of your identity &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                  options={identifications}
                  selectedKey="name"
                  action={(value) =>
                    handleChangeDropdown(
                      value,
                      "assignedIdentification",
                      setAssignedIdentification
                    )
                  }
                />
                <ErrorInput>{errorIdentification}</ErrorInput>
              </InputContainer>
              <InputContainer>
                <InputLabel>Identification (ID) Number</InputLabel>
                <InputMentor
                  disabled={isFormDisabled}
                  {...register("identification_number", {
                    required: "Please input your Identification (ID) number",
                    value:
                      mentor &&
                      mentor.user_details &&
                      mentor.user_details.identification_number,
                  })}
                  placeholder="Input your ID number"
                />
                {ErrorHandler("identification_number")}
              </InputContainer>
              <InputContainer>
                <InputLabel>Educational Qualifications</InputLabel>
                <CustomDropdown
                  disabled={isFormDisabled}
                  value={
                    assignedQualification ||
                    (mentor &&
                      mentor.user_details &&
                      mentor.user_details.education_qualification)
                  }
                  placeholder="Select your last educational qualifications &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                  options={qualifications}
                  selectedKey="name"
                  action={(value) =>
                    handleChangeDropdown(
                      value,
                      "assignedQualification",
                      setAssignedQualification
                    )
                  }
                />
                <ErrorInput>{errorQualification}</ErrorInput>
              </InputContainer>
            </Col>
          </Row>
          <Col>
            <ButtonHandlerContainer>
              {!isFormDisabled && (
                <ButtonHandler
                  value="save"
                  color="#00BB99"
                  onSubmit={handleSubmit((data, e) =>
                    editMentor(data, e, "save")
                  )}
                >
                  Save
                </ButtonHandler>
              )}
            </ButtonHandlerContainer>
          </Col>
          <ModalComponent
            show={isModalAssignedRole}
            body={[
              <p style={{ fontWeight: "700" }} key={0}>
                Do you want to save changes?
              </p>,
            ]}
            buttonPrimary="Yes"
            buttonSecondary="No"
            onPrimaryClick={() => handleChangeUserRole()}
            onSecondaryClick={handleCancelModalAssignedRole}
            handleClose={handleCancelModalAssignedRole}
          />
        </FormMentor>
      );
    } else if (infoName === "education") {
      return (
        <FormMentor onSubmit={handleSubmit((data, e) => editMentor(data, e))}>
          <Row>
            <Col>
              <FlexRow>
                {/* <InputContainer>
                    <InputLabel>Title</InputLabel>
                    <CustomDropdown
                      disabled={isFormDisabled}
                      value={assignedTitle || mentor.mentor_title}
                      placeholder="Select title &nbsp; &nbsp;"
                      options={mentorsTitle}
                      selectedKey="name"
                      action={(value) =>
                        handleChangeDropdown(value, "assignedTitle")
                      }
                    />
                    <ErrorInput>{errorTitle}</ErrorInput>
                  </InputContainer> */}
                <InputContainer>
                  <InputLabel>Gender</InputLabel>
                  <RadioButton
                    disabled={isFormDisabled}
                    name="gender"
                    data={["male", "female"]}
                    state={
                      assignedGender ||
                      (mentor &&
                        mentor.user_details &&
                        mentor.user_details.gender)
                    }
                    handleChange={handleGender}
                  />
                  <ErrorInput>{errorGenders}</ErrorInput>
                </InputContainer>
              </FlexRow>
              <FlexRow>
                <InputContainer>
                  <InputLabel>First Name</InputLabel>
                  <InputMentor
                    disabled={isFormDisabled}
                    placeholder="Input first name here"
                    {...register("first_name", {
                      required: "Please input your first name",
                      value:
                        mentor &&
                        mentor.user_details &&
                        mentor.user_details.first_name,
                    })}
                  />
                  {ErrorHandler("first_name")}
                </InputContainer>
                <InputContainer>
                  <InputLabel>Last Name</InputLabel>
                  <InputMentor
                    disabled={isFormDisabled}
                    placeholder="Input last name here"
                    {...register("last_name", {
                      required: "Please input your last name",
                      value:
                        mentor &&
                        mentor.user_details &&
                        mentor.user_details.last_name,
                    })}
                  />
                  {ErrorHandler("last_name")}
                </InputContainer>
              </FlexRow>
              <InputContainer>
                <InputLabel>Company/Organization</InputLabel>
                <InputMentor
                  disabled={isFormDisabled}
                  {...register("company_name", {
                    required: "Please input your company",
                    value:
                      mentor &&
                      mentor.user_details &&
                      mentor.user_details.company_name,
                  })}
                  placeholder="e.g: ABC company"
                />
                {ErrorHandler("company_name")}
              </InputContainer>
              <InputContainer>
                <InputLabel>Industry</InputLabel>
                <CustomDropdown
                  disabled={isFormDisabled}
                  value={
                    (assignedIndustry && assignedIndustry.name) ||
                    (mentor &&
                      mentor.user_details &&
                      mentor.user_details.industry &&
                      mentor.user_details.industry.name)
                  }
                  placeholder="Select one related industry &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                  options={industries}
                  selectedKey={"name"}
                  label={"assignedIndustry"}
                  action={(value) =>
                    handleChangeDropdown(
                      value,
                      "assignedIndustry",
                      setAssignedIndustry
                    )
                  }
                />
                <ErrorInput>{errorIndustry}</ErrorInput>
              </InputContainer>
              <InputContainer>
                <InputLabel>Phone Number</InputLabel>
                <div className="inputPhoneBox">
                  <div
                    className="inputPhoneCode"
                    onClick={() =>
                      !isFormDisabled
                        ? setIsOpenPhoneDropdown(!isOpenPhoneDropdown)
                        : null
                    }
                    style={{
                      border: isFormDisabled
                        ? "2px solid #9BDDCC"
                        : "2px solid #00bb99",
                    }}
                  >
                    {phoneNumber && phoneNumber.icon ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CIcon icon={phoneNumber.icon} size="xxl" />
                      </div>
                    ) : (
                      "Country"
                    )}
                  </div>
                  <InputText
                    placeholder={"Fill in phone number"}
                    background={
                      errors && errors.phone_number
                        ? "#FFFFFF"
                        : isFormDisabled
                        ? "#F5FBFA"
                        : "transparent"
                    }
                    name={"phoneNumberMyProfile"}
                    border={
                      isFormDisabled ? "2px solid #9BDDCC" : "2px solid #00bb99"
                    }
                    disabled={isFormDisabled}
                    functionSetter={handleOnChangePhoneNumber}
                    phoneNumberState={phoneNumber}
                    setterPhoneNumber={setPhoneNumber}
                    className={"userFormInputStyle"}
                    fontColor={isFormDisabled ? "#7f7f7f" : "#000000"}
                    value={
                      phoneNumber &&
                      phoneNumber.number &&
                      phoneNumber.phone_code
                        ? `(${phoneNumber.phone_code}) ${phoneNumber.number}`
                        : phoneNumber &&
                          phoneNumber.phone_code &&
                          !phoneNumber.number
                        ? `(${phoneNumber.phone_code}) `
                        : phoneNumber &&
                          phoneNumber.number &&
                          !phoneNumber.phone_code
                        ? phoneNumber.number
                        : ""
                    }
                  />
                </div>
                <ErrorInput>
                  {!phoneNumber.country_id &&
                  !phoneNumber.number &&
                  errors?.country_id &&
                  errors.country_id.message &&
                  errors?.phone_number &&
                  errors.phone_number.message
                    ? "Country and Phone Number is required"
                    : (!phoneNumber.country_id &&
                        errors?.country_id &&
                        errors.country_id.message) ||
                      (errors?.phone_number && errors.phone_number.message)}
                </ErrorInput>
                <div className="inputContainer" style={{ marginTop: "0" }}>
                  <div>
                    {isOpenPhoneDropdown ? (
                      <InputDropdown
                        options={countryOptions}
                        styles={{
                          marginTop: "1em",
                          border: "2px solid #00BB99",
                          background: "transparent",
                          borderRadius: "10px",
                          height: "48px",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        state={phoneNumber}
                        setter={setPhoneNumber}
                        functionSetter={handleChangePhoneNumberDropdown}
                      />
                    ) : null}
                  </div>
                </div>
                {/* <InputMentor
                    disabled={isFormDisabled}
                    {...register("phone_number", {
                      required: "Please input your phone number",
                      value: mentor.phone_number,
                    })}
                  /> */}
                {/* {ErrorHandler("phone_number")} */}
              </InputContainer>

              <FlexRow>
                <InputContainer>
                  <InputLabel>Country</InputLabel>
                  {/* <QuickFilterDropdown
                      isDisabled={isFormDisabled}
                      options={countries}
                      placeholder="Input country"
                      state={assignedCountry}
                      handleState={setAssignedCountry}
                    />
                    <ErrorInput>{errorCountry}</ErrorInput> */}
                  <InputDropdown
                    options={countryOptions}
                    styles={{
                      border: isFormDisabled
                        ? "2px solid #9BDDCC"
                        : "2px solid #00bb99",
                      background:
                        errors && errors.country_id
                          ? "#FFFFFF"
                          : isFormDisabled
                          ? "#F5FBFA"
                          : "transparent",
                      borderRadius: "10px",
                      height: "48px",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    placeholder="Select..."
                    disabled={isFormDisabled}
                    state={phoneNumber}
                    setter={setPhoneNumber}
                    functionSetter={handleChangePhoneNumberDropdown}
                    value={
                      phoneNumber &&
                      phoneNumber.value &&
                      phoneNumber.value.label
                        ? phoneNumber && phoneNumber.value
                        : null
                    }
                  />
                  <ErrorInput>
                    {!phoneNumber.country_id &&
                      errors?.country_id &&
                      errors.country_id.message}
                  </ErrorInput>
                </InputContainer>
                <InputContainer>
                  <InputLabel>State/Province</InputLabel>
                  <InputDropdown
                    options={stateOptions}
                    styles={{
                      border: isFormDisabled
                        ? "2px solid #9BDDCC"
                        : "2px solid #00bb99",
                      background:
                        errors && errors.province
                          ? "#FFFFFF"
                          : isFormDisabled
                          ? "#F5FBFA"
                          : "transparent",
                      borderRadius: "10px",
                      height: "48px",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    disabled={isFormDisabled}
                    setter={setProvinceValue}
                    state={"province"}
                    functionSetter={handleChangeDropdown}
                    value={provinceValue}
                  />
                  <ErrorInput>
                    {errors?.province && errors.province.message}
                  </ErrorInput>
                  {/* <InputMentor
                    disabled={isFormDisabled}
                    {...register("state_or_province", {
                      required: "Please input your state/province",
                      value: mentor.state_or_province,
                    })}
                  />
                  {ErrorHandler("state_or_province")} */}
                </InputContainer>
              </FlexRow>
              <FlexRow>
                <InputContainer>
                  <InputLabel>City</InputLabel>
                  <InputDropdown
                    options={cityOptions}
                    styles={{
                      border: isFormDisabled
                        ? "2px solid #9BDDCC"
                        : "2px solid #00bb99",
                      background:
                        errors && errors.city
                          ? "#FFFFFF"
                          : isFormDisabled
                          ? "#F5FBFA"
                          : "transparent",
                      borderRadius: "10px",
                      height: "48px",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    disabled={isFormDisabled}
                    setter={setCityValue}
                    state={"city"}
                    functionSetter={handleChangeDropdown}
                    value={cityValue}
                  />
                  <ErrorInput>{errors?.city && errors.city.message}</ErrorInput>
                  {/* <InputMentor
                    disabled={isFormDisabled}
                    {...register("city", {
                      required: "Please input your city",
                      value: mentor.city,
                    })}
                  />
                  {ErrorHandler("state_or_province")} */}
                </InputContainer>
                <InputContainer>
                  <InputLabel>Postal Code</InputLabel>
                  <InputMentor
                    disabled={isFormDisabled}
                    {...register("postal_code", {
                      required: "Please input your postal code",
                      value:
                        mentor &&
                        mentor.user_details &&
                        mentor.user_details.postal_code,
                    })}
                    placeholder="Input your postal code here"
                  />
                  {ErrorHandler("postal_code")}
                </InputContainer>
              </FlexRow>
              {mentor.id === userLoginDetail.id ? (
                <>
                  <InputContainer>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div className="userFormInputGroupFlexContainer">
                          <div
                            className="userFormInputLabel"
                            style={{
                              fontSize: fontSize.title2,
                            }}
                          >
                            {isChangingEmail ? "New email address" : "Email"}
                          </div>
                          {isChangingEmail ? (
                            <CancelText onClick={() => setChangingEmail(false)}>
                              Cancel
                            </CancelText>
                          ) : (
                            <InputAction
                              onClick={() =>
                                !isFormDisabled ? setChangingEmail(true) : null
                              }
                              disabled={isFormDisabled}
                            >
                              Change
                            </InputAction>
                          )}
                        </div>
                        {isChangingEmail ? (
                          <div className="userFormInputGroupFlexContainer">
                            <input
                              style={{
                                // width: smallScreen ? "100%" : "60%",
                                width: "60%",
                                background: "#FFFFFF",
                                border: "2px solid #00BB99",
                              }}
                              value={newEmailAddress}
                              onChange={(e) =>
                                setNewEmailAddress(e.target.value)
                              }
                              type="email"
                              placeholder="e.g: john@example.com"
                              className="userFormInputStyle"
                            />
                            {newEmailAddress !== "" ? (
                              <GreenButton
                                action={handleUpdateEmail}
                                width="130px"
                                height="30px"
                                textSize={fontSize.title3}
                                text="Update"
                                bgcolor={
                                  newEmailAddress ? "#00BB99" : "#AAAAAA"
                                }
                              />
                            ) : null}
                          </div>
                        ) : (
                          <div className="userFormInputGroupFlexContainer">
                            <div
                              style={{
                                fontSize: fontSize.title3,
                                fontWeight: "400",
                              }}
                            >
                              Your email address is
                              <b> {mentor?.email}</b>
                            </div>
                          </div>
                        )}
                      </Form.Group>
                    </div>
                  </InputContainer>
                  <InputContainer>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div className="userFormInputGroupFlexContainer">
                          <div
                            className="userFormInputLabel"
                            style={{
                              fontSize: fontSize.title2,
                            }}
                          >
                            {isChangingPassword ? (
                              <>
                                <span>New password</span>
                                <Tooltip content="Your password must at least 8 characters, including uppercase, lowercase, numbers, and special characters" />
                              </>
                            ) : (
                              <span>Password</span>
                            )}
                          </div>
                          {isChangingPassword ? (
                            <CancelText
                              onClick={() => setChangingPassword(false)}
                            >
                              Cancel
                            </CancelText>
                          ) : (
                            <InputAction
                              onClick={() =>
                                !isFormDisabled
                                  ? setChangingPassword(true)
                                  : null
                              }
                              disabled={isFormDisabled}
                            >
                              Change
                            </InputAction>
                          )}
                        </div>
                        {isChangingPassword ? (
                          <div>
                            <div
                              className="userFormInputGroupFlexContainer"
                              style={{ marginBottom: "10px" }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <input
                                  style={{
                                    width: "100%",
                                    background: "#FFFFFF",
                                    border: "2px solid #00BB99",
                                  }}
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  type={passwordType}
                                  placeholder="New password"
                                  className="userFormInputStyle"
                                />

                                <input
                                  autoComplete="off"
                                  style={{ display: "none" }}
                                  id="show1"
                                  type="checkbox"
                                  checked={passwordType === "text"}
                                  onChange={() =>
                                    setPasswordType((prev) =>
                                      prev === "password" ? "text" : "password"
                                    )
                                  }
                                />
                                <label
                                  htmlFor="show1"
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    margin: "0 12px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {passwordType === "password" ? (
                                    <AiFillEye size={25} />
                                  ) : (
                                    <AiFillEyeInvisible size={25} />
                                  )}
                                </label>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  marginTop:
                                    !repeatPassword || checkRepeatPassword()
                                      ? null
                                      : "1.3em",
                                }}
                              >
                                <div
                                  style={{
                                    position: "relative",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <input
                                    style={{
                                      width: "100%",
                                      background: "#FFFFFF",
                                      border: "2px solid #00BB99",
                                    }}
                                    value={repeatPassword}
                                    onChange={(e) =>
                                      setRepeatPassword(e.target.value)
                                    }
                                    type={repeatPasswordType}
                                    placeholder="Repeat password"
                                    className="userFormInputStyle"
                                  />
                                  <input
                                    autoComplete="off"
                                    style={{ display: "none" }}
                                    id="show2"
                                    type="checkbox"
                                    checked={repeatPasswordType === "text"}
                                    onChange={() =>
                                      setRepeatPasswordType((prev) =>
                                        prev === "password"
                                          ? "text"
                                          : "password"
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor="show2"
                                    style={{
                                      position: "absolute",
                                      right: 0,
                                      margin: "0 12px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {repeatPasswordType === "password" ? (
                                      <AiFillEye size={25} />
                                    ) : (
                                      <AiFillEyeInvisible size={25} />
                                    )}
                                  </label>
                                </div>
                                <small
                                  className="text-danger"
                                  style={{
                                    userSelect: "none",
                                    display: "flex",
                                    textAlign: "left",
                                  }}
                                >
                                  {!repeatPassword || checkRepeatPassword()
                                    ? null
                                    : "Password not match"}
                                </small>
                              </div>
                            </div>
                            <GreenButton
                              action={handleUpdatePassword}
                              width="130px"
                              height="30px"
                              textSize={fontSize.title3}
                              text="Update"
                              bgcolor={
                                password && repeatPassword
                                  ? "#00BB99"
                                  : "#AAAAAA"
                              }
                            />
                          </div>
                        ) : null}
                      </Form.Group>
                    </div>
                  </InputContainer>
                </>
              ) : null}
            </Col>
            <Col>
              {userLoginDetail.id !== mentor.id ? (
                <InputContainer>
                  <InputLabel>Email Address</InputLabel>
                  <InputMentor
                    disabled={true}
                    placeholder="e.g: ccs@mail.com"
                    {...register("email", {
                      required: "Please input your email address",
                      value: mentor && mentor.email,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "email format is invalid, please try again..",
                      },
                    })}
                  />
                  {/* {ErrorHandler("email")} */}
                </InputContainer>
              ) : null}
              <InputContainer>
                <InputLabel>Address</InputLabel>
                <TextAreaMentor
                  disabled={isFormDisabled}
                  {...register("address", {
                    required: "Please input your address",
                    value:
                      mentor &&
                      mentor.user_details &&
                      mentor.user_details.address,
                  })}
                  placeholder="e.g: Sudirman street block 88 unit S"
                />
                {ErrorHandler("address")}
              </InputContainer>
              {/* <InputContainer>
                  <InputLabel>Role/Position</InputLabel>
                  <InputMentor
                    disabled={isFormDisabled}
                    {...register("role", {
                      required: "Please input your role/position",
                      value: mentor.role,
                    })}
                  />
                  {ErrorHandler("role")}
                </InputContainer> */}
              <FlexRow>
                <InputContainer>
                  <InputLabel>Place of Birth</InputLabel>
                  <InputMentor
                    disabled={isFormDisabled}
                    {...register("place_of_birth", {
                      required: "Please input your place of birth",
                      value:
                        mentor &&
                        mentor.user_details &&
                        mentor.user_details.place_of_birth,
                    })}
                    placeholder="Input your place of birth here"
                  />
                  {ErrorHandler("place_of_birth")}
                </InputContainer>
                <InputContainer>
                  <InputLabel>Date of Birth</InputLabel>
                  <ReactDatePicker
                    disabled={isFormDisabled}
                    date={
                      date ||
                      (mentor &&
                        mentor.user_details &&
                        mentor.user_details.date_of_birth)
                    }
                    handleDate={(date) => handleDate(date)}
                    format="dd/MM/yyyy"
                  />
                  <ErrorInput>{errorDate}</ErrorInput>
                </InputContainer>
              </FlexRow>
              <InputContainer>
                <InputLabel>Area of Interest / Skills</InputLabel>
                <TagsInput
                  disabled={isFormDisabled}
                  hidePlusBtn={isFormDisabled}
                  tags={
                    skills ||
                    (mentor &&
                      mentor.user_details &&
                      mentor.user_details.skills) ||
                    []
                  }
                  handleTags={setSkills}
                />
                <ErrorInput>{errorSkills}</ErrorInput>
              </InputContainer>
              <InputContainer>
                <InputLabel>Identification type</InputLabel>
                <CustomDropdown
                  disabled={isFormDisabled}
                  value={
                    assignedIdentification ||
                    (mentor &&
                      mentor.user_details &&
                      mentor.user_details.identification_type)
                  }
                  placeholder="Select type of your identity &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                  options={identifications}
                  selectedKey="name"
                  action={(value) =>
                    handleChangeDropdown(
                      value,
                      "assignedIdentification",
                      setAssignedIdentification
                    )
                  }
                />
                <ErrorInput>{errorIdentification}</ErrorInput>
              </InputContainer>
              <InputContainer>
                <InputLabel>Identification (ID) Number</InputLabel>
                <InputMentor
                  disabled={isFormDisabled}
                  {...register("identification_number", {
                    required: "Please input your Identification (ID) number",
                    value:
                      mentor &&
                      mentor.user_details &&
                      mentor.user_details.identification_number,
                  })}
                  placeholder="Input your ID number"
                />
                {ErrorHandler("identification_number")}
              </InputContainer>
              <InputContainer>
                <InputLabel>Educational Qualifications</InputLabel>
                <CustomDropdown
                  disabled={isFormDisabled}
                  value={
                    assignedQualification ||
                    (mentor &&
                      mentor.user_details &&
                      mentor.user_details.education_qualification)
                  }
                  placeholder="Select your last educational qualifications &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                  options={qualifications}
                  selectedKey="name"
                  action={(value) =>
                    handleChangeDropdown(
                      value,
                      "assignedQualification",
                      setAssignedQualification
                    )
                  }
                />
                <ErrorInput>{errorQualification}</ErrorInput>
              </InputContainer>
            </Col>
          </Row>
          <Col>
            <ButtonHandlerContainer>
              {!isFormDisabled && (
                <ButtonHandler onClick={(e) => cancelEdit(e)} color="#AAAAAA">
                  Cancel
                </ButtonHandler>
              )}
              {!isFormDisabled && (
                <ButtonHandler
                  value="save"
                  color="#00BB99"
                  onSubmit={handleSubmit((data, e) =>
                    editMentor(data, e, "save")
                  )}
                >
                  Save
                </ButtonHandler>
              )}
            </ButtonHandlerContainer>
          </Col>
          <ModalComponent
            show={isModal}
            body={[
              <p style={{ fontWeight: "700" }} key={0}>
                Are you sure want to cancel?
              </p>,
            ]}
            buttonPrimary="Yes"
            buttonSecondary="No"
            onPrimaryClick={() => handleCancel()}
            onSecondaryClick={() => setModal(false)}
            handleClose={() => setModal(false)}
          />
          <ModalComponent
            show={isModalAssignedRole}
            body={[
              <p style={{ fontWeight: "700" }} key={0}>
                Do you want to save changes?
              </p>,
            ]}
            buttonPrimary="Yes"
            buttonSecondary="No"
            onPrimaryClick={() => handleChangeUserRole()}
            onSecondaryClick={handleCancelModalAssignedRole}
            handleClose={handleCancelModalAssignedRole}
          />
        </FormMentor>
      );
    } else if (infoName === "supporting") {
      return (
        <FormMentor onSubmit={handleSubmit((data, e) => editMentor(data, e))}>
          <Row>
            <Col>
              <FlexRow>
                {/* <InputContainer>
                    <InputLabel>Title</InputLabel>
                    <CustomDropdown
                      disabled={isFormDisabled}
                      value={assignedTitle || mentor.mentor_title}
                      placeholder="Select title &nbsp; &nbsp;"
                      options={mentorsTitle}
                      selectedKey="name"
                      action={(value) =>
                        handleChangeDropdown(value, "assignedTitle")
                      }
                    />
                    <ErrorInput>{errorTitle}</ErrorInput>
                  </InputContainer> */}
                <InputContainer>
                  <InputLabel>Gender</InputLabel>
                  <RadioButton
                    disabled={isFormDisabled}
                    name="gender"
                    data={["male", "female"]}
                    state={
                      assignedGender ||
                      (mentor &&
                        mentor.user_details &&
                        mentor.user_details.gender)
                    }
                    handleChange={handleGender}
                  />
                  <ErrorInput>{errorGenders}</ErrorInput>
                </InputContainer>
              </FlexRow>
              <FlexRow>
                <InputContainer>
                  <InputLabel>First Name</InputLabel>
                  <InputMentor
                    disabled={isFormDisabled}
                    placeholder="Input first name here"
                    {...register("first_name", {
                      required: "Please input your first name",
                      value:
                        mentor &&
                        mentor.user_details &&
                        mentor.user_details.first_name,
                    })}
                  />
                  {ErrorHandler("first_name")}
                </InputContainer>
                <InputContainer>
                  <InputLabel>Last Name</InputLabel>
                  <InputMentor
                    disabled={isFormDisabled}
                    placeholder="Input last name here"
                    {...register("last_name", {
                      required: "Please input your last name",
                      value:
                        mentor &&
                        mentor.user_details &&
                        mentor.user_details.last_name,
                    })}
                  />
                  {ErrorHandler("last_name")}
                </InputContainer>
              </FlexRow>
              <InputContainer>
                <InputLabel>Company/Organization</InputLabel>
                <InputMentor
                  disabled={isFormDisabled}
                  {...register("company_name", {
                    required: "Please input your company",
                    value:
                      mentor &&
                      mentor.user_details &&
                      mentor.user_details.company_name,
                  })}
                  placeholder="e.g: ABC company"
                />
                {ErrorHandler("company_name")}
              </InputContainer>
              <InputContainer>
                <InputLabel>Industry</InputLabel>
                <CustomDropdown
                  disabled={isFormDisabled}
                  value={
                    (assignedIndustry && assignedIndustry.name) ||
                    (mentor &&
                      mentor.user_details &&
                      mentor.user_details.industry &&
                      mentor.user_details.industry.name)
                  }
                  placeholder="Select one related industry &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                  options={industries}
                  selectedKey={"name"}
                  label={"assignedIndustry"}
                  action={(value) =>
                    handleChangeDropdown(
                      value,
                      "assignedIndustry",
                      setAssignedIndustry
                    )
                  }
                />
                <ErrorInput>{errorIndustry}</ErrorInput>
              </InputContainer>
              <InputContainer>
                <InputLabel>Phone Number</InputLabel>
                <div className="inputPhoneBox">
                  <div
                    className="inputPhoneCode"
                    onClick={() =>
                      !isFormDisabled
                        ? setIsOpenPhoneDropdown(!isOpenPhoneDropdown)
                        : null
                    }
                    style={{
                      border: isFormDisabled
                        ? "2px solid #9BDDCC"
                        : "2px solid #00bb99",
                    }}
                  >
                    {phoneNumber && phoneNumber.icon ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CIcon icon={phoneNumber.icon} size="xxl" />
                      </div>
                    ) : (
                      "Country"
                    )}
                  </div>
                  <InputText
                    placeholder={"Fill in phone number"}
                    background={
                      errors && errors.phone_number
                        ? "#FFFFFF"
                        : isFormDisabled
                        ? "#F5FBFA"
                        : "transparent"
                    }
                    name={"phoneNumberMyProfile"}
                    border={
                      isFormDisabled ? "2px solid #9BDDCC" : "2px solid #00bb99"
                    }
                    disabled={isFormDisabled}
                    functionSetter={handleOnChangePhoneNumber}
                    phoneNumberState={phoneNumber}
                    setterPhoneNumber={setPhoneNumber}
                    className={"userFormInputStyle"}
                    fontColor={isFormDisabled ? "#7f7f7f" : "#000000"}
                    value={
                      phoneNumber &&
                      phoneNumber.number &&
                      phoneNumber.phone_code
                        ? `(${phoneNumber.phone_code}) ${phoneNumber.number}`
                        : phoneNumber &&
                          phoneNumber.phone_code &&
                          !phoneNumber.number
                        ? `(${phoneNumber.phone_code}) `
                        : phoneNumber &&
                          phoneNumber.number &&
                          !phoneNumber.phone_code
                        ? phoneNumber.number
                        : ""
                    }
                  />
                </div>
                <ErrorInput>
                  {!phoneNumber.country_id &&
                  !phoneNumber.number &&
                  errors?.country_id &&
                  errors.country_id.message &&
                  errors?.phone_number &&
                  errors.phone_number.message
                    ? "Country and Phone Number is required"
                    : (!phoneNumber.country_id &&
                        errors?.country_id &&
                        errors.country_id.message) ||
                      (errors?.phone_number && errors.phone_number.message)}
                </ErrorInput>
                <div className="inputContainer" style={{ marginTop: "0" }}>
                  <div>
                    {isOpenPhoneDropdown ? (
                      <InputDropdown
                        options={countryOptions}
                        styles={{
                          marginTop: "1em",
                          border: "2px solid #00BB99",
                          background: "transparent",
                          borderRadius: "10px",
                          height: "48px",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        state={phoneNumber}
                        setter={setPhoneNumber}
                        functionSetter={handleChangePhoneNumberDropdown}
                      />
                    ) : null}
                  </div>
                </div>
                {/* <InputMentor
                    disabled={isFormDisabled}
                    {...register("phone_number", {
                      required: "Please input your phone number",
                      value: mentor.phone_number,
                    })}
                  /> */}
                {/* {ErrorHandler("phone_number")} */}
              </InputContainer>

              <FlexRow>
                <InputContainer>
                  <InputLabel>Country</InputLabel>
                  {/* <QuickFilterDropdown
                      isDisabled={isFormDisabled}
                      options={countries}
                      placeholder="Input country"
                      state={assignedCountry}
                      handleState={setAssignedCountry}
                    />
                    <ErrorInput>{errorCountry}</ErrorInput> */}
                  <InputDropdown
                    options={countryOptions}
                    styles={{
                      border: isFormDisabled
                        ? "2px solid #9BDDCC"
                        : "2px solid #00bb99",
                      background:
                        errors && errors.country_id
                          ? "#FFFFFF"
                          : isFormDisabled
                          ? "#F5FBFA"
                          : "transparent",
                      borderRadius: "10px",
                      height: "48px",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    placeholder="Select..."
                    disabled={isFormDisabled}
                    state={phoneNumber}
                    setter={setPhoneNumber}
                    functionSetter={handleChangePhoneNumberDropdown}
                    value={
                      phoneNumber &&
                      phoneNumber.value &&
                      phoneNumber.value.label
                        ? phoneNumber && phoneNumber.value
                        : null
                    }
                  />
                  <ErrorInput>
                    {!phoneNumber.country_id &&
                      errors?.country_id &&
                      errors.country_id.message}
                  </ErrorInput>
                </InputContainer>
                <InputContainer>
                  <InputLabel>State/Province</InputLabel>
                  <InputDropdown
                    options={stateOptions}
                    styles={{
                      border: isFormDisabled
                        ? "2px solid #9BDDCC"
                        : "2px solid #00bb99",
                      background:
                        errors && errors.province
                          ? "#FFFFFF"
                          : isFormDisabled
                          ? "#F5FBFA"
                          : "transparent",
                      borderRadius: "10px",
                      height: "48px",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    disabled={isFormDisabled}
                    setter={setProvinceValue}
                    state={"province"}
                    functionSetter={handleChangeDropdown}
                    value={provinceValue}
                  />
                  <ErrorInput>
                    {errors?.province && errors.province.message}
                  </ErrorInput>
                  {/* <InputMentor
                    disabled={isFormDisabled}
                    {...register("state_or_province", {
                      required: "Please input your state/province",
                      value: mentor.state_or_province,
                    })}
                  />
                  {ErrorHandler("state_or_province")} */}
                </InputContainer>
              </FlexRow>
              <FlexRow>
                <InputContainer>
                  <InputLabel>City</InputLabel>
                  <InputDropdown
                    options={cityOptions}
                    styles={{
                      border: isFormDisabled
                        ? "2px solid #9BDDCC"
                        : "2px solid #00bb99",
                      background:
                        errors && errors.city
                          ? "#FFFFFF"
                          : isFormDisabled
                          ? "#F5FBFA"
                          : "transparent",
                      borderRadius: "10px",
                      height: "48px",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    disabled={isFormDisabled}
                    setter={setCityValue}
                    state={"city"}
                    functionSetter={handleChangeDropdown}
                    value={cityValue}
                  />
                  <ErrorInput>{errors?.city && errors.city.message}</ErrorInput>
                  {/* <InputMentor
                    disabled={isFormDisabled}
                    {...register("city", {
                      required: "Please input your city",
                      value: mentor.city,
                    })}
                  />
                  {ErrorHandler("state_or_province")} */}
                </InputContainer>
                <InputContainer>
                  <InputLabel>Postal Code</InputLabel>
                  <InputMentor
                    disabled={isFormDisabled}
                    {...register("postal_code", {
                      required: "Please input your postal code",
                      value:
                        mentor &&
                        mentor.user_details &&
                        mentor.user_details.postal_code,
                    })}
                    placeholder="Input your postal code here"
                  />
                  {ErrorHandler("postal_code")}
                </InputContainer>
              </FlexRow>
              {mentor.id === userLoginDetail.id ? (
                <>
                  <InputContainer>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div className="userFormInputGroupFlexContainer">
                          <div
                            className="userFormInputLabel"
                            style={{
                              fontSize: fontSize.title2,
                            }}
                          >
                            {isChangingEmail ? "New email address" : "Email"}
                          </div>
                          {isChangingEmail ? (
                            <CancelText onClick={() => setChangingEmail(false)}>
                              Cancel
                            </CancelText>
                          ) : (
                            <InputAction
                              onClick={() =>
                                !isFormDisabled ? setChangingEmail(true) : null
                              }
                              disabled={isFormDisabled}
                            >
                              Change
                            </InputAction>
                          )}
                        </div>
                        {isChangingEmail ? (
                          <div className="userFormInputGroupFlexContainer">
                            <input
                              style={{
                                // width: smallScreen ? "100%" : "60%",
                                width: "60%",
                                background: "#FFFFFF",
                                border: "2px solid #00BB99",
                              }}
                              value={newEmailAddress}
                              onChange={(e) =>
                                setNewEmailAddress(e.target.value)
                              }
                              type="email"
                              placeholder="e.g: john@example.com"
                              className="userFormInputStyle"
                            />
                            {newEmailAddress !== "" ? (
                              <GreenButton
                                action={handleUpdateEmail}
                                width="130px"
                                height="30px"
                                textSize={fontSize.title3}
                                text="Update"
                                bgcolor={
                                  newEmailAddress ? "#00BB99" : "#AAAAAA"
                                }
                              />
                            ) : null}
                          </div>
                        ) : (
                          <div className="userFormInputGroupFlexContainer">
                            <div
                              style={{
                                fontSize: fontSize.title3,
                                fontWeight: "400",
                              }}
                            >
                              Your email address is
                              <b> {mentor?.email}</b>
                            </div>
                          </div>
                        )}
                      </Form.Group>
                    </div>
                  </InputContainer>
                  <InputContainer>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div className="userFormInputGroupFlexContainer">
                          <div
                            className="userFormInputLabel"
                            style={{
                              fontSize: fontSize.title2,
                            }}
                          >
                            {isChangingPassword ? (
                              <>
                                <span>New password</span>
                                <Tooltip content="Your password must at least 8 characters, including uppercase, lowercase, numbers, and special characters" />
                              </>
                            ) : (
                              <span>Password</span>
                            )}
                          </div>
                          {isChangingPassword ? (
                            <CancelText
                              onClick={() => setChangingPassword(false)}
                            >
                              Cancel
                            </CancelText>
                          ) : (
                            <InputAction
                              onClick={() =>
                                !isFormDisabled
                                  ? setChangingPassword(true)
                                  : null
                              }
                              disabled={isFormDisabled}
                            >
                              Change
                            </InputAction>
                          )}
                        </div>
                        {isChangingPassword ? (
                          <div>
                            <div
                              className="userFormInputGroupFlexContainer"
                              style={{ marginBottom: "10px" }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <input
                                  style={{
                                    width: "100%",
                                    background: "#FFFFFF",
                                    border: "2px solid #00BB99",
                                  }}
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  type={passwordType}
                                  placeholder="New password"
                                  className="userFormInputStyle"
                                />

                                <input
                                  autoComplete="off"
                                  style={{ display: "none" }}
                                  id="show1"
                                  type="checkbox"
                                  checked={passwordType === "text"}
                                  onChange={() =>
                                    setPasswordType((prev) =>
                                      prev === "password" ? "text" : "password"
                                    )
                                  }
                                />
                                <label
                                  htmlFor="show1"
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    margin: "0 12px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {passwordType === "password" ? (
                                    <AiFillEye size={25} />
                                  ) : (
                                    <AiFillEyeInvisible size={25} />
                                  )}
                                </label>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  marginTop:
                                    !repeatPassword || checkRepeatPassword()
                                      ? null
                                      : "1.3em",
                                }}
                              >
                                <div
                                  style={{
                                    position: "relative",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <input
                                    style={{
                                      width: "100%",
                                      background: "#FFFFFF",
                                      border: "2px solid #00BB99",
                                    }}
                                    value={repeatPassword}
                                    onChange={(e) =>
                                      setRepeatPassword(e.target.value)
                                    }
                                    type={repeatPasswordType}
                                    placeholder="Repeat password"
                                    className="userFormInputStyle"
                                  />
                                  <input
                                    autoComplete="off"
                                    style={{ display: "none" }}
                                    id="show2"
                                    type="checkbox"
                                    checked={repeatPasswordType === "text"}
                                    onChange={() =>
                                      setRepeatPasswordType((prev) =>
                                        prev === "password"
                                          ? "text"
                                          : "password"
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor="show2"
                                    style={{
                                      position: "absolute",
                                      right: 0,
                                      margin: "0 12px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {repeatPasswordType === "password" ? (
                                      <AiFillEye size={25} />
                                    ) : (
                                      <AiFillEyeInvisible size={25} />
                                    )}
                                  </label>
                                </div>
                                <small
                                  className="text-danger"
                                  style={{
                                    userSelect: "none",
                                    display: "flex",
                                    textAlign: "left",
                                  }}
                                >
                                  {!repeatPassword || checkRepeatPassword()
                                    ? null
                                    : "Password not match"}
                                </small>
                              </div>
                            </div>
                            <GreenButton
                              action={handleUpdatePassword}
                              width="130px"
                              height="30px"
                              textSize={fontSize.title3}
                              text="Update"
                              bgcolor={
                                password && repeatPassword
                                  ? "#00BB99"
                                  : "#AAAAAA"
                              }
                            />
                          </div>
                        ) : null}
                      </Form.Group>
                    </div>
                  </InputContainer>
                </>
              ) : null}
            </Col>
            <Col>
              {userLoginDetail.id !== mentor.id ? (
                <InputContainer>
                  <InputLabel>Email Address</InputLabel>
                  <InputMentor
                    disabled={true}
                    placeholder="e.g: ccs@mail.com"
                    {...register("email", {
                      required: "Please input your email address",
                      value: mentor && mentor.email,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "email format is invalid, please try again..",
                      },
                    })}
                  />
                  {/* {ErrorHandler("email")} */}
                </InputContainer>
              ) : null}
              <InputContainer>
                <InputLabel>Address</InputLabel>
                <TextAreaMentor
                  disabled={isFormDisabled}
                  {...register("address", {
                    required: "Please input your address",
                    value:
                      mentor &&
                      mentor.user_details &&
                      mentor.user_details.address,
                  })}
                  placeholder="e.g: Sudirman street block 88 unit S"
                />
                {ErrorHandler("address")}
              </InputContainer>
              {/* <InputContainer>
                  <InputLabel>Role/Position</InputLabel>
                  <InputMentor
                    disabled={isFormDisabled}
                    {...register("role", {
                      required: "Please input your role/position",
                      value: mentor.role,
                    })}
                  />
                  {ErrorHandler("role")}
                </InputContainer> */}
              <FlexRow>
                <InputContainer>
                  <InputLabel>Place of Birth</InputLabel>
                  <InputMentor
                    disabled={isFormDisabled}
                    {...register("place_of_birth", {
                      required: "Please input your place of birth",
                      value:
                        mentor &&
                        mentor.user_details &&
                        mentor.user_details.place_of_birth,
                    })}
                    placeholder="Input your place of birth here"
                  />
                  {ErrorHandler("place_of_birth")}
                </InputContainer>
                <InputContainer>
                  <InputLabel>Date of Birth</InputLabel>
                  <ReactDatePicker
                    disabled={isFormDisabled}
                    date={
                      date ||
                      (mentor &&
                        mentor.user_details &&
                        mentor.user_details.date_of_birth)
                    }
                    handleDate={(date) => handleDate(date)}
                    format="dd/MM/yyyy"
                  />
                  <ErrorInput>{errorDate}</ErrorInput>
                </InputContainer>
              </FlexRow>
              <InputContainer>
                <InputLabel>Area of Interest / Skills</InputLabel>
                <TagsInput
                  disabled={isFormDisabled}
                  hidePlusBtn={isFormDisabled}
                  tags={
                    skills ||
                    (mentor &&
                      mentor.user_details &&
                      mentor.user_details.skills) ||
                    []
                  }
                  handleTags={setSkills}
                />
                <ErrorInput>{errorSkills}</ErrorInput>
              </InputContainer>
              <InputContainer>
                <InputLabel>Identification type</InputLabel>
                <CustomDropdown
                  disabled={isFormDisabled}
                  value={
                    assignedIdentification ||
                    (mentor &&
                      mentor.user_details &&
                      mentor.user_details.identification_type)
                  }
                  placeholder="Select type of your identity &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                  options={identifications}
                  selectedKey="name"
                  action={(value) =>
                    handleChangeDropdown(
                      value,
                      "assignedIdentification",
                      setAssignedIdentification
                    )
                  }
                />
                <ErrorInput>{errorIdentification}</ErrorInput>
              </InputContainer>
              <InputContainer>
                <InputLabel>Identification (ID) Number</InputLabel>
                <InputMentor
                  disabled={isFormDisabled}
                  {...register("identification_number", {
                    required: "Please input your Identification (ID) number",
                    value:
                      mentor &&
                      mentor.user_details &&
                      mentor.user_details.identification_number,
                  })}
                  placeholder="Input your ID number"
                />
                {ErrorHandler("identification_number")}
              </InputContainer>
              <InputContainer>
                <InputLabel>Educational Qualifications</InputLabel>
                <CustomDropdown
                  disabled={isFormDisabled}
                  value={
                    assignedQualification ||
                    (mentor &&
                      mentor.user_details &&
                      mentor.user_details.education_qualification)
                  }
                  placeholder="Select your last educational qualifications &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                  options={qualifications}
                  selectedKey="name"
                  action={(value) =>
                    handleChangeDropdown(
                      value,
                      "assignedQualification",
                      setAssignedQualification
                    )
                  }
                />
                <ErrorInput>{errorQualification}</ErrorInput>
              </InputContainer>
            </Col>
          </Row>
          <Col>
            <ButtonHandlerContainer>
              {!isFormDisabled && (
                <ButtonHandler onClick={(e) => cancelEdit(e)} color="#AAAAAA">
                  Cancel
                </ButtonHandler>
              )}
              {!isFormDisabled && (
                <ButtonHandler
                  value="save"
                  color="#00BB99"
                  onSubmit={handleSubmit((data, e) =>
                    editMentor(data, e, "save")
                  )}
                >
                  Save
                </ButtonHandler>
              )}
            </ButtonHandlerContainer>
          </Col>
          <ModalComponent
            show={isModal}
            body={[
              <p style={{ fontWeight: "700" }} key={0}>
                Are you sure want to cancel?
              </p>,
            ]}
            buttonPrimary="Yes"
            buttonSecondary="No"
            onPrimaryClick={() => handleCancel()}
            onSecondaryClick={() => setModal(false)}
            handleClose={() => setModal(false)}
          />
          <ModalComponent
            show={isModalAssignedRole}
            body={[
              <p style={{ fontWeight: "700" }} key={0}>
                Do you want to save changes?
              </p>,
            ]}
            buttonPrimary="Yes"
            buttonSecondary="No"
            onPrimaryClick={() => handleChangeUserRole()}
            onSecondaryClick={handleCancelModalAssignedRole}
            handleClose={handleCancelModalAssignedRole}
          />
        </FormMentor>
      );
    } else if (infoName === "schedule") {
      return (
        <ScheduleContainer>
          <FlexRow>
            <ScheduleSubtitle>Topics Assigned</ScheduleSubtitle>
            <MentorButton
              text="Assign more topics"
              handleButton={showTopicModal}
              disabled={isFormDisabled}
            />
          </FlexRow>
          {topicMentorState &&
          Array.isArray(topicMentorState) &&
          topicMentorState.length > 0 ? (
            <div
              style={
                topicMentorState && topicMentorState.length < 3
                  ? { maxWidth: "50vw" }
                  : {}
              }
            >
              <ItemSlider childrens={topicMentorState} name="topics" />
            </div>
          ) : (
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                flexDirection: "column",
                gap: "1em",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={dayoff_empty_schedule_icon}
                alt="day off icon"
                width={300}
                height={160}
              />
              <p
                style={{
                  fontWeight: 500,
                  fontSize: "18px",
                  marginTop: "1em",
                  color: "#4285F4",
                }}
              >
                {userLoginDetail &&
                userLoginDetail.id &&
                user_id &&
                +userLoginDetail.id === +user_id
                  ? "You don't have a topic assigned yet."
                  : "This mentor does not have a topic assigned yet."}
              </p>
            </div>
          )}
          <Row style={{ marginTop: "3.5em" }}>
            <Col>
              <ScheduleSubtitle>Schedule</ScheduleSubtitle>
              <div>
                <ReactDayPicker
                  selectedDate={assignedScheduleDate}
                  onSelectedDate={(date, month) =>
                    handleScheduleDate(date, month)
                  }
                  scheduledDates={mentorSchedulesAll.map((value) => {
                    return {
                      id: value.id,
                      date: value.date,
                      is_available: value.is_available,
                    };
                  })}
                />
              </div>
              <MentorButton
                text="Add new schedule"
                handleButton={openAddSchedule}
                disabled={
                  isFormDisabled ||
                  timeslotsHandler(assignedScheduleDate) <
                    timeslotsHandler(new Date()) ||
                  mentorTopics.length === 0 ||
                  mentorTopicsId.length === 0
                }
              />
            </Col>
            <Col>
              <div
                style={{
                  borderBottom: "1px solid #000000",
                  maxWidth: "400px",
                }}
              >
                &nbsp;
              </div>
              <ScheduleDetailsContainer>
                <FlexRow justifyContent="space-between" maxWidth="385px">
                  <DateIndicator>
                    <DateIndicatorDay>
                      {dayHandler(assignedScheduleDate)}
                    </DateIndicatorDay>
                    <DateIndicatorNumber>
                      {dateHandler(assignedScheduleDate)}
                    </DateIndicatorNumber>
                  </DateIndicator>
                  <ScheduleDetailsText>
                    <DateIndicatorSubtitle>
                      SCHEDULE DETAILS
                    </DateIndicatorSubtitle>
                    <DateIndicatorCoaching>
                      Coaching session availability
                    </DateIndicatorCoaching>
                  </ScheduleDetailsText>
                  {timeslotsHandler(assignedScheduleDate) <
                    timeslotsHandler(new Date()) ||
                  mentorTopics.length === 0 ||
                  mentorTopicsId.length === 0 ? (
                    <span></span>
                  ) : (
                    <img
                      src={edit_icon}
                      alt="edit icon"
                      width={20}
                      height={20}
                      onClick={openEditSchedule}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </FlexRow>
              </ScheduleDetailsContainer>
              <Col>
                {isScheduleAvailable ? (
                  schedule && Array.isArray(schedule) && schedule.length > 0 ? (
                    schedule.map((value, index) => {
                      return (
                        <div key={index}>
                          <DateIndicatorSubtitle>TOPIC</DateIndicatorSubtitle>
                          <div>
                            <strong>
                              {capitalizeFirstLetter(value.topic.title)}
                            </strong>
                          </div>
                          <FlexRow flexWrap="wrap">
                            {value.timeslots.map((item, timeslotIndex) => {
                              return (
                                <TopicsItemContainer key={timeslotIndex}>
                                  <ScheduleTime>
                                    {getHoursAndMinutes(item.start_time)} -{" "}
                                    {getHoursAndMinutes(item.end_time)}
                                  </ScheduleTime>
                                  <ScheduleTimeRange>
                                    <ScheduleTimeIcon
                                      src={clock_black_icon}
                                      alt="clock icon"
                                      width={15}
                                      height={15}
                                    />
                                    {calculateHourRange(
                                      item.start_time,
                                      item.end_time
                                    )}{" "}
                                    {calculateHourRange(
                                      item.start_time,
                                      item.end_time
                                    ) > 1
                                      ? "hours"
                                      : "hour"}
                                  </ScheduleTimeRange>
                                </TopicsItemContainer>
                              );
                            })}
                          </FlexRow>
                          <ScheduleItemLine />
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <FlexRow flexDirection="column">
                        <img
                          src={dayoff_empty_schedule_icon}
                          alt="day off icon"
                          width={300}
                          height={160}
                        />
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "18px",
                            marginTop: "1em",
                            color: "#4285F4",
                          }}
                        >
                          You didn&apos;t set any schedule for this date
                        </p>
                      </FlexRow>
                    </>
                  )
                ) : (
                  <>
                    <FlexRow flexDirection="column">
                      <img
                        src={dayoff_empty_schedule_icon}
                        alt="day off icon"
                        width={300}
                        height={160}
                      />
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: "18px",
                          marginTop: "1em",
                          color: "#4285F4",
                        }}
                      >
                        {schedule.length === 0 ? (
                          <>You didn&apos;t set any schedule for this date</>
                        ) : (
                          <>You have set this date as offline</>
                        )}
                      </p>
                    </FlexRow>
                  </>
                )}
                {}
              </Col>
            </Col>
          </Row>
          <ModalComponent
            show={isModalAssignedTopic}
            handleClose={() => setModalAssignedTopic(false)}
            maxWidth="800px"
            body={[
              [
                <AlertGreenComponent
                  key={0}
                  show={topicAlert}
                  text="Successfully added a new topic"
                />,
              ],
              [
                <Row key={1}>
                  <Col>
                    <FlexRow justifyContent="flex-start">
                      <p
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        Assign more topic
                      </p>
                    </FlexRow>
                  </Col>
                </Row>,
              ],
              [
                <Row key={2}>
                  <Col>
                    <FlexRow justifyContent="flex-start">
                      <CustomDropdown
                        placeholder="Add more topic..."
                        options={coachingTopics}
                        containerWidth="90%"
                        itemWidth="100%"
                        action={(value) => handleTopic(value)}
                        selectedKey="id"
                        imageFile={add_mentor_icon}
                      />
                    </FlexRow>
                  </Col>
                  <Col>
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: 700,
                        textAlign: "left",
                      }}
                    >
                      Current topics assigned to you
                    </p>
                    <FlexRow>
                      <TagsInput
                        tags={mentorTopics}
                        handleTags={(value) => handleTopicTags(value)}
                        // disabled={mentorTopics.length === 1}
                        showInputBorder={false}
                        showSubmit={false}
                        hidePlusBtn={true}
                      />
                    </FlexRow>
                  </Col>
                </Row>,
              ],
              [
                <div
                  key={3}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "1em 2em",
                  }}
                >
                  <GreenButton
                    // disabled={mentorTopics.length === 0}
                    action={saveTopics}
                    text="Save"
                    width="85px"
                    height="40px"
                  />
                </div>,
              ],
            ]}
          />
          <ModalComponent
            show={isModalSchedule}
            handleClose={closeModalSchedule}
            maxWidth="1000px"
            body={[
              [
                <Row
                  key={0}
                  style={{ marginLeft: "1.3em", marginRight: "1.3em" }}
                >
                  <Col>
                    <FlexRow justifyContent="flex-start">
                      {scheduleEditMode ? (
                        <p
                          style={{
                            fontWeight: 700,
                            fontSize: "18px",
                          }}
                        >
                          Edit schedule
                        </p>
                      ) : (
                        <p
                          style={{
                            fontWeight: 700,
                            fontSize: "18px",
                          }}
                        >
                          Add new schedule
                        </p>
                      )}
                    </FlexRow>
                  </Col>
                  <Col>&nbsp;</Col>
                  <Col>
                    <div
                      style={{
                        background: "#FFF2DE",
                        padding: "0.5em",
                        fontSize: "12px",
                      }}
                    >
                      <span>Timezone is displayed in {timezone}</span>
                    </div>
                  </Col>
                </Row>,
              ],
              [
                <div
                  key={1}
                  style={{
                    marginBottom: "1em",
                    marginLeft: "2em",
                    marginRight: "2em",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "2em",
                  }}
                >
                  <Col
                    style={{
                      fontSize: "14px",
                      textAlign: "left",
                    }}
                  >
                    <FlexRow
                      justifyContent="flex-start"
                      style={{ marginTop: "1em" }}
                    >
                      <InputLabel>Date/Time</InputLabel>
                    </FlexRow>
                    <ReactDatePicker
                      disabled
                      date={assignedScheduleDate}
                      handleDate={(date) => setAssignedScheduleDate(date)}
                    />
                    <FlexRow
                      justifyContent="flex-start"
                      style={{ marginTop: "1em" }}
                    >
                      <InputLabel>Topic</InputLabel>
                    </FlexRow>
                    <CustomDropdown
                      disabled={!isScheduleAvailable}
                      placeholder="Select topic"
                      options={
                        scheduleEditMode ? coachingTopicsEdit : coachingTopics
                      }
                      containerWidth="100%"
                      itemWidth="inherit"
                      action={(value) => setAssignedTopic(value)}
                      value={scheduleEditMode ? assignedTopic : null}
                      isSelected={assignedTopic ? true : false}
                      showSelectedIcon={false}
                      selectedBackgroundColor="rgba(0, 221, 221, 0.15)"
                    />
                  </Col>
                  <Col style={{ fontSize: "14px" }}>
                    <FlexRow
                      justifyContent="flex-start"
                      style={{ marginTop: "1em" }}
                    >
                      <InputLabel>Time Slot Availability</InputLabel>
                    </FlexRow>
                    <RadioButton
                      disabled={!isScheduleAvailable}
                      data={timeslots}
                      state={assignedTimeslot}
                      handleChange={handleTimeslots}
                    />
                    <br />
                    {assignedTimeslot === "Multiple time slots" ? (
                      <div style={{ marginBottom: "1em", marginTop: "1.4em" }}>
                        {multipleTimeslot}
                        {!scheduleEditMode && (
                          <MentorButton
                            text="Add time slot"
                            handleButton={handleTimeslotLimit}
                          />
                        )}
                      </div>
                    ) : (
                      <FlexRow
                        alignItems="baseline"
                        style={{ marginBottom: "1em", marginTop: "1.4em" }}
                      >
                        <CustomDropdown
                          disabled={
                            !assignedScheduleDate ||
                            !assignedTopic ||
                            !isScheduleAvailable
                          }
                          placeholder="Start time"
                          options={timeAvailable}
                          filteredOptions={filteredStartTime}
                          value={
                            scheduleEditMode
                              ? new Date(singleStartTime).getHours()
                              : null
                          }
                          action={(value) => handleSelectStartTime(value)}
                          itemWidth="inherit"
                          isSelected={singleStartTime ? true : false}
                          showSelectedIcon={false}
                          selectedBackgroundColor="rgba(0, 221, 221, 0.15)"
                        />
                        &nbsp; &nbsp;
                        <p style={{ fontWeight: 700 }}>TO</p>
                        &nbsp; &nbsp;
                        <CustomDropdown
                          disabled={
                            !assignedScheduleDate ||
                            !assignedTopic ||
                            !isScheduleAvailable
                          }
                          placeholder="End time"
                          options={newEndTime}
                          value={
                            scheduleEditMode
                              ? new Date(singleEndTime).getHours()
                              : null
                          }
                          action={(value) => handleSelectEndTime(value)}
                          itemWidth="inherit"
                          isSelected={singleEndTime ? true : false}
                          showSelectedIcon={false}
                          selectedBackgroundColor="rgba(0, 221, 221, 0.15)"
                        />
                      </FlexRow>
                    )}
                  </Col>
                  <Col style={{ fontSize: "14px" }}>
                    <br />
                    <br />
                    <FlexRow
                      alignItems="flex-start"
                      style={{ textAlign: "left" }}
                    >
                      <div style={{ marginTop: "0.2em" }}>
                        <input
                          type="checkbox"
                          checked={!isScheduleAvailable}
                          onChange={() =>
                            setScheduleAvailable(!isScheduleAvailable)
                          }
                        />
                      </div>
                      <div style={{ marginLeft: "1em" }}>
                        <p style={{ marginBottom: 0, fontWeight: 700 }}>
                          Mark as not available
                        </p>
                        <img
                          src={warning_black_icon}
                          alt="warning black icon"
                          width={15}
                          height={15}
                          style={{ objectFit: "scale-down" }}
                        />
                        <p style={{ fontSize: "12px" }}>
                          You will set this date as offline. Student will not be
                          able to make any booking.
                        </p>
                      </div>
                    </FlexRow>
                  </Col>
                </div>,
              ],
              !scheduleEditMode
                ? [
                    <Row
                      key={2}
                      style={{
                        marginLeft: "1.3em",
                        marginTop: "3em",
                        marginRight: "1.3em",
                      }}
                    >
                      <Col
                        style={{ cursor: "pointer" }}
                        onClick={() => closeModalSchedule()}
                      >
                        <FlexRow justifyContent="flex-start">
                          <p>Cancel</p>
                        </FlexRow>
                      </Col>
                      <Col>&nbsp;</Col>
                      <Col>
                        <FlexRow justifyContent="flex-end">
                          <GreenButton
                            disabled={!assignedTopic || !assignedScheduleDate}
                            action={() =>
                              scheduleEditMode
                                ? editSchedule()
                                : createSchedule()
                            }
                            text="Save"
                            width="85px"
                            height="40px"
                          />
                        </FlexRow>
                      </Col>
                    </Row>,
                  ]
                : [
                    <div
                      key={2}
                      style={{
                        marginLeft: "1.8em",
                        marginTop: "3em",
                        marginRight: "1.8em",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <GreenButton
                        action={() => handleDeleteSchedule()}
                        text="Delete"
                        width="85px"
                        height="40px"
                        bgcolor="#EE636E"
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "2em",
                        }}
                      >
                        <div
                          onClick={() => closeModalSchedule()}
                          style={{ cursor: "pointer" }}
                        >
                          Cancel
                        </div>
                        <GreenButton
                          disabled={!assignedTopic || !assignedScheduleDate}
                          action={() =>
                            scheduleEditMode ? editSchedule() : createSchedule()
                          }
                          text="Save"
                          width="85px"
                          height="40px"
                        />
                      </div>
                    </div>,
                  ],
            ]}
          />
        </ScheduleContainer>
      );
    } else {
      return (
        <div>
          <p>Data not found, please try another options...</p>
        </div>
      );
    }
  }

  useEffect(() => {
    if (user_id) {
      handleFetchTopics();
      handleFetchMentorDetail(user_id);
      handleFetchMentorTopics();
      handleFetchMentorSchedules(user_id);
      handleFetchMentorSchedulesAllTime(user_id);
      handleFetchIndustries();
    }
  }, [user_id, userLoginDetail]);

  // Update mentor schedules after add/edit/remove based on selected date
  useEffect(() => {
    updateMentorSchedules();
  }, [isModalSchedule, assignedScheduleDate]);

  // Get timeslots by topic - schedule
  useEffect(() => {
    async function getScheduleTimeslots() {
      if (!assignedTopic) return;
      try {
        const scheduleTimeslots = await getScheduleTimeslot(user_id, {
          topic_id: assignedTopic,
          selected_time: assignedScheduleDate,
        });

        if (!scheduleTimeslots) {
          setScheduleAvailable(true);
          setAssignedTimeslot("Single slot");
          setTimeslotLimit(3);
        } else {
          setScheduleEditData(scheduleTimeslots.data[0]);
          setAssignedTimeslot(
            scheduleEditMode
              ? scheduleTimeslots.data[0].is_multiple_slot
                ? "Multiple time slots"
                : "Single slot"
              : "Single slot"
          );
          setTimeslotLimit(
            scheduleEditMode ? scheduleTimeslots.data[0].timeslots.length : 3
          );
          if (scheduleTimeslots.data[0].is_multiple_slot) {
            setInsertedStartTime(
              scheduleTimeslots.data[0].timeslots
                .map((value) => value.start_time)
                .sort((a, b) => {
                  let start = new Date(a).getHours();
                  let end = new Date(b).getHours();
                  if (start < end) return -1;
                  if (start > end) return 1;
                  return 0;
                })
            );
            setInsertedEndTime(
              scheduleTimeslots.data[0].timeslots
                .map((value) => value.end_time)
                .sort((a, b) => {
                  let start = new Date(a).getHours();
                  let end = new Date(b).getHours();
                  if (start < end) return -1;
                  if (start > end) return 1;
                  return 0;
                })
            );
          } else {
            setSingleStartTime(
              scheduleTimeslots.data[0].timeslots[0].start_time
            );
            setSingleEndTime(scheduleTimeslots.data[0].timeslots[0].end_time);
          }
        }
      } catch (err) {
        console.log(err, "error line 4681 internalMentorDetail.js <<");
        setLoading(false);
      }
    }
    getScheduleTimeslots();
  }, [isModalSchedule, assignedTopic]);

  // Time validation on add/edit schedule - schedule
  useEffect(() => {
    let time = timeArray;
    let mappedStart = insertedStartTime.map((value) =>
      new Date(value).getHours()
    );
    let mappedEnd = insertedEndTime.map((value) => new Date(value).getHours());
    let concatedStartTime = startTimes
      .map((value) => new Date(value).getHours())
      .concat(mappedStart)
      .sort((a, b) => a - b);
    let concatedEndTime = endTimes
      .map((value) => new Date(value).getHours())
      .concat(mappedEnd)
      .sort((a, b) => a - b);
    let startTime = [];
    let endTime = [];

    // remove duplicates
    concatedStartTime.forEach((element) => {
      if (!startTime.includes(element)) {
        startTime.push(element);
      }
    });
    concatedEndTime.forEach((element) => {
      if (!endTime.includes(element)) {
        endTime.push(element);
      }
    });

    let timeFiltered = [];
    let index = 0;
    for (let i = 0; i < time.length; i++) {
      if (time[i] <= startTime[index] || time[i] > endTime[index]) {
        timeFiltered.push(time[i]);
      } else if (time[i] > startTime[index] && time[i] < endTime[index]) {
        // i + 0;
      } else if (time[i] !== startTime[i + 1]) {
        timeFiltered.push(time[i]);
      }

      if (time[i] === endTime[index]) {
        index++;
      }
    }

    // for filtering end time
    let filteredStart = timeFiltered
      .filter((value) => !startTime.includes(value))
      .map((value) => {
        return {
          id: value,
          name: timeslotsRender(assignedScheduleDate, value),
        };
      });

    // for changing custom dropdown filtered options
    let filteredOptions = timeAvailable.filter(
      (value) =>
        !filteredStart.map((filterValue) => filterValue.id).includes(value.id)
    );

    setFilteredStartime(filteredOptions);
  }, [
    singleStartTime,
    singleEndTime,
    insertedStartTime,
    insertedEndTime,
    startTimes,
    endTimes,
    assignedScheduleDate,
    scheduleEditData,
    assignedTopic,
  ]);

  useEffect(() => {
    if (!scheduleEditMode) return setTimeslotLimit(3);
  }, [scheduleEditMode]);

  // Render multiple timeslots - schedule
  useLayoutEffect(() => {
    let elements = [];
    for (let i = 0; i < timeslotLimit; i++) {
      let startTime = new Date(insertedStartTime[i]).getHours();
      let endTime = new Date(insertedEndTime[i]).getHours();
      let startPlaceholder;
      let endPlaceholder;
      let getPlaceholder = () => {
        let checkedStart = startTimes.map((value) =>
          new Date(value).getHours()
        );
        if (scheduleEditMode) {
          startPlaceholder = timeslotsRender(insertedStartTime[i], startTime);
          endPlaceholder = timeslotsRender(insertedEndTime[i], endTime);
        } else if (checkedStart.includes(startTime) || !startTime) {
          startPlaceholder = "Start time";
          endPlaceholder = "End time";
        } else {
          startPlaceholder = timeslotsRender(insertedStartTime[i], startTime);
          endPlaceholder = timeslotsRender(insertedEndTime[i], endTime);
        }
      };
      getPlaceholder();
      elements.push(
        <FlexRow key={i} alignItems="baseline" style={{ marginBottom: "1em" }}>
          <CustomDropdown
            disabled={
              insertedStartTime.length < i ||
              (!assignedTopic && !assignedScheduleDate) ||
              !isScheduleAvailable
            }
            placeholder={startPlaceholder}
            options={timeAvailable}
            filteredOptions={filteredStartTime}
            action={async (value) => handleSelectStartTime(value, i)}
            value={scheduleEditMode ? startTime : null}
            isSelected={scheduleEditMode ? true : false}
            showSelectedIcon={false}
            selectedBackgroundColor="rgba(0, 221, 221, 0.15)"
          />
          &nbsp; &nbsp;
          <p style={{ fontWeight: 700 }}>TO</p>
          &nbsp; &nbsp;
          <CustomDropdown
            disabled={
              !insertedStartTime[i] ||
              insertedEndTime.length < i ||
              (!assignedTopic && !assignedScheduleDate) ||
              !isScheduleAvailable
            }
            placeholder={endPlaceholder}
            options={newEndTime}
            action={async (value) => handleSelectEndTime(value, i)}
            value={scheduleEditMode ? endTime : null}
            isSelected={scheduleEditMode ? true : false}
            showSelectedIcon={false}
            selectedBackgroundColor="rgba(0, 221, 221, 0.15)"
          />
          <p
            style={{
              marginLeft: "1em",
              cursor: "pointer",
            }}
            onClick={() => removeTimeslot(startTime, endTime, i)}
          >
            Remove
          </p>
        </FlexRow>
      );
    }
    setMultipleTimeslot(elements);
  }, [timeslotLimit, insertedStartTime, insertedEndTime, assignedTimeslot]);

  // Assign mentor topic id - schedule
  useEffect(() => {
    const topicsTitle = topics.map((value) => value.title);
    const topicsFiltered = topicsTitle.filter((item) =>
      mentorTopics.includes(item)
    );
    const topicsIdFiltered = topics.filter((item) =>
      topicsFiltered.includes(item.title)
    );
    setMentorTopicsId(topicsIdFiltered.map((value) => value.id));
    const coachingTopicsFiltered = topics
      .map(({ id, title, description }) => {
        return {
          id,
          name: title,
          description,
        };
      })
      .filter((item) => !mentorTopics.includes(item.name));
    setCoachingTopics(coachingTopicsFiltered);
  }, [mentorTopics]);

  // Set coaching topics for add schedule - schedule
  useEffect(() => {
    const coachingTopicsFiltered = topics
      .map(({ id, title, description }) => {
        return {
          id,
          name: title,
          description,
        };
      })
      .filter((item) =>
        isModalSchedule
          ? mentorTopics.includes(item.name)
          : !mentorTopics.includes(item.name)
      );
    setCoachingTopics(coachingTopicsFiltered);
  }, [isModalSchedule]);

  // Timezone setter - schedule
  useEffect(() => {
    const timezone = () => {
      let utc = null;
      const timezone = String((new Date().getTimezoneOffset() / 60) * -1);
      if (timezone >= 0) {
        utc = "+";
      }

      return `GMT${utc}${timezone}`;
    };
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      const geoApiUrl = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`;

      axios
        .get(geoApiUrl)
        .then((res) => {
          setTimezone(
            `${res.data.city}${res.data.city ? "," : ""} ${
              res.data.countryName
            } ${timezone()}`
          );
        })
        .catch((err) => console.log(err));
    };
    const error = () => {
      console.log("Unable to find your location");
    };

    navigator.geolocation.getCurrentPosition(success, error);
  }, [assignedScheduleDate]);

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      <SpinnerComponent
        isShown={isLoading}
        styles={{
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "290px",
        }}
      />
      {!isLoading && mentor ? (
        <OuterContainer>
          <InnerContainer>
            <Row>
              <BackNavigation onClick={() => navigate(-1)}>
                <img
                  src={arrow_left_icon_dark}
                  alt="arrow_left_icon_dark"
                  height={15}
                  width={15}
                  style={{
                    objectFit: "scale-down",
                    objectPosition: "left bottom",
                  }}
                />
                {`    Back`}
              </BackNavigation>
            </Row>
            <PageTitle>Mentor details</PageTitle>
            <Row>
              <Col>
                <MentorProfileContainer>
                  <MentorProfilePicture
                    src={
                      mentor &&
                      mentor.user &&
                      mentor.user.user_details &&
                      mentor.user.user_details.image_url
                        ? mentor.user.user_details.image_url
                        : ccs_mentor_icon
                    }
                    alt="user profile picture"
                    onClick={() =>
                      document.querySelector(`.input-field-user-image`).click()
                    }
                  />
                  <input
                    type="file"
                    accept="image/*"
                    className={`input-field-user-image`}
                    hidden
                    onChange={({ target: { files } }) =>
                      handleOnClickImageProfile(files)
                    }
                  />
                  <MentorIdentification>
                    {mentor && mentor.user_details ? (
                      <MentorName>
                        {mentor.user_details.first_name}{" "}
                        {mentor.user_details.last_name}
                      </MentorName>
                    ) : null}
                    <MentorIdTitle>User ID</MentorIdTitle>
                    <MentorId>{mentor.user_ccs_id}</MentorId>
                  </MentorIdentification>
                </MentorProfileContainer>
              </Col>
              {userLoginDetail &&
              userLoginDetail.role &&
              userLoginDetail.role.id &&
              mentor &&
              mentor.role &&
              mentor.role.id &&
              ((+userLoginDetail.role.id === +mentor.role.id &&
                +userLoginDetail.id !== +mentor.id) ||
                (+userLoginDetail.role.id === 2 &&
                  +mentor.role.id === 1)) ? null : (
                <Col>
                  <InputContainer>
                    <InputLabel>Role/Position</InputLabel>
                    <CustomDropdown
                      value={assignedRole || ""}
                      placeholder="Choose one role/position"
                      options={roleOptions}
                      selectedKey="name"
                      action={(value) =>
                        handleChangeDropdown(
                          value,
                          "assignedRole",
                          setAssignedRole
                        )
                      }
                      inputWidth="250px"
                      itemWidth="250px"
                      disabled={
                        mentor &&
                        mentor.role &&
                        mentor.role.id &&
                        userLoginDetail &&
                        userLoginDetail.role &&
                        userLoginDetail.role.id &&
                        mentor.role.id === userLoginDetail.role.id
                      }
                    />
                  </InputContainer>
                </Col>
              )}
              {userLoginDetail &&
              userLoginDetail.role &&
              userLoginDetail.role.id &&
              mentor &&
              mentor.role &&
              mentor.role.id &&
              ((+userLoginDetail.role.id === +mentor.role.id &&
                +userLoginDetail.id !== +mentor.id) ||
                (+userLoginDetail.role.id === 2 &&
                  +mentor.role.id === 1)) ? null : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  {!isFormDisabled ? (
                    <GreenButton
                      action={() => cancelEdit()}
                      width="130px"
                      height="30px"
                      textSize={fontSize.title3}
                      text={infoName === "personal" ? "Cancel" : "Save"}
                      bgcolor="#00bb99"
                    />
                  ) : (
                    <GreenButton
                      action={() => setFormDisabled(false)}
                      width="130px"
                      height="30px"
                      textSize={fontSize.title3}
                      text="Edit Profile"
                      bgcolor="#00bb99"
                    />
                  )}
                </div>
              )}
              {/* <Col>
                {isFormDisabled && infoName === "personal" && (
                  <ButtonHandler
                    color="#00BB99"
                    onClick={() => setFormDisabled(false)}
                  >
                    Edit
                  </ButtonHandler>
                )}
              </Col> */}
            </Row>
            <MentorOptions>
              <MentorOptionSelect
                onClick={() => setInfoName("personal")}
                className={infoName}
                match="personal"
              >
                <MentorOptionText>Personal Information</MentorOptionText>
                <MentorOptionLine className={infoName} match="personal" />
              </MentorOptionSelect>
              {/* <MentorOptionSelect
                className={infoName}
                match="education"
                onClick={() =>
                  !isFormDisabled ? setInfoName("education") : null
                }
              >
                <MentorOptionText>Basic education information</MentorOptionText>
                <MentorOptionLine className={infoName} match="education" />
              </MentorOptionSelect> */}
              {/* <MentorOptionSelect
                className={infoName}
                match="supporting"
                onClick={() =>
                  !isFormDisabled ? setInfoName("supporting") : null
                }
              >
                <MentorOptionText>Supporting data</MentorOptionText>
                <MentorOptionLine className={infoName} match="supporting" />
              </MentorOptionSelect> */}
              <MentorOptionSelect
                onClick={() => setInfoName("schedule")}
                className={infoName}
                match="schedule"
              >
                <MentorOptionText>Schedule</MentorOptionText>
                <MentorOptionLine className={infoName} match="schedule" />
              </MentorOptionSelect>
            </MentorOptions>
            <MentorInvisibleLine />
            {renderOptions()}
            <ModalComponent
              show={isModal}
              body={[
                <p style={{ fontWeight: "700" }} key={0}>
                  {infoName === "personal"
                    ? "Are you sure want to cancel?"
                    : "Changes saved!"}
                </p>,
              ]}
              buttonPrimary={infoName === "personal" ? "Yes" : "Ok"}
              buttonSecondary={infoName === "personal" ? "No" : ""}
              onPrimaryClick={() => handleCancel()}
              onSecondaryClick={() => setModal(false)}
              handleClose={() => setModal(false)}
              maxWidth="450px"
            />
          </InnerContainer>
        </OuterContainer>
      ) : null}
    </div>
  );
};

export default InternalMentorDetail;

const OuterContainer = styled.div`
  padding-left: 290px;
  text-align: left;
`;

const InnerContainer = styled.div`
  padding: 130px 3vw 30px 3vw;
`;

const BackNavigation = styled.p`
  font-size: 16px;
  color: #000000;
  cursor: pointer;
`;

const PageTitle = styled.h1`
  font-size: 36px;
  color: #00bb99;
  margin-bottom: 1em;
`;

const MentorProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
`;

const MentorProfilePicture = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
`;

const MentorIdentification = styled.div`
  margin-left: 1em;
`;

const MentorName = styled.p`
  margin-bottom: 0.3em;
  font-weight: 700;
  font-size: 25px;
`;

const MentorIdTitle = styled.p`
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  color: #00bb99;
`;

const MentorId = styled.p`
  font-size: 14px;
  font-weight: 500;
`;

const MentorOptions = styled.div`
  display: flex;
  min-width: 900px;
  font-weight: 700;
  color: #c4c4c4;
`;

const MentorOptionSelect = styled.div`
  color: ${({ className, match }) =>
    className === match ? "#007C7C" : "#C4C4C4"};
  /* flex-grow: 1; // Important */
`;

const MentorOptionLine = styled.hr`
  opacity: ${({ className, match }) => (className === match ? 1 : 0)};
  padding: 2px;
  margin-top: 0.5em;
`;

const MentorInvisibleLine = styled.hr`
  opacity: 0.2;
  margin-top: -20px;
  padding: 2px;
  min-width: 900px;
`;

const MentorOptionText = styled.p`
  text-align: center;
  padding: 0 1em 0;
  margin-bottom: 0;
  cursor: pointer;
  &:hover {
    color: #007c7c;
  }

  &:hover + ${MentorOptionLine} {
    opacity: 1;
    color: #007c7c;
    transition: 0.2s;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : "row"};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "normal"};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "none")};
  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : "nowrap")};
`;

const FormMentor = styled.form``;

const InputContainer = styled.div`
  width: -webkit-fill-available; // Important
  margin-right: 2em;
  margin-top: 1em;
  flex-grow: 1; // Important
`;

const InputLabel = styled.p`
  font-weight: 700;
  margin-bottom: 0.3em;
`;

const InputMentor = styled.input`
  /* background: rgba(0, 187, 153, 0.1); */
  border: 2px solid #00bb99;
  border-radius: 10px;
  height: 50px;
  padding: 0.5em;
  width: 100%;
  &:disabled {
    opacity: 0.5;
    background: rgba(0, 187, 153, 0.1);
  }
`;

const TextAreaMentor = styled.textarea`
  /* background: rgba(0, 187, 153, 0.1); */
  border: 2px solid #00bb99;
  border-radius: 10px;
  min-height: 235px;
  width: 100%;
  padding: 0.5em;
  &:disabled {
    opacity: 0.5;
    background: rgba(0, 187, 153, 0.1);
  }
`;

const ButtonHandlerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 97.8%;
  margin-top: 20px;
`;

const ButtonHandler = styled.button`
  font-size: 14px;
  padding: 0.3em 2em;
  color: white;
  border: none;
  background: ${({ color }) => (color ? color : "none")};
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  border-radius: 40px;
  margin-left: 1em;
`;

const ErrorInput = styled.span`
  font-size: 12px;
  color: red;
`;

const ScheduleContainer = styled.div`
  margin-top: 61px;
`;

const ScheduleSubtitle = styled.p`
  color: #007c7c;
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 0;
  margin-right: 1em;
`;

const DateIndicator = styled.div`
  text-align: center;
  background: #f5f3ef;
  border-radius: 13.0846px;
  padding: 0.3em 0.9em;
`;

const DateIndicatorDay = styled.div`
  font-weight: 700;
  font-size: 15.7015px;
`;

const DateIndicatorNumber = styled.div`
  font-weight: 700;
  font-size: 23.5522px;
  color: #00bb99;
`;

const DateIndicatorSubtitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.1em;
  color: #4d4d4d;
`;

const DateIndicatorCoaching = styled.div`
  font-weight: 700;
  font-size: 18px;
`;

const ScheduleDetailsText = styled.div``;

const ScheduleDetailsContainer = styled.div`
  margin: 3em 0;
`;

const TopicsItemContainer = styled.div`
  border: 1px solid #00bb99;
  border-radius: 10px;
  margin: 1em 1em 1em 0;
  padding: 1em;
  max-width: 130px;
  font-size: 14px;
`;

const ScheduleTime = styled.div`
  font-weight: 700;
  color: #00bb99;
`;

const ScheduleTimeRange = styled.div`
  font-size: 11px;
  margin-top: 0.5em;
`;

const ScheduleTimeIcon = styled.img`
  max-width: 25px;
  margin: 0 0.5em 0 0;
`;

const ScheduleItemLine = styled.hr`
  border: 1px solid #c4c4c4;
`;

const CancelText = styled.div`
  font-size: ${fontSize.title4};
  text-align: center;
  color: #404040;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  &:hover {
    text-decoration: underline;
    font-weight: bold;
  }
`;

const InputAction = styled.div`
  font-size: ${fontSize.title4};
  color: ${({ disabled }) => (disabled ? "#aaaaaa" : "#00bb99")};
  font-weight: 500;
  color: ${({ disabled }) => (disabled ? "" : "pointer")};
  user-select: none;
  &:hover {
    text-decoration: ${({ disabled }) => (disabled ? "" : "underline")};
    font-weight: ${({ disabled }) => (disabled ? "" : "bold")};
  }
`;
