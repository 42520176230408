// pages
import Home from "./pages/home";
import Profile from "./pages/profile";
import MyBooking from "./pages/myBooking";
import SessionHistory from "./pages/sessionHistory";
import CoachingBook from "./pages/coachingBook";
import InternalBookings from "./pages/internalBookings";
import InternalBookingDetail from "./pages/internalBookingDetail";
import InternalMentorDetail from "./pages/internalMentorDetail";
import SessionDetail from "./pages/sessionDetail";
import InternalCourses from "./pages/internalCourses";
import CoursesForm from "./pages/coursesForm";
import InternalCourseDetail from "./pages/InternalCourseDetail";
import Courses from "./pages/courses";
import CourseDetail from "./pages/courseDetail";
import InternalUserList from "./pages/internalUserList";
import InternalUserDetail from "./pages/internalUserDetail";
import InternalTopics from "./pages/internalTopics";
import InternalTopicDetail from "./pages/internalTopicDetail";
import FormInternalTopic from "./pages/formInternalTopic";
import Webinars from "./pages/webinars";
import WebinarDetail from "./pages/webinarDetail";
import FormRegisterWebinar from "./pages/formRegisterWebinar";
import MobileNotificationPage from "./pages/mobileNotificationPage";
import CoachingBookRequirements from "./pages/coachingBookRequirements";
import InternalWebinars from "./pages/InternalWebinars";
import FormInternalWebinar from "./pages/formInternalWebinar";
import InternalWebinarDetail from "./pages/InternalWebinarDetail";
import InternalDashboard from "./pages/internalDashboard";
// import template from "./pages/template";
// import AddNewMentor from "./pages/addNewMentor";
// import InternalMentors from "./pages/internalMentors";

const routes = [
  { path: "/", name: "Home", element: Home, exact: true, requiredRole: [3] },
  {
    path: "/profile",
    name: "Profile page",
    element: Profile,
    requiredRole: [3],
  },
  {
    path: "/bookings",
    name: "My Booking page",
    element: MyBooking,
    requiredRole: [3],
  },
  {
    path: "/session",
    name: "Session history page",
    element: SessionHistory,
    requiredRole: [3],
  },
  {
    path: "/session/:session_id",
    name: "Session detail page",
    element: SessionDetail,
    requiredRole: [3],
  },
  {
    path: "/bookings/:session_id",
    name: "Booking detail page",
    element: SessionDetail,
    requiredRole: [3],
  },
  {
    path: "/coaching/book",
    name: "Coaching Book page",
    element: CoachingBook,
    requiredRole: [3],
  },
  {
    path: "/coaching/book/:booking_id",
    name: "Coaching Book update page",
    element: CoachingBook,
    requiredRole: [3],
  },
  {
    path: "/webinars",
    name: "Webinar page",
    element: Webinars,
    requiredRole: [3],
  },
  {
    path: "/webinars/:webinar_id",
    name: "Webinar page",
    element: WebinarDetail,
    requiredRole: [3],
  },
  {
    path: "/courses",
    name: "Course list page",
    element: Courses,
    requiredRole: [3],
  },
  {
    path: "/courses/:course_id",
    name: "Course Detail page",
    element: CourseDetail,
    requiredRole: [3],
  },
  {
    path: "/notifications",
    name: "Notification Page",
    element: MobileNotificationPage,
  },
  {
    path: "/webinars/:webinar_id/register",
    name: "Edit internal coaching topic page",
    element: FormRegisterWebinar,
    requiredRole: [3],
  },
  {
    path: "/coaching/book/requirements",
    name: "Coaching Book Requirements page",
    element: CoachingBookRequirements,
    requiredRole: [3],
  },
  {
    path: "/internal/dashboard",
    name: "Admin dashboard page",
    element: InternalDashboard,
    requiredRole: [1, 2, 4],
  },
  {
    path: "/internal/bookings",
    name: "Internal Booking page",
    element: InternalBookings,
    requiredRole: [1, 2, 4],
  },
  // {
  //   path: "/internal/mentors",
  //   name: "Internal Mentor page",
  //   element: InternalMentors,
  // },
  {
    path: "/internal/topics",
    name: "Internal Topic page",
    element: InternalTopics,
    requiredRole: [1, 2],
  },
  {
    path: "/internal/bookings/:booking_id",
    name: "Internal Booking Detail page",
    element: InternalBookingDetail,
    requiredRole: [1, 2, 4],
  },
  {
    path: "/internal/mentors/:user_id",
    name: "Internal Mentor Detail page",
    element: InternalMentorDetail,
    requiredRole: [1, 2, 4],
  },
  {
    path: "/internal/topics/:topic_id",
    name: "Internal Topic Detail page",
    element: InternalTopicDetail,
    requiredRole: [1, 2],
  },
  // {
  //   path: "/internal/mentors/add",
  //   name: "Add new Internal Mentor page",
  //   element: AddNewMentor,
  // },
  {
    path: "/internal/courses",
    name: "Internal Course page",
    element: InternalCourses,
    requiredRole: [1, 2, 4],
  },
  {
    path: "/internal/courses/add",
    name: "Internal add new Course page",
    element: CoursesForm,
    requiredRole: [1, 2, 4],
  },
  {
    path: "/internal/courses/edit/:course_id",
    name: "Internal Edit Course page",
    element: CoursesForm,
    requiredRole: [1, 2, 4],
  },
  {
    path: "/internal/courses/:course_id",
    name: "Internal Course Detail page",
    element: InternalCourseDetail,
    requiredRole: [1, 2, 4],
  },
  {
    path: "/internal/users",
    name: "User list page",
    element: InternalUserList,
    requiredRole: [1, 2],
  },
  {
    path: "/internal/users/:user_id",
    name: "User detail page",
    element: InternalUserDetail,
    requiredRole: [1, 2],
  },
  {
    path: "/internal/topics/add",
    name: "Add new internal coaching topic page",
    element: FormInternalTopic,
    requiredRole: [1, 2],
  },
  {
    path: "/internal/topics/edit/:topic_id",
    name: "Edit internal coaching topic page",
    element: FormInternalTopic,
    requiredRole: [1, 2],
  },
  {
    path: "/internal/webinars",
    name: "Internal webinar list page",
    element: InternalWebinars,
    requiredRole: [1, 2],
  },
  {
    path: "/internal/webinars/:webinar_id",
    name: "Internal webinar detail page",
    element: InternalWebinarDetail,
    requiredRole: [1, 2],
  },
  {
    path: "/internal/webinars/add",
    name: "Internal webinar create new page",
    element: FormInternalWebinar,
    requiredRole: [1, 2],
  },
  {
    path: "/internal/webinars/edit/:webinar_id",
    name: "Internal webinar edit page",
    element: FormInternalWebinar,
    requiredRole: [1, 2],
  },
  // {
  //   path: "/template",
  //   name: "template",
  //   element: template,
  // }, // uncomment jika dibutuhkan
  //   { path: "/coupons/edit/:id", name: "Edit Coupon", element: EditCoupon },
  //   {
  //     path: "/coupons/details/:id",
  //     name: "Coupon Details",
  //     element: CouponDetails,
  //   },
  //   { path: "/my-page", name: "My Page", element: MyPage, exact: true },
  //   {
  //     path: "/my-page/edit/general-settings",
  //     name: "Edit General Setting",
  //     element: EditMyPage,
  //   },
  //   { path: "/orders", name: "Orders", element: OrderList, exact: true },
  //   { path: "/orders/list", name: "Order List", element: OrderList },
  //   { path: "/orders/details/:id", name: "Order Details", element: OrderDetails },
  //   { path: "/products", name: "Product", element: ProductList, exact: true },
  //   { path: "/products/list", name: "Product List", element: ProductList },
  //   { path: "/products/add", name: "Add Product", element: AddProduct },
  //   { path: "/products/edit/:id", name: "Edit Product", element: EditProduct },
  //   {
  //     path: "/products/details/:id",
  //     name: "Product Details",
  //     element: ProductDetails,
  //   },
  // { path: '/products/variant/list', name: 'Variant List', element: VariantList },
  //   {
  //     path: "/products/category/list",
  //     name: "Category List",
  //     element: CategoryList,
  //   },
  //   {
  //     path: "/products/category/add",
  //     name: "Add Category",
  //     element: AddCategory,
  //   },
  //   {
  //     path: "/products/category/edit/:id",
  //     name: "Edit Category",
  //     element: EditCategory,
  //   },
  //   {
  //     path: "/products/category/details/:id",
  //     name: "Category Details",
  //     element: CategoryDetails,
  //   },
  //   {
  //     path: "/products/sub-category/list",
  //     name: "Sub Category List",
  //     element: SubCategoryList,
  //   },
  //   {
  //     path: "/products/sub-category/add",
  //     name: "Add Sub Category",
  //     element: AddSubCategory,
  //   },
  //   {
  //     path: "/products/sub-category/edit/:id",
  //     name: "Edit Sub Category",
  //     element: EditSubCategory,
  //   },
  //   {
  //     path: "/products/sub-category/details/:id",
  //     name: "Sub Category Details",
  //     element: SubCategoryDetails,
  //   },
  //   { path: "/products/brand/list", name: "Brand List", element: BrandList },
  //   { path: "/products/brand/add", name: "Add Brand", element: AddBrand },
  //   { path: "/products/brand/edit/:id", name: "Edit Brand", element: EditBrand },
  //   {
  //     path: "/products/brand/details/:id",
  //     name: "Brand Details",
  //     element: BrandDetails,
  //   },
  //   { path: "/customers", name: "Customers", element: CustomerList, exact: true },
  //   { path: "/customers/list", name: "Customer List", element: CustomerList },
  //   {
  //     path: "/customers/details/:id",
  //     name: "Customer Details",
  //     element: CustomerDetails,
  //   },
  //   { path: "/chat", name: "Chat", element: Chats, exact: true },
  //   { path: "/reports", name: "Reports", element: Reports, exact: true },
];

export default routes;
