import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";

import styled from "styled-components";

// Sweetalert
import Swal from "sweetalert2";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// Components
import SpinnerComponent from "../SpinnerComponent";
import AlertComponent from "../AlertComponent";
import GreenButton from "../button/GreenButton";
import ReactDatePicker from "../input/DatePicker";
import RadioButton from "../input/RadioButton";
import CustomDropdown from "../input/CustomDropdown";
import Tooltip from "../tooltip/Tooltip";
import LoadingModalMobile from "../LoadingModalMobile";
import InputText from "../input/InputText";
import InputDropdown from "../input/InputDropdown";
import ModalComponent from "../ModalComponent";

// Images & Icons
import info_icon from "../../assets/icons/info_icon.png";
import success_icon from "../../assets/icons/success_icon.svg";
import danger_icon from "../../assets/icons/danger_icon.svg";
import ccs_mentor_icon from "../../assets/icons/ccs_mentor_icon.png";
import email_password_update_success from "../../assets/icons/email_password_update_success.png";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
// import router from "next/router";

// Lib
import fontSize from "../../lib/styling/font_size";

// Helpers
import { validatePhoneNumber } from "../../helpers/validate-phone-number";
import { createCountriesOptions } from "../../helpers/get-country-options";
import { createStateOptions } from "../../helpers/get-state-options";
import { createCityOptions } from "../../helpers/get-city-options";

// Styles
import "../../styles/userForm.css";

// Core UI Icons
import * as icon from "@coreui/icons";
import CIcon from "@coreui/icons-react";

// Apis
import {
  logoutUser,
  updateEmailFromProfile,
  updatePasswordFromProfile,
  updateUserDetail,
  updateUserImageUrl,
} from "../../apis/authentications";
import {
  getAllCountries,
  getCityByCountryNameAndStateName,
  getStatesByCountryName,
} from "../../apis/general";

// store
import { SET_USER_DETAIL } from "../../store/actions";

// react-responsive
import { useMediaQuery } from "react-responsive";

function UserForm({
  user,
  isNewUser,
  setIsNewUser,
  mutateUser,
  industries,
  occupations,
  degrees,
  isLoading,
  setLoading,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: {
      errors,
      // isDirty,
      // isValid,
      isSubmitting,
    },
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      first_name: user?.user_details?.first_name,
      last_name: user?.user_details?.last_name,
      place_of_birth: user?.user_details?.place_of_birth,
      date_of_birth: user?.user_details?.date_of_birth,
      gender: user?.user_details?.gender,
      company_name: user?.user_details?.company_name,
      industry_id: user?.user_details?.industry_id,
      address: user?.user_details?.address,
      occupation_id: user?.user_details?.occupation_id,
      postal_code: user?.user_details?.postal_code,
      degree_id: user?.user_details?.degree_id,
      city: user?.user_details?.city,
      province: user?.user_details?.province,
      university: user?.user_details?.university,
      // country_id: user?.user_details?.country_id,
      // phone_number: user?.user_details?.phone_number,
    },
  });

  const handleError = (errors) => {};

  const formOptions = {
    first_name: {
      required: "First name is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    last_name: {
      required: "Last name is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    place_of_birth: {
      required: "Place of birth is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    date_of_birth: {
      required: "Date of birth is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    gender: {
      required: "Gender is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    company_name: {
      required: "Company name is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    // phone_number: {
    //   required: "Phone number is required",
    //   pattern: {
    //     value: /^\d+$/,
    //     message: "Invalid phone number",
    //   },
    //   maxLength: {
    //     value: 16,
    //     message: "Can't be longer than 16 characters",
    //   },
    // },
    industry_id: {
      required: "Industry is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    address: {
      required: "Address is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    occupation_id: {
      required: "Role/position is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    // country_id: {
    //   required: "Country is required",
    //   maxLength: {
    //     value: 255,
    //     message: "Can't be longer than 255 characters",
    //   },
    // },
    postal_code: {
      required: "Postal Code is required",
      pattern: {
        value: /^\d+$/,
        message: "Invalid postal code",
      },
      maxLength: {
        value: 5,
        message: "Can't be longer than 5 characters",
      },
    },
    degree_id: {
      required: "Highest degree is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    city: {
      required: "City is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    province: {
      required: "State/Province is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    university: {
      required: "University is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
  };

  const [isInitialValueEmpty] = useState(
    user?.user_details === null ||
      user?.user_details === undefined ||
      Object.values(user?.user_details).some(
        (value) => value === null || value === undefined
      )
  );
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [newEmailAddress, setNewEmailAddress] = useState("");
  const [isChangingEmail, setChangingEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [repeatPasswordType, setRepeatPasswordType] = useState("password");
  const [isChangingPassword, setChangingPassword] = useState(false);
  const [error, setErrorMsg] = useState("");
  const [success, setSuccess] = useState("");
  const [isModalCancel, setIsModalCancel] = useState(false);
  const [userDetail, setUserDetail] = useState(false);

  // phone number state
  const [isOpenPhoneDropdown, setIsOpenPhoneDropdown] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({
    number: "",
    icon: null,
    phone_code: "",
    country_id: "",
  });

  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const [provinceValue, setProvinceValue] = useState(null);
  const [cityValue, setCityValue] = useState(null);

  async function handleUpdateProfile(data) {
    setLoading(true);
    try {
      if (
        phoneNumber &&
        phoneNumber.country_id &&
        phoneNumber.number &&
        phoneNumber.phone_code
      ) {
        let isValidPhoneNumber = validatePhoneNumber(phoneNumber.number);

        if (isValidPhoneNumber) {
          if (provinceValue && provinceValue.value) {
            if (cityValue && cityValue.value) {
              const finalData = {
                ...data,
                country_id: phoneNumber.country_id,
                phone_number: phoneNumber.number,
                province: provinceValue.value,
                city: cityValue.value,
              };

              const res = await updateUserDetail(finalData, user.id);
              if (res && res.data) {
                Swal.fire({
                  customClass: {
                    popup: "mentor-popup",
                  },
                  imageUrl:
                    "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
                  position: "top-end",
                  text: "Profile updated successfully",
                  width: "20em",
                  heightAuto: "0.5em",
                  showConfirmButton: false,
                  timer: 1500,
                });
                setSuccess("Profile updated successfully");
                setIsEditingProfile(false);
                setIsNewUser(false);
              }
            } else {
              setError("city", { message: "City is required" });
            }
          } else {
            setError("province", { message: "State / Province is required" });
          }
        } else {
          setError("phone_number", { message: "Invalid phone number" });
        }
      } else {
        if (!phoneNumber.country_id) {
          setError("country_id", { message: "Country is required" });
        }
        if (!phoneNumber.number) {
          setError("phone_number", { message: "Phone number is required" });
        }
      }
    } catch (error) {
      console.log(error, "error line 347 UserForm.js <<");
      setErrorMsg(error.response.data.message);
    }
    setLoading(false);
  }

  function showAlert(text, type) {
    if (type === "success") {
      setErrorMsg("");
      setSuccess(text);
    } else {
      setSuccess("");
      setErrorMsg(text);
    }
  }

  function checkRepeatPassword() {
    if (password && repeatPassword && password === repeatPassword) {
      return true;
    } else {
      return false;
    }
  }

  async function logout() {
    try {
      setLoading(true);
      const result = await logoutUser();
      if (result && result.data && result.data.success) {
        setLoading(false);
        dispatch(SET_USER_DETAIL(null));
        navigate("/login");
      }
    } catch (error) {
      console.log(error, "error line 381 UserForm.js <<");
      setLoading(false);
      showAlert(error.response.data.message, "error");
    }
  }

  function handleUpdateEmail() {
    Swal.fire({
      title: "Please wait",
      text: "We are processing your request",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        updateEmailFromProfile({
          prev_email: user?.email,
          new_email: newEmailAddress,
        })
          .then(() => {
            Swal.fire({
              text: "You have successfully changed your email",
              imageUrl: email_password_update_success,
              imageHeight: 185,
              imageWidth: 255,
              showCloseButton: true,
            }).then(() => {
              logout();
            });
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: err.response.data.message,
              icon: "error",
              showCloseButton: true,
            });
          });
      },
    });
  }

  function handleUpdatePassword() {
    if (checkRepeatPassword()) {
      Swal.fire({
        title: "Please wait",
        text: "We are processing your request",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          updatePasswordFromProfile({ password })
            .then(() => {
              Swal.fire({
                text: "You have successfully changed your password",
                imageUrl: email_password_update_success,
                imageHeight: 185,
                imageWidth: 255,
                showCloseButton: true,
              }).then(() => {
                setPassword("");
                setRepeatPassword("");
                setChangingPassword(false);
              });
            })
            .catch((err) => {
              Swal.fire({
                title: "Error",
                text: err.response.data.message,
                icon: "error",
              });
            });
        },
      });
    } else {
      showAlert("Password did not match", "error");
    }
  }

  const handleOnChangePhoneNumber = (
    value,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    let parts = value.split(" ");
    let withoutCountryCode;

    if (phoneNumberState && phoneNumberState.phone_code) {
      withoutCountryCode = parts[1];
    } else {
      withoutCountryCode = parts.join(" ");
    }

    clearErrors("phone_number");

    setterPhoneNumber({
      ...phoneNumberState,
      number: withoutCountryCode,
    });
  };

  const handleChangePhoneNumberDropdown = (
    val,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    const {
      value: { code, phone_code, id },
      label = {},
    } = val || {};

    let countryIcon = "";
    if (code) {
      countryIcon = icon[`cif${code}`];
    }

    const countryName = label;
    setProvinceValue(null);
    setCityValue(null);
    setCityOptions([]);
    handleGetStateOptions(countryName);

    setterPhoneNumber({
      ...phoneNumberState,
      icon: countryIcon,
      phone_code: phone_code,
      country_id: id,
      value: { label, value: val.value },
    });
    clearErrors("country_id");
    setIsOpenPhoneDropdown(false);
  };

  const fetchAllCountries = async () => {
    const response = await getAllCountries();
    if (response && response.data) {
      const { data } = response;
      if (data && Array.isArray(data) && data.length > 0) {
        setCountryOptions(createCountriesOptions(data));
      }
    }
  };

  const handleGetStateOptions = async (countryName) => {
    const result = await getStatesByCountryName({ country: countryName });
    if (result && result.data) {
      const { data: { states } = {} } = result.data || {};
      if (states && Array.isArray(states) && states.length > 0) {
        setStateOptions(createStateOptions(states));
      }
    }
  };

  const handleGetCityOptions = async (countryName, stateName) => {
    const result = await getCityByCountryNameAndStateName({
      country: countryName,
      state: stateName,
    });
    if (result && result.data) {
      const { data } = result.data || {};
      if (data && Array.isArray(data) && data.length > 0) {
        setCityOptions(createCityOptions(data));
      }
    }
  };

  const handleOnClickImageProfile = async (files) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    const result = await updateUserImageUrl(formData, user.id);
    if (result && result.data) {
      let userDetails = {
        ...user.user_details,
        image_url: result.data.image_url,
      };
      dispatch(SET_USER_DETAIL({ ...user, user_details: userDetails }));
      Swal.fire({
        customClass: {
          popup: "mentor-popup",
        },
        icon: "success",
        position: "top-end",
        text: "User profile picture has been updated",
        width: "20em",
        heightAuto: "0.5em",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  // Handle dropdown change
  function handleChangeDropdown(value, key, setter) {
    if (key && key === "province") {
      setCityValue(null);
      const countryName = phoneNumber.value.label;
      const provinceName = value.label;
      handleGetCityOptions(countryName, provinceName);
    }
    setter(value);
  }

  const handleFetchUserDetail = async (user) => {
    setLoading(true);
    const { user_details } = user || {};

    let countryIcon;
    let number;
    let phone_code;
    let country_id;
    let label;
    let code;
    let id;

    if (user_details && user_details.country && user_details.country.id) {
      id = user_details.country.id;
    }
    if (user_details && user_details.country && user_details.country.code) {
      code = user_details.country.code;
      countryIcon = icon[`cif${user_details.country.code}`];
    }
    if (user_details && user_details.phone_number) {
      number = user_details.phone_number;
    }
    if (
      user_details &&
      user_details.country &&
      user_details.country.phone_code
    ) {
      phone_code = user_details.country.phone_code;
    }
    if (user_details && user_details.country && user_details.country.id) {
      country_id = user_details.country.id;
    }
    if (user_details && user_details.country && user_details.country.name) {
      label = user_details.country.name;
      await handleGetStateOptions(user_details.country.name);
      if (user_details && user_details.province) {
        setProvinceValue({
          value: user_details.province,
          label: user_details.province,
        });
        await handleGetCityOptions(
          user_details.country.name,
          user_details.province
        );
        if (user_details && user_details.city) {
          setCityValue({
            value: user_details.city,
            label: user_details.city,
          });
        }
      }
    }

    for (const key in user_details) {
      setValue(key, user_details[key]);
    }

    setPhoneNumber({
      ...phoneNumber,
      icon: countryIcon,
      phone_code,
      number,
      country_id,
      value: { label, value: code, phone_code, id },
    });
    fetchAllCountries();
    setUserDetail(user);
    setLoading(false);
  };

  useEffect(() => {
    if (isNewUser) {
      setIsEditingProfile(true);
    } else {
      setIsEditingProfile(false);
    }
  }, [isNewUser]);

  useEffect(() => {
    if (user && user.user_details) {
      handleFetchUserDetail(user);
    }
  }, [user]);

  return (
    <div>
      <SpinnerComponent
        isShown={isLoading && !smallScreen && !mediumScreen}
        styles={{
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "290px",
        }}
      />
      <AlertComponent
        isShown={error ? true : false}
        variant="danger"
        text={error}
      />
      <AlertComponent
        isShown={success ? true : false}
        variant="success"
        text={success}
      />
      {isLoading && (smallScreen || mediumScreen) ? (
        <LoadingModalMobile />
      ) : (
        <>
          {!isLoading ? (
            <>
              <div className="userFormHeaderContainer">
                <div
                  style={{
                    fontSize: fontSize.title1,
                    color:
                      userDetail &&
                      userDetail.role &&
                      userDetail.role.id &&
                      +userDetail.role.id === 3
                        ? "#2c65f7"
                        : "#00BB99",
                    fontWeight: "bold",
                  }}
                >
                  Hello, welcome back!
                </div>
                {!isEditingProfile && (
                  <div
                    style={
                      smallScreen
                        ? {
                            display: "flex",
                            marginTop: "1em",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }
                        : {}
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "0.5em",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "0.5em",
                      }}
                    >
                      <img
                        src={
                          userDetail && userDetail.is_validated
                            ? success_icon
                            : danger_icon
                        }
                        className="alertComponentImage"
                        alt="check_icon"
                        height={20}
                        width={20}
                      />
                      <div
                        style={{
                          fontSize: fontSize.title3,
                          fontWeight: "500",
                        }}
                      >
                        {userDetail && userDetail.is_validated
                          ? "VERIFIED"
                          : "NOT VERIFIED"}
                      </div>
                    </div>
                    <GreenButton
                      action={() => setIsEditingProfile(true)}
                      width="130px"
                      height="30px"
                      textSize={fontSize.title3}
                      text="Edit Profile"
                      bgcolor="#2c65f7"
                    />
                  </div>
                )}
              </div>
              {isInitialValueEmpty && (
                <div
                  className="useFormHeaderInitialValueEmptyContainer"
                  style={{
                    backgroundColor:
                      userDetail &&
                      userDetail.role &&
                      userDetail.role.id &&
                      +userDetail.role.id === 3
                        ? "#e7eeff"
                        : "#d7ffff",
                  }}
                >
                  <img src={info_icon} alt="info_icon" />
                  <div
                    style={{
                      fontSize: fontSize.title3,
                      fontWeight: "600",
                      textAlign: "left",
                    }}
                  >
                    Thank you for joining Combined Clinics Sustainability!
                    Before getting started, please complete your profile below.
                  </div>
                </div>
              )}
              <Row>
                <Col>
                  <MentorProfileContainer>
                    <MentorProfilePicture
                      src={
                        userDetail &&
                        userDetail.user_details &&
                        userDetail.user_details.image_url
                          ? userDetail.user_details.image_url
                          : ccs_mentor_icon
                      }
                      alt="user profile picture"
                      onClick={() =>
                        isEditingProfile
                          ? document
                              .querySelector(`.input-field-user-image`)
                              .click()
                          : null
                      }
                      isEditingProfile={isEditingProfile}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      className={`input-field-user-image`}
                      hidden
                      onChange={({ target: { files } }) =>
                        handleOnClickImageProfile(files)
                      }
                    />
                    <MentorIdentification>
                      {userDetail && userDetail.user_details ? (
                        <MentorName>
                          {userDetail.user_details.first_name}{" "}
                          {userDetail.user_details.last_name}
                        </MentorName>
                      ) : null}
                      <MentorIdTitle>User ID</MentorIdTitle>
                      <MentorId>{userDetail.user_ccs_id}</MentorId>
                    </MentorIdentification>
                  </MentorProfileContainer>
                </Col>
              </Row>
              <form onSubmit={handleSubmit(handleUpdateProfile, handleError)}>
                <Row className="userFormRowMainContainer">
                  <Col md={3}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div
                          className="userFormInputLabel"
                          style={{
                            fontSize: fontSize.title2,
                          }}
                        >
                          First Name
                        </div>
                        <input
                          id="first_name"
                          name="first_name"
                          autoComplete="off"
                          type="text"
                          placeholder="e.g: John"
                          {...register("first_name", formOptions.first_name)}
                          disabled={!isEditingProfile}
                          style={{
                            background:
                              Boolean(getValues("first_name")) ||
                              !isEditingProfile
                                ? `${
                                    userDetail &&
                                    userDetail.role &&
                                    userDetail.role.id &&
                                    +userDetail.role.id === 3
                                      ? "#e7eeff"
                                      : "#D7FFFF"
                                  }`
                                : "#FFFFFF",
                            border:
                              errors && errors.first_name
                                ? "2px solid #EB4335"
                                : `2px solid ${
                                    userDetail &&
                                    userDetail.role &&
                                    userDetail.role.id &&
                                    +userDetail.role.id === 3
                                      ? "#2c65f7"
                                      : "#00BB99"
                                  }`,
                          }}
                          className="userFormInputStyle"
                        />
                        <small
                          className="text-danger"
                          style={{
                            display: "flex",
                            textAlign: "start",
                            userSelect: "none",
                          }}
                        >
                          {errors?.first_name && errors.first_name.message}
                        </small>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div
                          className="userFormInputLabel"
                          style={{
                            fontSize: fontSize.title2,
                          }}
                        >
                          Last Name
                        </div>
                        <input
                          id="last_name"
                          name="last_name"
                          autoComplete="off"
                          type="text"
                          placeholder="e.g: Doe"
                          {...register("last_name", formOptions.last_name)}
                          disabled={!isEditingProfile}
                          style={{
                            background:
                              Boolean(getValues("last_name")) ||
                              !isEditingProfile
                                ? `${
                                    userDetail &&
                                    userDetail.role &&
                                    userDetail.role.id &&
                                    +userDetail.role.id === 3
                                      ? "#e7eeff"
                                      : "#D7FFFF"
                                  }`
                                : "#FFFFFF",
                            border:
                              errors && errors.last_name
                                ? "2px solid #EB4335"
                                : `2px solid ${
                                    userDetail &&
                                    userDetail.role &&
                                    userDetail.role.id &&
                                    +userDetail.role.id === 3
                                      ? "#2c65f7"
                                      : "#00BB99"
                                  }`,
                          }}
                          className="userFormInputStyle"
                        />
                        <small
                          className="text-danger"
                          style={{
                            userSelect: "none",
                            display: "flex",
                            textAlign: "start",
                          }}
                        >
                          {errors?.last_name && errors.last_name.message}
                        </small>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div
                          className="userFormInputLabel"
                          style={{
                            fontSize: fontSize.title2,
                          }}
                        >
                          Place of Birth
                        </div>
                        <input
                          id="place_of_birth"
                          name="place_of_birth"
                          autoComplete="off"
                          type="text"
                          placeholder="e.g: Jakarta"
                          {...register(
                            "place_of_birth",
                            formOptions.place_of_birth
                          )}
                          disabled={!isEditingProfile}
                          style={{
                            background:
                              Boolean(getValues("place_of_birth")) ||
                              !isEditingProfile
                                ? `${
                                    userDetail &&
                                    userDetail.role &&
                                    userDetail.role.id &&
                                    +userDetail.role.id === 3
                                      ? "#e7eeff"
                                      : "#D7FFFF"
                                  }`
                                : "#FFFFFF",
                            border:
                              errors && errors.place_of_birth
                                ? "2px solid #EB4335"
                                : `2px solid ${
                                    userDetail &&
                                    userDetail.role &&
                                    userDetail.role.id &&
                                    +userDetail.role.id === 3
                                      ? "#2c65f7"
                                      : "#00BB99"
                                  }`,
                          }}
                          className="userFormInputStyle"
                        />
                        <small
                          className="text-danger"
                          style={{
                            userSelect: "none",
                            display: "flex",
                            textAlign: "start",
                          }}
                        >
                          {errors?.place_of_birth &&
                            errors.place_of_birth.message}
                        </small>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div
                          className="userFormInputLabel"
                          style={{
                            fontSize: fontSize.title2,
                          }}
                        >
                          Date of Birth
                        </div>
                        <Controller
                          control={control}
                          name="date_of_birth"
                          rules={formOptions.date_of_birth}
                          render={({ field: { onChange, value } }) => (
                            <ReactDatePicker
                              disabled={!isEditingProfile}
                              date={new Date(value)}
                              handleDate={onChange}
                              format="dd/MM/yyyy"
                              fromprofile={true}
                              error={errors?.date_of_birth}
                              user_detail={userDetail}
                            />
                          )}
                        />
                        <small
                          className="text-danger"
                          style={{ userSelect: "none" }}
                        >
                          {errors?.date_of_birth &&
                            errors.date_of_birth.message}
                        </small>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div
                          className="userFormInputLabel"
                          style={{
                            fontSize: fontSize.title2,
                          }}
                        >
                          Gender
                        </div>
                        <Controller
                          control={control}
                          name="gender"
                          rules={formOptions.gender}
                          render={({ field: { onChange, value } }) => (
                            <RadioButton
                              name="gender"
                              data={["male", "female"]}
                              state={value}
                              handleChange={onChange}
                              isFilled={Boolean(getValues("gender"))}
                              disabled={!isEditingProfile}
                              user_detail={userDetail}
                            />
                          )}
                        />
                        <small
                          className="text-danger"
                          style={{
                            userSelect: "none",
                            display: "flex",
                            textAlign: "start",
                          }}
                        >
                          {errors?.gender && errors.gender.message}
                        </small>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div
                          className="userFormInputLabel"
                          style={{
                            fontSize: fontSize.title2,
                          }}
                        >
                          Company Name
                        </div>
                        <input
                          id="company_name"
                          name="company_name"
                          autoComplete="off"
                          type="text"
                          placeholder="e.g: ABC Company"
                          {...register(
                            "company_name",
                            formOptions.company_name
                          )}
                          disabled={!isEditingProfile}
                          style={{
                            background:
                              Boolean(getValues("company_name")) ||
                              !isEditingProfile
                                ? `${
                                    userDetail &&
                                    userDetail.role &&
                                    userDetail.role.id &&
                                    +userDetail.role.id === 3
                                      ? "#e7eeff"
                                      : "#D7FFFF"
                                  }`
                                : "#FFFFFF",
                            border:
                              errors && errors.company_name
                                ? "2px solid #EB4335"
                                : `2px solid ${
                                    userDetail &&
                                    userDetail.role &&
                                    userDetail.role.id &&
                                    +userDetail.role.id === 3
                                      ? "#2c65f7"
                                      : "#00BB99"
                                  }`,
                          }}
                          className="userFormInputStyle"
                        />
                        <small
                          className="text-danger"
                          style={{
                            userSelect: "none",
                            display: "flex",
                            textAlign: "start",
                          }}
                        >
                          {errors?.company_name && errors.company_name.message}
                        </small>
                      </Form.Group>
                    </div>
                  </Col>

                  {/* <Col md={6}>
                <div className="userFormInputGroup">
                  <Form.Group>
                    <div
                      className="userFormInputLabel"
                      style={{
                        fontSize: fontSize.title2,
                      }}
                    >
                      Phone Number
                    </div>
                    <div>
                      <Controller
                        control={control}
                        name="phone_number"
                        rules={formOptions.phone_number}
                        render={({ field: { onChange, value } }) => (
                          <input
                            id="phone_number"
                            name="phone_number"
                            autoComplete="off"
                            value={value}
                            onChange={onChange}
                            type="text"
                            placeholder="e.g: 08xxxxx"
                            disabled={!isEditingProfile}
                            style={{
                              background:
                                Boolean(getValues("phone_number")) ||
                                !isEditingProfile
                                  ? `${
                                      userDetail && userDetail.role_id !== 3
                                        ? "#e7eeff"
                                        : "#D7FFFF"
                                    }`
                                  : "#FFFFFF",
                              border:
                                errors && errors.phone_number
                                  ? "2px solid #EB4335"
                                  : `2px solid ${
                                      userDetail && userDetail.role_id !== 3
                                        ? "#2c65f7"
                                        : "#00BB99"
                                    }`,
                            }}
                            className="userFormInputStyle"
                          />
                        )}
                      />
                      <small
                        className="text-danger"
                        style={{
                          userSelect: "none",
                          display: "flex",
                          textAlign: "start",
                        }}
                      >
                        {errors?.phone_number && errors.phone_number.message}
                      </small>
                    </div>
                  </Form.Group>
                </div>
              </Col> */}

                  <Col md={6}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div>
                          <div
                            className="userFormInputLabel"
                            style={{
                              fontSize: fontSize.title2,
                            }}
                          >
                            Phone number
                          </div>
                          <div className="inputPhoneBox">
                            <div
                              className="inputPhoneCode"
                              onClick={() =>
                                isEditingProfile
                                  ? setIsOpenPhoneDropdown(!isOpenPhoneDropdown)
                                  : null
                              }
                              style={{
                                background:
                                  Boolean(getValues("phone_number")) ||
                                  !isEditingProfile
                                    ? `${
                                        userDetail &&
                                        userDetail.role &&
                                        userDetail.role.id &&
                                        +userDetail.role.id === 3
                                          ? "#e7eeff"
                                          : "#D7FFFF"
                                      }`
                                    : "#FFFFFF",
                                border:
                                  errors &&
                                  errors.country_id &&
                                  !phoneNumber.country_id
                                    ? "2px solid #EB4335"
                                    : `2px solid ${
                                        userDetail &&
                                        userDetail.role &&
                                        userDetail.role.id &&
                                        +userDetail.role.id === 3
                                          ? "#2c65f7"
                                          : "#00BB99"
                                      }`,
                              }}
                            >
                              {phoneNumber && phoneNumber.icon ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <CIcon icon={phoneNumber.icon} size="xxl" />
                                </div>
                              ) : (
                                "Country"
                              )}
                            </div>
                            <InputText
                              placeholder={"Fill in phone number"}
                              background={
                                errors && errors.phone_number
                                  ? "#FFFFFF"
                                  : `${
                                      userDetail &&
                                      userDetail.role &&
                                      userDetail.role.id &&
                                      +userDetail.role.id === 3
                                        ? "#e7eeff"
                                        : "#D7FFFF"
                                    }`
                              }
                              name={"phoneNumberMyProfile"}
                              border={
                                errors && errors.phone_number
                                  ? "2px solid #EB4335"
                                  : `2px solid ${
                                      userDetail &&
                                      userDetail.role &&
                                      userDetail.role.id &&
                                      +userDetail.role.id === 3
                                        ? "#2c65f7"
                                        : "#00BB99"
                                    }`
                              }
                              disabled={!isEditingProfile}
                              functionSetter={handleOnChangePhoneNumber}
                              phoneNumberState={phoneNumber}
                              setterPhoneNumber={setPhoneNumber}
                              className={"userFormInputStyle"}
                              value={
                                phoneNumber &&
                                phoneNumber.number &&
                                phoneNumber.phone_code
                                  ? `(${phoneNumber.phone_code}) ${phoneNumber.number}`
                                  : phoneNumber &&
                                    phoneNumber.phone_code &&
                                    !phoneNumber.number
                                  ? `(${phoneNumber.phone_code}) `
                                  : phoneNumber &&
                                    phoneNumber.number &&
                                    !phoneNumber.phone_code
                                  ? phoneNumber.number
                                  : ""
                              }
                            />
                          </div>
                          <small
                            className="text-danger"
                            style={{
                              userSelect: "none",
                              display: "flex",
                              textAlign: "start",
                            }}
                          >
                            {!phoneNumber.country_id &&
                            !phoneNumber.number &&
                            errors?.country_id &&
                            errors.country_id.message &&
                            errors?.phone_number &&
                            errors.phone_number.message
                              ? "Country and Phone Number is required"
                              : (!phoneNumber.country_id &&
                                  errors?.country_id &&
                                  errors.country_id.message) ||
                                (errors?.phone_number &&
                                  errors.phone_number.message)}
                          </small>
                          <div
                            className="inputContainer"
                            style={{ marginTop: "0" }}
                          >
                            <div>
                              {isOpenPhoneDropdown ? (
                                <InputDropdown
                                  options={countryOptions}
                                  styles={{
                                    marginTop: "1em",
                                    border: `2px solid ${
                                      userDetail &&
                                      userDetail.role &&
                                      userDetail.role.id &&
                                      +userDetail.role.id === 3
                                        ? "#2c65f7"
                                        : "#00BB99"
                                    }`,
                                    background: `${
                                      userDetail &&
                                      userDetail.role &&
                                      userDetail.role.id &&
                                      +userDetail.role.id === 3
                                        ? "#e7eeff"
                                        : "#D7FFFF"
                                    }`,
                                    borderRadius: "10px",
                                    height: "48px",
                                    cursor: "pointer",
                                    width: "100%",
                                  }}
                                  state={phoneNumber}
                                  setter={setPhoneNumber}
                                  functionSetter={
                                    handleChangePhoneNumberDropdown
                                  }
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div
                          className="userFormInputLabel"
                          style={{
                            fontSize: fontSize.title2,
                          }}
                        >
                          Industry
                        </div>
                        <Controller
                          control={control}
                          name="industry_id"
                          rules={formOptions.industry_id}
                          render={({ field: { onChange, value } }) => (
                            <CustomDropdown
                              isError={errors?.industry_id}
                              id="industry_id"
                              name="industry_id"
                              autoComplete="off"
                              value={value}
                              placeholder="Select one related industry"
                              options={industries}
                              action={onChange}
                              isSelected={
                                Boolean(getValues("industry_id")) ||
                                !isEditingProfile
                              }
                              inputFilledBackgroundColor="#D7FFFF"
                              fromprofile={true}
                              disabled={!isEditingProfile}
                              user_detail={userDetail}
                              selectedBackgroundColor={
                                userDetail &&
                                userDetail.role &&
                                userDetail.role.id &&
                                +userDetail.role.id === 3
                                  ? "#e7eeff"
                                  : ""
                              }
                            />
                          )}
                        />
                        <small
                          className="text-danger"
                          style={{ userSelect: "none" }}
                        >
                          {errors?.industry_id && errors.industry_id.message}
                        </small>
                      </Form.Group>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div
                          className="userFormInputLabel"
                          style={{
                            fontSize: fontSize.title2,
                          }}
                        >
                          Address
                        </div>
                        <TextArea
                          rows={5}
                          id="address"
                          name="address"
                          autoComplete="off"
                          error={errors?.address}
                          type="text"
                          placeholder="e.g: Sudirman street block 88 unit S"
                          isFilled={Boolean(getValues("address"))}
                          disabled={!isEditingProfile}
                          {...register("address", formOptions.address)}
                          user_detail={userDetail}
                        />
                        <small
                          className="text-danger"
                          style={{
                            userSelect: "none",
                            display: "flex",
                            textAlign: "start",
                          }}
                        >
                          {errors?.address && errors.address.message}
                        </small>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div
                          className="userFormInputLabel"
                          style={{
                            fontSize: fontSize.title2,
                          }}
                        >
                          Role / Position
                        </div>
                        <Controller
                          control={control}
                          name="occupation_id"
                          rules={formOptions.occupation_id}
                          render={({ field: { onChange, value } }) => (
                            <CustomDropdown
                              isError={errors?.occupation_id}
                              id="occupation_id"
                              name="occupation_id"
                              value={value}
                              placeholder="Select one role/position"
                              options={occupations}
                              action={onChange}
                              isSelected={
                                Boolean(getValues("occupation_id")) ||
                                !isEditingProfile
                              }
                              inputFilledBackgroundColor="#D7FFFF"
                              fromprofile={true}
                              disabled={!isEditingProfile}
                              user_detail={userDetail}
                              selectedBackgroundColor={
                                userDetail &&
                                userDetail.role &&
                                userDetail.role.id &&
                                +userDetail.role.id === 3
                                  ? "#e7eeff"
                                  : ""
                              }
                            />
                          )}
                        />
                        <small
                          className="text-danger"
                          style={{
                            userSelect: "none",
                            display: "flex",
                            textAlign: "start",
                          }}
                        >
                          {errors?.occupation_id &&
                            errors.occupation_id.message}
                        </small>
                      </Form.Group>
                    </div>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div
                          className="userFormInputLabel"
                          style={{
                            fontSize: fontSize.title2,
                          }}
                        >
                          Highest Degree
                        </div>
                        <Controller
                          control={control}
                          name="degree_id"
                          rules={formOptions.degree_id}
                          render={({ field: { onChange, value } }) => (
                            <CustomDropdown
                              isError={errors?.degree_id}
                              id="degree_id"
                              name="degree_id"
                              value={value}
                              placeholder="Choose your highest degree"
                              options={degrees}
                              action={onChange}
                              isSelected={
                                Boolean(getValues("degree_id")) ||
                                !isEditingProfile
                              }
                              inputFilledBackgroundColor="#D7FFFF"
                              fromprofile={true}
                              disabled={!isEditingProfile}
                              user_detail={userDetail}
                              selectedBackgroundColor={
                                userDetail &&
                                userDetail.role &&
                                userDetail.role.id &&
                                +userDetail.role.id === 3
                                  ? "#e7eeff"
                                  : ""
                              }
                            />
                          )}
                        />
                        <small
                          className="text-danger"
                          style={{
                            userSelect: "none",
                            display: "flex",
                            textAlign: "start",
                          }}
                        >
                          {errors?.degree_id && errors.degree_id.message}
                        </small>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div
                          className="userFormInputLabel"
                          style={{
                            fontSize: fontSize.title2,
                          }}
                        >
                          Country
                        </div>
                        {/* <Controller
                      control={control}
                      name="country_id"
                      rules={formOptions.country_id}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <DropdownSearch
                            name="country_id"
                            datalist={countries}
                            value={value}
                            onChange={onChange}
                            keyToShow="name"
                            keyToSend="id"
                            placeholder="Select your country"
                            disabled={!isEditingProfile}
                            error={errors?.country_id}
                            user_detail={userDetail}
                          />
                        );
                      }}
                    /> */}
                        <InputDropdown
                          options={countryOptions}
                          styles={{
                            border:
                              errors &&
                              errors.country_id &&
                              !phoneNumber.country_id
                                ? "2px solid #EB4335"
                                : `2px solid ${
                                    userDetail &&
                                    userDetail.role &&
                                    userDetail.role.id &&
                                    +userDetail.role.id === 3
                                      ? "#2c65f7"
                                      : "#00BB99"
                                  }`,
                            background:
                              errors &&
                              errors.country_id &&
                              !phoneNumber.country_id
                                ? "#FFFFFF"
                                : `${
                                    userDetail &&
                                    userDetail.role &&
                                    userDetail.role.id &&
                                    +userDetail.role.id === 3
                                      ? "#e7eeff"
                                      : "#D7FFFF"
                                  }`,
                            borderRadius: "10px",
                            height: "48px",
                            cursor: "pointer",
                            width: "100%",
                          }}
                          state={phoneNumber}
                          setter={setPhoneNumber}
                          functionSetter={handleChangePhoneNumberDropdown}
                          value={
                            phoneNumber &&
                            phoneNumber.value &&
                            phoneNumber.value.label
                              ? phoneNumber && phoneNumber.value
                              : null
                          }
                          disabled={!isEditingProfile}
                        />
                        <small
                          className="text-danger"
                          style={{
                            userSelect: "none",
                            display: "flex",
                            textAlign: "start",
                          }}
                        >
                          {!phoneNumber.country_id &&
                            errors?.country_id &&
                            errors.country_id.message}
                        </small>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div
                          className="userFormInputLabel"
                          style={{
                            fontSize: fontSize.title2,
                          }}
                        >
                          State / Province
                        </div>
                        <InputDropdown
                          options={stateOptions}
                          styles={{
                            border:
                              errors && errors.province && !provinceValue
                                ? "2px solid #EB4335"
                                : "2px solid #2c65f7",
                            background:
                              errors && errors.province ? "#FFFFFF" : "#e7eeff",
                            borderRadius: "10px",
                            height: "48px",
                            cursor: "pointer",
                            width: "100%",
                          }}
                          disabled={!isEditingProfile}
                          setter={setProvinceValue}
                          state={"province"}
                          functionSetter={handleChangeDropdown}
                          value={provinceValue}
                          className="userFormInputStyle"
                          placeholder="Select..."
                        />
                        <small
                          className="text-danger"
                          style={{
                            userSelect: "none",
                            display: "flex",
                            textAlign: "start",
                          }}
                        >
                          {errors?.province && errors.province.message}
                        </small>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div
                          className="userFormInputLabel"
                          style={{
                            fontSize: fontSize.title2,
                          }}
                        >
                          University
                        </div>
                        <input
                          id="university"
                          name="university"
                          autoComplete="off"
                          type="text"
                          placeholder="Input university"
                          {...register("university", formOptions.university)}
                          disabled={!isEditingProfile}
                          style={{
                            background:
                              Boolean(getValues("university")) ||
                              !isEditingProfile
                                ? `${
                                    userDetail &&
                                    userDetail.role &&
                                    userDetail.role.id &&
                                    +userDetail.role.id === 3
                                      ? "#e7eeff"
                                      : "#D7FFFF"
                                  }`
                                : "#FFFFFF",
                            border:
                              errors && errors.university
                                ? "2px solid #EB4335"
                                : `2px solid ${
                                    userDetail &&
                                    userDetail.role &&
                                    userDetail.role.id &&
                                    +userDetail.role.id === 3
                                      ? "#2c65f7"
                                      : "#00BB99"
                                  }`,
                          }}
                          className="userFormInputStyle"
                        />
                        <small
                          className="text-danger"
                          style={{
                            userSelect: "none",
                            display: "flex",
                            textAlign: "start",
                          }}
                        >
                          {errors?.university && errors.university.message}
                        </small>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div
                          className="userFormInputLabel"
                          style={{
                            fontSize: fontSize.title2,
                          }}
                        >
                          City
                        </div>
                        <InputDropdown
                          options={cityOptions}
                          styles={{
                            border:
                              errors && errors.city && !cityValue
                                ? "2px solid #EB4335"
                                : "2px solid #2c65f7",
                            background:
                              errors && errors.city ? "#FFFFFF" : "#e7eeff",
                            borderRadius: "10px",
                            height: "48px",
                            cursor: "pointer",
                            width: "100%",
                          }}
                          disabled={!isEditingProfile}
                          setter={setCityValue}
                          functionSetter={handleChangeDropdown}
                          value={cityValue}
                          className="userFormInputStyle"
                        />
                        <small
                          className="text-danger"
                          style={{
                            userSelect: "none",
                            display: "flex",
                            textAlign: "start",
                          }}
                        >
                          {errors?.city && errors.city.message}
                        </small>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div
                          className="userFormInputLabel"
                          style={{
                            fontSize: fontSize.title2,
                          }}
                        >
                          Postal Code
                        </div>
                        <input
                          id="postal_code"
                          name="postal_code"
                          autoComplete="off"
                          type="text"
                          placeholder="Input postal code"
                          {...register("postal_code", formOptions.postal_code)}
                          disabled={!isEditingProfile}
                          style={{
                            background:
                              Boolean(getValues("postal_code")) ||
                              !isEditingProfile
                                ? `${
                                    userDetail &&
                                    userDetail.role &&
                                    userDetail.role.id &&
                                    +userDetail.role.id === 3
                                      ? "#e7eeff"
                                      : "#D7FFFF"
                                  }`
                                : "#FFFFFF",
                            border:
                              errors && errors.postal_code
                                ? "2px solid #EB4335"
                                : `2px solid ${
                                    userDetail &&
                                    userDetail.role &&
                                    userDetail.role.id &&
                                    +userDetail.role.id === 3
                                      ? "#2c65f7"
                                      : "#00BB99"
                                  }`,
                          }}
                          className="userFormInputStyle"
                        />
                        <small
                          className="text-danger"
                          style={{
                            userSelect: "none",
                            display: "flex",
                            textAlign: "start",
                          }}
                        >
                          {errors?.postal_code && errors.postal_code.message}
                        </small>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={6}> </Col>
                  <Col md={6}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div className="userFormInputGroupFlexContainer">
                          <div
                            className="userFormInputLabel"
                            style={{
                              fontSize: fontSize.title2,
                            }}
                          >
                            {isChangingEmail ? "New email address" : "Email"}
                          </div>
                          {isChangingEmail ? (
                            <CancelText onClick={() => setChangingEmail(false)}>
                              Cancel
                            </CancelText>
                          ) : (
                            <InputAction
                              onClick={() => setChangingEmail(true)}
                              user_detail={userDetail}
                            >
                              Change
                            </InputAction>
                          )}
                        </div>
                        {isChangingEmail ? (
                          <div className="userFormInputGroupFlexContainer">
                            <input
                              style={{
                                width: smallScreen ? "100%" : "60%",
                                background: "#FFFFFF",
                                border: `2px solid ${
                                  userDetail &&
                                  userDetail.role &&
                                  userDetail.role.id &&
                                  +userDetail.role.id === 3
                                    ? "#2c65f7"
                                    : "#00BB99"
                                }`,
                              }}
                              value={newEmailAddress}
                              onChange={(e) =>
                                setNewEmailAddress(e.target.value)
                              }
                              type="email"
                              placeholder="e.g: john@example.com"
                              className="userFormInputStyle"
                            />
                            {newEmailAddress !== "" ? (
                              <GreenButton
                                action={handleUpdateEmail}
                                width="130px"
                                height="30px"
                                textSize={fontSize.title3}
                                text="Update"
                                bgcolor={
                                  newEmailAddress
                                    ? `${
                                        userDetail &&
                                        userDetail.role &&
                                        userDetail.role.id &&
                                        +userDetail.role.id === 3
                                          ? "#2c65f7"
                                          : "#00BB99"
                                      }`
                                    : "#AAAAAA"
                                }
                              />
                            ) : null}
                          </div>
                        ) : (
                          <div className="userFormInputGroupFlexContainer">
                            <div
                              style={{
                                fontSize: fontSize.title3,
                                fontWeight: "400",
                              }}
                            >
                              Your email address is
                              <b> {userDetail?.email}</b>
                            </div>
                          </div>
                        )}
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={6}></Col>

                  <Col md={6}>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div className="userFormInputGroupFlexContainer">
                          <div
                            className="userFormInputLabel"
                            style={{
                              fontSize: fontSize.title2,
                            }}
                          >
                            {isChangingPassword ? (
                              <>
                                <span>New password</span>
                                <Tooltip content="Your password must at least 8 characters, including uppercase, lowercase, numbers, and special characters" />
                              </>
                            ) : (
                              <span>Password</span>
                            )}
                          </div>
                          {isChangingPassword ? (
                            <CancelText
                              onClick={() => setChangingPassword(false)}
                            >
                              Cancel
                            </CancelText>
                          ) : (
                            <InputAction
                              onClick={() => setChangingPassword(true)}
                              user_detail={userDetail}
                            >
                              Change
                            </InputAction>
                          )}
                        </div>
                        {isChangingPassword ? (
                          <div>
                            <div
                              className="userFormInputGroupFlexContainer"
                              style={{ marginBottom: "10px" }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <input
                                  style={{
                                    width: "100%",
                                    background: "#FFFFFF",
                                    border: `2px solid ${
                                      userDetail &&
                                      userDetail.role &&
                                      userDetail.role.id &&
                                      +userDetail.role.id === 3
                                        ? "#2c65f7"
                                        : "#00BB99"
                                    }`,
                                  }}
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  type={passwordType}
                                  placeholder="New password"
                                  className="userFormInputStyle"
                                />

                                <input
                                  autoComplete="off"
                                  style={{ display: "none" }}
                                  id="show1"
                                  type="checkbox"
                                  checked={passwordType === "text"}
                                  onChange={() =>
                                    setPasswordType((prev) =>
                                      prev === "password" ? "text" : "password"
                                    )
                                  }
                                />
                                <label
                                  htmlFor="show1"
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    margin: "0 12px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {passwordType === "password" ? (
                                    <AiFillEye size={25} />
                                  ) : (
                                    <AiFillEyeInvisible size={25} />
                                  )}
                                </label>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  marginTop:
                                    !repeatPassword || checkRepeatPassword()
                                      ? null
                                      : "1.3em",
                                }}
                              >
                                <div
                                  style={{
                                    position: "relative",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <input
                                    style={{
                                      width: "100%",
                                      background: "#FFFFFF",
                                      border: `2px solid ${
                                        userDetail &&
                                        userDetail.role &&
                                        userDetail.role.id &&
                                        +userDetail.role.id === 3
                                          ? "#2c65f7"
                                          : "#00BB99"
                                      }`,
                                    }}
                                    value={repeatPassword}
                                    onChange={(e) =>
                                      setRepeatPassword(e.target.value)
                                    }
                                    type={repeatPasswordType}
                                    placeholder="Repeat password"
                                    className="userFormInputStyle"
                                  />
                                  <input
                                    autoComplete="off"
                                    style={{ display: "none" }}
                                    id="show2"
                                    type="checkbox"
                                    checked={repeatPasswordType === "text"}
                                    onChange={() =>
                                      setRepeatPasswordType((prev) =>
                                        prev === "password"
                                          ? "text"
                                          : "password"
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor="show2"
                                    style={{
                                      position: "absolute",
                                      right: 0,
                                      margin: "0 12px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {repeatPasswordType === "password" ? (
                                      <AiFillEye size={25} />
                                    ) : (
                                      <AiFillEyeInvisible size={25} />
                                    )}
                                  </label>
                                </div>
                                <small
                                  className="text-danger"
                                  style={{
                                    userSelect: "none",
                                    display: "flex",
                                    textAlign: "left",
                                  }}
                                >
                                  {!repeatPassword || checkRepeatPassword()
                                    ? null
                                    : "Password not match"}
                                </small>
                              </div>
                            </div>
                            <GreenButton
                              action={handleUpdatePassword}
                              width="130px"
                              height="30px"
                              textSize={fontSize.title3}
                              text="Update"
                              bgcolor={
                                password && repeatPassword
                                  ? `${
                                      userDetail &&
                                      userDetail.role &&
                                      userDetail.role.id &&
                                      +userDetail.role.id === 3
                                        ? "#2c65f7"
                                        : "#00BB99"
                                    }`
                                  : "#AAAAAA"
                              }
                            />
                          </div>
                        ) : null}
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={6}></Col>
                </Row>

                {isEditingProfile && (
                  <div className="userFormBtnMainContainer">
                    <div
                      type="reset"
                      className="userFormBtnCancel"
                      onClick={() => setIsModalCancel(true)}
                    >
                      Cancel
                    </div>
                    {userDetail &&
                    userDetail.role &&
                    userDetail.role.id &&
                    +userDetail.role.id === 3 ? (
                      <div
                        disabled={isSubmitting}
                        type="submit"
                        className={
                          isSubmitting
                            ? "userFormBtnSaveDisabledForStudent"
                            : "userFormBtnSaveForStudent"
                        }
                      >
                        Save
                      </div>
                    ) : (
                      <div
                        disabled={isSubmitting}
                        type="submit"
                        className={
                          isSubmitting
                            ? "userFormBtnSaveDisabled"
                            : "userFormBtnSave"
                        }
                      >
                        Save
                      </div>
                    )}
                  </div>
                )}
              </form>
              <ModalComponent
                show={isModalCancel}
                body={[
                  <p style={{ fontWeight: "700" }} key={0}>
                    Are you sure want to cancel?
                  </p>,
                ]}
                buttonPrimary="Yes"
                buttonSecondary="No"
                onPrimaryClick={() => {
                  clearErrors([
                    "phone_number",
                    "country_id",
                    "province",
                    "city",
                    "first_name",
                    "last_name",
                    "gender",
                    "address",
                    "postal_code",
                    "place_of_birth",
                    "company_name",
                    "date_of_birth",
                    "university",
                    "degree_id",
                    "industry_id",
                    "occupation_id",
                  ]);
                  handleFetchUserDetail(userDetail);
                  setIsEditingProfile(false);
                  setIsModalCancel(false);
                }}
                onSecondaryClick={() => setIsModalCancel(false)}
                primaryColor={
                  userDetail &&
                  userDetail.role &&
                  userDetail.role.id &&
                  +userDetail.role.id === 3
                    ? "#2c65f7"
                    : "#00BB99"
                }
              />
            </>
          ) : null}
        </>
      )}
    </div>
  );
}

export default UserForm;

const TextArea = styled.textarea`
  background: ${({ isFilled, disabled, user_detail }) =>
    isFilled || disabled
      ? `${
          user_detail &&
          user_detail.role &&
          user_detail.role.id &&
          +user_detail.role.id === 3
            ? "#e7eeff"
            : "#D7FFFF"
        }`
      : "#FFFFFF"};
  width: 100%;
  border: ${({ error, user_detail }) =>
    error
      ? "2px solid #EB4335"
      : `2px solid ${
          user_detail &&
          user_detail.role &&
          user_detail.role.id &&
          +user_detail.role.id === 3
            ? "#2c65f7"
            : "#00BB99"
        }`};
  padding: 10px 15px;
  border-radius: 10px;
  &:focus {
    outline: none;
    border-width: 3px;
  }
`;

const CancelText = styled.div`
  font-size: ${fontSize.title4};
  text-align: center;
  color: #404040;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  &:hover {
    text-decoration: underline;
    font-weight: bold;
  }
`;

const InputAction = styled.div`
  font-size: ${fontSize.title4};
  color: ${({ user_detail }) =>
    user_detail &&
    user_detail.role &&
    user_detail.role.id &&
    +user_detail.role.id === 3
      ? "#2c65f7"
      : "#00bb99"};
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  &:hover {
    text-decoration: underline;
    font-weight: bold;
  }
`;

const MentorProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
`;

const MentorProfilePicture = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: ${({ isEditingProfile }) => (isEditingProfile ? "pointer" : "")};
`;

const MentorIdentification = styled.div`
  margin-left: 1em;
`;

const MentorName = styled.p`
  margin-bottom: 0.3em;
  font-weight: 700;
  font-size: 25px;
`;

const MentorIdTitle = styled.p`
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  color: #2c65f7;
`;

const MentorId = styled.p`
  font-size: 14px;
  font-weight: 500;
`;
