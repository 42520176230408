import React, { useEffect, useState } from "react";

import styled from "styled-components";

// lib
import fontSize from "../../../lib/styling/font_size";

// react bootstrap form
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

// react-collapsible
import Collapsible from "react-collapsible";

// images & icons
import option_video_course_lesson_icon from "../../../assets/icons/option_video_course_lesson_icon.svg";
import option_article_course_lesson_icon from "../../../assets/icons/option_article_course_lesson_icon.svg";
import upload_file_icon from "../../../assets/icons/upload_file_icon.svg";
import dropdown_icon from "../../../assets/icons/dropdown_icon.svg";
import lesson_filled_icon from "../../../assets/icons/lesson_filled_icon.svg";

// Components
import TextEditor from "../../../components/input/TextEditor";
import UploadingVideoProgressBar from "../../UploadingVideoProgressBar";

// helpers
import getPdfCoverImage from "../../../helpers/get-pdf-cover-image";

// Api video player
import ApiVideoPlayer from "@api.video/react-player";

const InputCourseLesson = (props) => {
  const {
    lessons,
    lesson,
    counter,
    handleOnInputCourseSectionChange,
    sectionCounter,
    handleAddNewLesson,
    handleRemoveLesson,
    cancelUploadVideo,
    isEditPage,
    courseSections,
  } = props || {};

  const [articlePreview, setArticlePreview] = useState(null);
  const [duration, setDuration] = useState(0);

  const getArticlePreview = async (file, fileUrl) => {
    const preview = await getPdfCoverImage(file, fileUrl);
    await setArticlePreview(preview);
  };

  const handleLoadedMetadata = (event) => {
    const videoElement = event.target;
    setDuration(Math.floor(videoElement.duration));
  };

  useEffect(() => {
    if (lesson["content_type"]) {
      if (lesson["content_type"] === "article") {
        if (lesson["content_value"]["content_to_save"]) {
          getArticlePreview(lesson["content_value"]["content_to_save"]);
        } else {
          if (
            lesson["content_value"]["content_to_view"] &&
            lesson["content_value"]["content_to_view"]["url"]
          ) {
            getArticlePreview(
              null,
              lesson["content_value"]["content_to_view"]["url"]
            );
          }
        }
      } else {
        if (
          lesson["content_type"] === "video" &&
          lesson["content_value"]["content_to_save"] &&
          lesson["content_value"]["content_to_view"] &&
          duration
        ) {
          handleOnInputCourseSectionChange(
            duration,
            sectionCounter,
            "duration",
            "lessons",
            counter,
            "duration_in_seconds"
          );
        }
      }
    }
  }, [
    lesson["content_value"]["content_to_view"],
    lesson["content_value"]["content_to_save"],
    lesson["content_type"],
    duration,
  ]);

  return (
    <Collapsible
      open={lesson["is_open_accordion"]}
      trigger={
        <CollapsibleTrigger
          onClick={() =>
            handleOnInputCourseSectionChange(
              !lesson["is_open_accordion"],
              sectionCounter,
              "accordion",
              "lessons",
              counter,
              "is_open_accordion"
            )
          }
          is_open_accordion={lesson["is_open_accordion"]}
        >
          <div className="lessonAccordionLabelContainer">
            <img src={lesson_filled_icon} alt="lesson_filled_icon" />
            <div className="lessonAccordionLabelContainer">
              <div>{`Lesson ${counter + 1}:`}</div>
              <div className="lessonAccordionLabelLessonNameContainer">
                {lesson["lesson"] ? lesson["lesson"] : "Fill in lesson"}
              </div>
            </div>
          </div>
          <div className="lessonAccordionIconContainer">
            <img src={dropdown_icon} alt="dropdown_icon" />
          </div>
        </CollapsibleTrigger>
      }
    >
      <div className="courseLessonMainContainer">
        <div className="inputCourseLessonMainContainer">
          <Col>
            <div className="userFormInputGroup">
              <Form.Group>
                <InputLabel>{`Lesson ${counter + 1}`}</InputLabel>
                <Input
                  type="text"
                  name={`lesson_${counter}`}
                  placeholder="Enter lesson details here..."
                  value={lesson["lesson"]}
                  onChange={({ target: { value } = {} }) =>
                    handleOnInputCourseSectionChange(
                      value,
                      sectionCounter,
                      "text",
                      "lessons",
                      counter,
                      "lesson"
                    )
                  }
                  style={{
                    borderColor:
                      lesson["invalid_lesson"] && !lesson["lesson"]
                        ? "#EB4335"
                        : undefined,
                  }}
                  maxLength={255}
                  autoComplete="off"
                />
                {lesson["invalid_lesson"] && !lesson["lesson"] ? (
                  <div
                    style={{
                      color: "#EB4335",
                      fontSize: 12,
                      marginTop: "0.5em",
                    }}
                  >
                    This field is required
                  </div>
                ) : null}
              </Form.Group>
            </div>
          </Col>
          <Col>
            <div className="userFormInputGroup">
              <Form.Group>
                <InputLabel>Content Type</InputLabel>
                <div style={{ paddingBottom: "10px" }}>
                  Choose the main type of content for this section
                </div>
                <div className="userFormInputGroup">
                  <div className="courseLessonContentTypeMainContainer">
                    <div
                      className="courseLessonContentTypeContainer"
                      style={{
                        border:
                          lesson &&
                          lesson["content_type"] &&
                          lesson["content_type"] === "video"
                            ? "1px solid #000000"
                            : "none",
                      }}
                      onClick={() =>
                        handleOnInputCourseSectionChange(
                          "video",
                          sectionCounter,
                          "content_type",
                          "lessons",
                          counter,
                          "content_type"
                        )
                      }
                    >
                      <div className="courseLessonContentTypeOptionContainer">
                        <img
                          src={option_video_course_lesson_icon}
                          alt="option_video_course_lesson_icon"
                          style={{
                            width: "40px",
                            height: "auto",
                          }}
                        />
                        <div style={{ paddingTop: "10px" }}>Video</div>
                      </div>
                    </div>
                    <div
                      className="courseLessonContentTypeContainer"
                      style={{
                        border:
                          lesson &&
                          lesson["content_type"] &&
                          lesson["content_type"] === "article"
                            ? "1px solid #000000"
                            : "none",
                      }}
                      onClick={() =>
                        handleOnInputCourseSectionChange(
                          "article",
                          sectionCounter,
                          "content_type",
                          "lessons",
                          counter,
                          "content_type"
                        )
                      }
                    >
                      <div className="courseLessonContentTypeOptionContainer">
                        <img
                          src={option_article_course_lesson_icon}
                          alt="option_article_course_lesson_icon"
                        />
                        <div style={{ paddingTop: "10px" }}>Article</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </div>
            {lesson && lesson["content_type"] && lesson["content_value"] ? (
              <div className="userFormInputGroup">
                <Form.Group>
                  <div className="labelForDescription">
                    <InputLabel>
                      {lesson["content_type"] === "video"
                        ? "Upload video"
                        : "Upload article"}
                    </InputLabel>
                  </div>
                  <div
                    className={
                      !lesson["content_value"]["content_to_view"]
                        ? "inputImageContainer"
                        : "inputImageContainerNoPointer"
                    }
                    onClick={() =>
                      !lesson["content_value"]["content_to_view"]
                        ? document
                            .querySelector(
                              `.input-field-course-section-${sectionCounter}-lesson-${lesson["content_type"]}-${counter}`
                            )
                            .click()
                        : null
                    }
                    style={{
                      border:
                        lesson["invalid_content"] &&
                        (!lesson["content_value"]["content_to_view"] ||
                          !lesson["content_value"]["content_to_save"])
                          ? "2px solid #EB4335"
                          : "2px solid #00bb99",
                    }}
                  >
                    <input
                      type="file"
                      accept={
                        lesson["content_type"] === "video"
                          ? "video/*"
                          : "application/pdf"
                      }
                      className={`input-field-course-section-${sectionCounter}-lesson-${lesson["content_type"]}-${counter}`}
                      hidden
                      onChange={({ target: { files } }) =>
                        handleOnInputCourseSectionChange(
                          files,
                          sectionCounter,
                          "video/article",
                          "lessons",
                          counter,
                          "content_value",
                          lesson["content_type"] === "video" ? false : true
                        )
                      }
                    />
                    <div className="imageToViewContainer">
                      {lesson["content_value"]["content_to_view"] ? (
                        <div>
                          {lesson["content_type"] === "video" &&
                          lesson["content_value"]["content_to_view"] &&
                          lesson["content_value"]["content_to_view"]["url"] ? (
                            <video
                              id={`lesson-${counter}-video-player`}
                              style={{ maxWidth: 450, maxHeight: 250 }}
                              controls={true}
                              onLoadedMetadata={handleLoadedMetadata}
                            >
                              <source
                                src={
                                  lesson["content_value"]["content_to_view"][
                                    "url"
                                  ]
                                }
                                type="video/mp4"
                              />
                            </video>
                          ) : lesson["content_type"] === "video" &&
                            lesson["content_value"]["content_to_view"] &&
                            lesson["content_value"]["content_to_view"][
                              "lesson_video_id"
                            ] &&
                            lesson["content_value"]["content_to_view"][
                              "video_api_token"
                            ] ? (
                            <ApiVideoPlayer
                              video={{
                                id: lesson["content_value"]["content_to_view"][
                                  "lesson_video_id"
                                ],
                                token:
                                  lesson["content_value"]["content_to_view"][
                                    "video_api_token"
                                  ],
                              }}
                              style={{
                                width: 450,
                                height: 250,
                                objectFit: "cover",
                                borderRadius: "15px",
                              }}
                            />
                          ) : lesson["content_type"] === "video" &&
                            lesson["content_value"]["content_to_view"] &&
                            !lesson["content_value"]["content_to_view"][
                              "url"
                            ] &&
                            lesson["show_progress_bar"] ? (
                            <UploadingVideoProgressBar
                              videoToView={
                                lesson["content_value"]["content_to_view"]
                              }
                              cancelUploadVideo={cancelUploadVideo}
                            />
                          ) : (
                            <div>
                              {articlePreview ? (
                                <img
                                  src={articlePreview}
                                  style={{
                                    maxWidth: 450,
                                    maxHeight: 250,
                                    objectFit: "cover",
                                  }}
                                  alt="courseImage"
                                />
                              ) : null}
                            </div>
                          )}
                        </div>
                      ) : (
                        <img
                          src={upload_file_icon}
                          alt="upload_file_icon"
                          style={{ maxWidth: 35, maxHeight: 35 }}
                        />
                      )}
                      {/* {!courseVideoHighlightFailedToUploadMsg &&
                  courseVideoHighlightToView &&
                  courseVideoHighlightToView.fileName ? (
                    <div className="imageFileNameFontStyle">
                      {courseVideoHighlightToView.fileName}
                    </div>
                  ) : null}
                  {courseVideoHighlightFailedToUploadMsg && (
                    <div style={{ color: "#EB4335", fontSize: 12 }}>
                      {courseVideoHighlightFailedToUploadMsg}
                    </div>
                  )} */}
                    </div>
                    {lesson["content_value"]["content_to_view"] &&
                      (lesson["content_value"]["content_to_view"]["url"] ||
                        (lesson["content_value"]["content_to_view"][
                          "lesson_video_id"
                        ] &&
                          lesson["content_value"]["content_to_view"][
                            "video_api_token"
                          ])) && (
                        <div
                          className="btnChangeImage"
                          onClick={() =>
                            document
                              .querySelector(
                                `.input-field-course-section-${sectionCounter}-lesson-${lesson["content_type"]}-${counter}`
                              )
                              .click()
                          }
                        >
                          <img
                            src={upload_file_icon}
                            alt="upload_file_icon"
                            width={17}
                            height={17}
                          ></img>
                          <div className="changeImageLabel">
                            Replace {lesson["content_type"]}
                          </div>
                        </div>
                      )}
                    {lesson["content_value"]["content_to_view"] ? null : (
                      <div className="labelImage">{"Click to upload "}</div>
                    )}
                    {lesson["content_value"]["content_to_view"] ? null : (
                      <div className="placeholderImage">
                        Maximum file size 15MB
                      </div>
                    )}
                    {lesson["content_value"]["content_to_view"] ? null : (
                      <div
                        className="placeholderImage"
                        style={{ paddingTop: "3em" }}
                      >
                        {`Supported file type: ${
                          lesson["content_type"] === "video"
                            ? ".mp4, .mov"
                            : ".pdf"
                        }`}
                      </div>
                    )}
                  </div>
                  {lesson["invalid_content"] &&
                  (!lesson["content_value"]["content_to_view"] ||
                    !lesson["content_value"]["content_to_save"]) ? (
                    <div
                      style={{
                        color: "#EB4335",
                        fontSize: 12,
                        marginTop: "0.5em",
                        marginBottom: "20px",
                      }}
                    >
                      {lesson["invalid_content_msg"]}
                    </div>
                  ) : null}
                </Form.Group>
              </div>
            ) : null}
            {lesson["invalid_content"] && !lesson["content_type"] ? (
              <div
                style={{
                  color: "#EB4335",
                  fontSize: 12,
                  marginTop: "-10px",
                  marginBottom: "20px",
                }}
              >
                {lesson["invalid_content_msg"]}
              </div>
            ) : null}
          </Col>
          <Col>
            <div className="userFormInputGroup">
              <Form.Group>
                <InputLabel>
                  Additional information about this lesson
                </InputLabel>
                <TextEditor
                  onChange={(content) =>
                    handleOnInputCourseSectionChange(
                      content,
                      sectionCounter,
                      "text-editor",
                      "lessons",
                      counter,
                      "additional_information"
                    )
                  }
                  preloadValue={
                    lesson && lesson["additional_information"]
                      ? lesson["additional_information"]
                      : null
                  }
                />
                {lesson["invalid_additional_information"] &&
                !lesson["additional_information"] ? (
                  <div
                    style={{
                      color: "#EB4335",
                      fontSize: 12,
                      marginTop: "0.5em",
                    }}
                  >
                    This field is required
                  </div>
                ) : null}
              </Form.Group>
            </div>
          </Col>
          <AddCourseLesson>
            <CancelAddCourseLessonButton
              onClick={() =>
                courseSections &&
                courseSections.length > 1 &&
                lessons.length <= 1
                  ? handleRemoveLesson(sectionCounter, counter, true)
                  : lessons.length > 1
                  ? handleRemoveLesson(sectionCounter, counter)
                  : null
              }
              disabled={
                courseSections &&
                courseSections.length === 1 &&
                lessons.length === 1
              }
            >
              <CancelAddCourseLessonText
                disabled={
                  courseSections &&
                  courseSections.length === 1 &&
                  lessons.length === 1
                }
              >
                Cancel
              </CancelAddCourseLessonText>
            </CancelAddCourseLessonButton>
            <AddCourseLessonButton
              onClick={() =>
                lesson &&
                lesson["lesson"] &&
                lesson["content_type"] &&
                lesson["additional_information"] &&
                lesson["content_value"] &&
                ((isEditPage && lesson["content_value"]["content_to_view"]) ||
                  (!isEditPage &&
                    lesson["content_value"]["content_to_view"] &&
                    lesson["content_value"]["content_to_save"]))
                  ? handleAddNewLesson(sectionCounter)
                  : null
              }
              greenBtn={
                lesson &&
                lesson["lesson"] &&
                lesson["content_type"] &&
                lesson["additional_information"] &&
                lesson["content_value"] &&
                ((isEditPage && lesson["content_value"]["content_to_view"]) ||
                  (!isEditPage &&
                    lesson["content_value"]["content_to_view"] &&
                    lesson["content_value"]["content_to_save"]))
              }
            >
              <AddCourseLessonText>Add more lesson</AddCourseLessonText>
            </AddCourseLessonButton>
          </AddCourseLesson>
        </div>
      </div>
    </Collapsible>
  );
};

export default InputCourseLesson;

const InputLabel = styled.label`
  margin-bottom: 5px;
  font-size: ${fontSize.title2};
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  border: 2px solid #00bb99;
  padding: 10px 15px;
  border-radius: 10px;
  :focus {
    outline: none;
    border-width: 3px;
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const AddCourseLesson = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  gap: 20px;
`;

const AddCourseLessonButton = styled.button`
  display: flex;
  background: ${({ greenBtn }) => (greenBtn ? "#00bb99" : "#cccccc")};
  border-radius: 7px;
  border: transparent;
  padding: 0.5em 1em;
  transition: 0.2s;
`;

const CancelAddCourseLessonButton = styled.button`
  display: flex;
  background: ${({ disabled }) => (disabled ? "#cccccc" : "#f8f8f8")};
  border-radius: 7px;
  border: ${({ disabled }) => (disabled ? "none" : "1px solid #000000")};
  padding: ${({ disabled }) => (disabled ? "0.5em 1em" : "0.4em 1em")};
  transition: 0.2s;
`;

const AddCourseLessonText = styled.span`
  color: #ffffff;
`;

const CancelAddCourseLessonText = styled.span`
  color: ${({ disabled }) => (disabled ? "#ffffff" : "#000000")};
`;

const CollapsibleTrigger = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background: #d7ffff;
  padding: 12px 10px;
  user-select: none;
  // border-radius: 10px;
  border-top: 2px solid #000000;
  border-right: 2px solid #000000;
  border-left: 2px solid #000000;
  border-bottom: ${({ is_open_accordion }) =>
    is_open_accordion ? "none" : "2px solid #000000"};
`;
