// Method 1

const initialState = {
  userDetail: null,
  scheduleModal: false,
  approvalModal: false,
  declineModal: false,
  assignmentMentorModal: false,
  assignmentScheduleModal: false,
  internal_table_value: null,
  userRole: "",
  isInternal: false,
  userLoginOtpToken: null,
  usersNotifications: [],
  searchKey: "",
  courseFormAccordion1Value: null,
  courseFormAccordion2Value: null,
  courseFormAccordion3Value: null,
  courseFormAccordion4Value: null,
};

export default function reducer(state = initialState, action) {
  if (action.type === "SET_SCHEDULE_MODAL_TRUE") {
    return { ...state, scheduleModal: action.payload };
  }
  if (action.type === "SET_SCHEDULE_MODAL_FALSE") {
    return { ...state, scheduleModal: action.payload };
  }
  if (action.type === "SET_APPROVAL_MODAL_TRUE") {
    return { ...state, approvalModal: action.payload };
  }
  if (action.type === "SET_APPROVAL_MODAL_FALSE") {
    return { ...state, approvalModal: action.payload };
  }
  if (action.type === "SET_DECLINE_MODAL_TRUE") {
    return { ...state, declineModal: action.payload };
  }
  if (action.type === "SET_DECLINE_MODAL_FALSE") {
    return { ...state, declineModal: action.payload };
  }
  if (action.type === "SET_ASSIGN_MENTOR_MODAL_TRUE") {
    return { ...state, assignmentMentorModal: action.payload };
  }
  if (action.type === "SET_ASSIGN_MENTOR_MODAL_FALSE") {
    return { ...state, assignmentMentorModal: action.payload };
  }
  if (action.type === "SET_ASSIGN_SCHEDULE_MODAL_TRUE") {
    return { ...state, assignmentScheduleModal: action.payload };
  }
  if (action.type === "SET_ASSIGN_SCHEDULE_MODAL_FALSE") {
    return { ...state, assignmentScheduleModal: action.payload };
  }
  if (action.type === "SET_INTERNAL_TABLE_VALUE") {
    return { ...state, internal_table_value: action.payload };
  }
  if (action.type === "SET_USER_ROLE") {
    return { ...state, userRole: action.payload };
  }
  if (action.type === "SET_IS_INTERNAL") {
    return { ...state, isInternal: action.payload };
  }
  if (action.type === "SET_USER_DETAIL") {
    return { ...state, userDetail: action.payload };
  }
  if (action.type === "SET_USER_LOGIN_OTP_TOKEN") {
    return { ...state, userLoginOtpToken: action.payload };
  }
  if (action.type === "SET_USERS_NOTIFICATIONS") {
    return { ...state, usersNotifications: action.payload };
  }
  if (action.type === "SET_SEARCH_KEY") {
    return { ...state, searchKey: action.payload };
  }
  if (action.type === "COURSE_FORM_ACCORDION_1_VALUE") {
    return { ...state, courseFormAccordion1Value: action.payload };
  }
  if (action.type === "COURSE_FORM_ACCORDION_2_VALUE") {
    return { ...state, courseFormAccordion2Value: action.payload };
  }
  if (action.type === "COURSE_FORM_ACCORDION_3_VALUE") {
    return { ...state, courseFormAccordion3Value: action.payload };
  }
  if (action.type === "COURSE_FORM_ACCORDION_4_VALUE") {
    return { ...state, courseFormAccordion4Value: action.payload };
  }
  return state;
}

// Method 2
// import { combineReducers } from "redux";
// import * as types from './types'

// const initialState = {
//     scheduleModal: false,
//     approvalModal: false
// };

// const schedule_modal_reducer = (state = initialState.scheduleModal, { type }) => {
//     switch(type) {
//         case types.SET_SCHEDULE_MODAL_TRUE === "SET_SCHEDULE_MODAL_TRUE":
//             return state = true;
//         case types.SET_SCHEDULE_MODAL_FALSE === "SET_SCHEDULE_MODAL_FALSE":
//             return state = false;
//         default:
//             return state;
//     }
// }

// const approval_modal_reducer = (state = initialState.approvalModal, { type }) => {
//     switch(type) {
//         case types.SET_APPROVAL_MODAL_TRUE === "SET_APPROVAL_MODAL_TRUE":
//             return state = true;
//         case types.SET_APPROVAL_MODAL_FALSE === "SET_APPROVAL_MODAL_FALSE":
//             return state = false;
//         default:
//             return state;
//     }
// }

// const reducers = {
//     scheduleModal: schedule_modal_reducer,
//     approvalModal: approval_modal_reducer
// }

// export default combineReducers(reducers)
