import React, { useEffect, useState } from "react";

import styled from "styled-components";

// lib
import fontSize from "../../../lib/styling/font_size";

// react bootstrap form
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputCourseLesson from "./InputCourseLesson";

const InputCourseSection = (props) => {
  const {
    counter,
    courseSections,
    handleOnInputCourseSectionChange,
    handleAddNewLesson,
    handleRemoveLesson,
    cancelUploadVideo,
    handleAddNewSection,
    isEditPage,
  } = props || {};

  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    if (
      courseSections &&
      courseSections[counter] &&
      courseSections[counter]["lessons"]
    ) {
      setLessons(courseSections[counter]["lessons"]);
    }
  }, [courseSections, courseSections[counter]]);

  if (courseSections && courseSections[counter]) {
    return (
      <div>
        {counter ? (
          <div
            style={{
              borderTop: "2px solid #007c7c",
              marginTop: "1.5em",
              marginBottom: "1.5em",
            }}
          />
        ) : null}
        <Col>
          <div className="userFormInputGroup">
            <Form.Group>
              <div
                style={{
                  marginBottom: "5px",
                  fontSize: "28px",
                  fontWeight: "bold",
                  color: "#007c7c",
                }}
              >{`Course section ${counter + 1}`}</div>
              <Input
                type="text"
                name={`course_section_${counter}`}
                placeholder="Enter course section here..."
                value={courseSections[counter]["course_section"]}
                onChange={({ target: { value } = {} }) =>
                  handleOnInputCourseSectionChange(
                    value,
                    counter,
                    "text",
                    "course_section"
                  )
                }
                maxLength={255}
                autoComplete="off"
                style={{
                  borderColor:
                    courseSections[counter]["invalid_course_section"] &&
                    !courseSections[counter]["course_section"]
                      ? "#EB4335"
                      : undefined,
                }}
              />
              {courseSections[counter]["invalid_course_section"] &&
              !courseSections[counter]["course_section"] ? (
                <div
                  style={{ color: "#EB4335", fontSize: 12, marginTop: "0.5em" }}
                >
                  This field is required
                </div>
              ) : null}
            </Form.Group>
          </div>
        </Col>
        <Col>
          <div className="userFormInputGroup">
            <Form.Group>
              <InputLabel>
                What will students be able to do at the end of this section?
              </InputLabel>
              <TextArea
                rows={3}
                id="courseName"
                name="courseName"
                autoComplete="off"
                value={
                  courseSections[counter][
                    "what_will_students_be_able_to_do_at_the_end_of_this_section"
                  ]
                }
                onChange={({ target: { value } = {} }) =>
                  handleOnInputCourseSectionChange(
                    value,
                    counter,
                    "text",
                    "what_will_students_be_able_to_do_at_the_end_of_this_section"
                  )
                }
                type="text"
                placeholder="Please enter learning objective here..."
                is_error={
                  courseSections[counter][
                    "invalid_what_will_students_be_able_to_do_at_the_end_of_this_section"
                  ] &&
                  !courseSections[counter][
                    "what_will_students_be_able_to_do_at_the_end_of_this_section"
                  ]
                }
              />
              {courseSections[counter][
                "invalid_what_will_students_be_able_to_do_at_the_end_of_this_section"
              ] &&
              !courseSections[counter][
                "what_will_students_be_able_to_do_at_the_end_of_this_section"
              ] ? (
                <div
                  style={{ color: "#EB4335", fontSize: 12, marginTop: "0.5em" }}
                >
                  This field is required
                </div>
              ) : null}
            </Form.Group>
          </div>
        </Col>
        {lessons &&
          Array.isArray(lessons) &&
          lessons.length > 0 &&
          lessons.map((lesson, idx) => {
            return (
              <Col key={idx}>
                <InputCourseLesson
                  lesson={lesson}
                  lessons={lessons}
                  counter={idx}
                  sectionCounter={counter}
                  handleOnInputCourseSectionChange={
                    handleOnInputCourseSectionChange
                  }
                  handleAddNewLesson={handleAddNewLesson}
                  handleRemoveLesson={handleRemoveLesson}
                  cancelUploadVideo={cancelUploadVideo}
                  isEditPage={isEditPage}
                  courseSections={courseSections}
                />
              </Col>
            );
          })}
        <AddCourseSection>
          <AddCourseSectionButton
            onClick={() =>
              courseSections[counter] &&
              courseSections[counter]["course_section"] &&
              courseSections[counter][
                "what_will_students_be_able_to_do_at_the_end_of_this_section"
              ]
                ? handleAddNewSection()
                : null
            }
            greenBtn={
              courseSections[counter] &&
              courseSections[counter]["course_section"] &&
              courseSections[counter][
                "what_will_students_be_able_to_do_at_the_end_of_this_section"
              ]
            }
          >
            <AddCourseSectionText>Add more section</AddCourseSectionText>
          </AddCourseSectionButton>
        </AddCourseSection>
      </div>
    );
  } else {
    return null;
  }
};

export default InputCourseSection;

const InputLabel = styled.label`
  margin-bottom: 5px;
  font-size: ${fontSize.title2};
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  border: 2px solid #00bb99;
  padding: 10px 15px;
  border-radius: 10px;
  :focus {
    outline: none;
    border-width: 3px;
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const TextArea = styled.textarea`
  background: ${({ isFilled, disabled }) =>
    isFilled || disabled ? "#D7FFFF" : "#FFFFFF"};
  width: 100%;
  border: ${({ is_error }) =>
    is_error ? "2px solid #EB4335" : "2px solid #00BB99"};
  padding: 10px 15px;
  border-radius: 10px;
  &:focus {
    outline: none;
    border-width: 3px;
  }
`;

const AddCourseSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 40px;
`;

const AddCourseSectionButton = styled.button`
  display: flex;
  background: ${({ greenBtn }) => (greenBtn ? "#00bb99" : "#cccccc")};
  border-radius: 7px;
  border: transparent;
  padding: 0.5em 1em;
  transition: 0.2s;
`;

const AddCourseSectionText = styled.span`
  color: #ffffff;
`;
