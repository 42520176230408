import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";

// date fns
import { format } from "date-fns";

// react-bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Apis
import { getAllTopics } from "../apis/topics";
import {
  getDetailInternalBooking,
  updateSession,
} from "../apis/internalApis/sessions";

// helpers
import getServerURL from "../helpers/get-server-url";

// sweetalert
import Swal from "sweetalert2";
import capitalizeFirstLetter from "../helpers/capitalize_first_letter";

// components
import {
  tabs,
  entreformInitialState,
} from "../components/coaching/book/bookingForm";
import SpinnerComponent from "../components/SpinnerComponent";
import HeaderComponent from "../components/HeaderComponent";
import SidebarComponent from "../components/SidebarComponent";
import StatusComponent from "../components/StatusComponent";
import EntrepreneurshipForm from "../components/coaching/book/EntrepreneurshipForm";
import RouteChangeModal from "../components/RouteChangeModal";
import LoadingModalMobile from "../components/LoadingModalMobile";

// react-responsive
import { useMediaQuery } from "react-responsive";

// helpers
import { device } from "../helpers/device";

// Images and Icons
import arrow_left_icon_dark from "../assets/icons/arrow_left_icon_dark.png";

const SessionDetail = () => {
  const { session_id } = useParams();
  const navigate = useNavigate();

  const userDetail = useSelector((state) => state.userDetail);

  const [session, setSession] = useState(null);
  const [topics, setTopics] = useState([]);

  const [topicTitle, setTopicTitle] = useState("");
  const [isTopicEntrepreneurship, setIsTopicEntrepreneurship] = useState(false);

  const [form, setForm] = useState(entreformInitialState);
  const [entreTopic, setEntreTopic] = useState(null);
  const [formDisabled, setFormDisabled] = useState(true);
  const [isRouteChange, setIsRouteChange] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isFormChanged, setFormChanged] = useState(false);

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  function handleChange(e, key) {
    setFormChanged(true);
    if (e.target.value !== "") {
      setForm({
        ...form,
        [key]: { ...form[key], value: e.target.value, error: false },
      });
    } else {
      if (form[key].required) {
        setForm({
          ...form,
          [key]: { ...form[key], value: e.target.value, error: true },
        });
      } else {
        setForm({
          ...form,
          [key]: { ...form[key], value: e.target.value, error: false },
        });
      }
    }
  }

  function handleChangeDropdown(value, key) {
    setFormChanged(true);
    setForm({ ...form, [key]: { ...form[key], value: value, error: false } });
  }

  function isEntreFormAllFilled(form) {
    let isFilled = true;
    Object.keys(form).forEach((key) => {
      if (form[key].required && form[key].value === "") {
        isFilled = false;
      }
    });
    return isFilled;
  }

  async function handleOnClicKEdit(e, index, formData) {
    e.stopPropagation();
    if (formDisabled) {
      setFormChanged(false);
      setFormDisabled((prev) => !prev);
    } else {
      if (isFormChanged) {
        if (isEntreFormAllFilled(form)) {
          setLoading(true);
          setFormDisabled((prev) => !prev);

          const status = await updateForm();
          if (status === 200) {
            Swal.fire({
              customClass: {
                popup: "mentor-popup",
              },
              imageUrl:
                "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
              position: "top-end",
              text: "Successfully updated session",
              width: "20em",
              heightAuto: "0.5em",
              showConfirmButton: false,
              timer: 1500,
            });
            setLoading(false);
            window.location.reload();
          }
        }
      } else {
        setFormDisabled((prev) => !prev);
      }
    }
  }

  function handleCancelButtonClicked() {
    setIsRouteChange(true);
  }

  async function handleButtonPrimaryRouteChange() {
    try {
      setLoading(true);
      const res = await updateSession(session.id, {
        ...session,
        status: "Cancelled",
      });
      if (res && res.status === 200) {
        Swal.fire({
          customClass: {
            popup: "mentor-popup",
          },
          imageUrl:
            "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
          position: "top-end",
          text: "Successfully cancelled session",
          width: "20em",
          heightAuto: "0.5em",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        window.location.reload();
      }
    } catch (error) {
      console.log(error, "error line 176 sessionDetail.js <<");
      setLoading(false);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.response.data.message.toString(),
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  function handleButtonSecondaryRouteChange() {
    setIsRouteChange(false);
  }

  function openDocument() {
    if (session && session.document) {
      const url = getServerURL();
      let docFilePath = session.document.replace(/^public\//, "");
      return window.open(`${url}/${docFilePath}`);
    }
  }

  async function updateForm() {
    setLoading(true);
    let data = {
      form_data: {
        current_challenges: "",
      },
    };

    if (form.topic.value === entreTopic.id) {
      data.topic_id = form.topic.value;
      Object.keys(form).forEach((v) => {
        if (v !== "topic" && v !== "id") {
          data.form_data[v] = form[v].value;
        }
      });
      try {
        const { updatedAt, ...sessionToSubmit } = session;
        const response = await updateSession(session.id, {
          ...sessionToSubmit,
          ...data,
        });
        Swal.fire({
          customClass: {
            popup: "mentor-popup",
          },
          imageUrl:
            "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
          position: "top-end",
          text: "Successfully updated session",
          width: "20em",
          heightAuto: "0.5em",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        return response.status;
      } catch (error) {
        console.log(error, "error line 245 sessionDetail.js <<");
        setLoading(false);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: error && error.toString(),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }

  const data = [
    {
      dataKey: "Booking ID",
      dataValue: session && session.booking_id ? session.booking_id : "-",
    },
    {
      dataKey: "Topic of interest",
      dataValue: topicTitle ? capitalizeFirstLetter(topicTitle) : "-",
    },
    {
      dataKey: "Session date/time",
      dataValue:
        session && session.scheduled_date
          ? format(new Date(session.scheduled_date), "dd/LL/yy HH:mm")
          : "-",
    },
    {
      dataKey: "Assigned mentor",
      dataValue:
        session && session.mentor && session.mentor.user_details
          ? `${session.mentor.user_details.first_name} ${session.mentor.user_details.last_name}`
          : "-",
    },
  ];

  const handleFetchTopics = async (topic_id) => {
    const result = await getAllTopics();
    if (result && result.data) {
      let finalTopics = result.data;
      setTopics(finalTopics);
      const finalTopicTitle = finalTopics.filter(
        (topic) => topic.id === topic_id
      )[0]?.title;
      setTopicTitle(finalTopicTitle);
      const isTopicEntrepreneurship =
        topic_id ===
        finalTopics.filter((topic) => topic.required_documents.length > 0)[0]
          ?.id;
      setIsTopicEntrepreneurship(isTopicEntrepreneurship);
      const topic = topics.filter(
        (topic) => topic.required_documents.length > 0
      );
      setEntreTopic(topic[0]);
      setLoading(false);
    }
  };

  const handleGetDetailSession = async (session_id) => {
    setLoading(true);
    const result = await getDetailInternalBooking(session_id);
    if (result && result.data) {
      const { data } = result || {};
      setSession(data.session);
      if (data.session.topic_id) {
        handleFetchTopics(data.session.topic_id);
      }
    }
  };

  useEffect(() => {
    if (session_id) {
      handleGetDetailSession(session_id);
    }
  }, [session_id]);

  useEffect(() => {
    if (session && isTopicEntrepreneurship) {
      let updatedState = form;

      Object.keys(form).forEach((key) => {
        if (key === "topic") {
          if (session && session.topic_id) {
            updatedState[key].value = session.topic_id;
          }
        } else {
          if (session && session.form_data) {
            updatedState[key].value = session.form_data[key];
          }
        }
      });
      setForm(updatedState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, isTopicEntrepreneurship]);

  return (
    <div
      style={{
        marginLeft: smallScreen ? "1.75em" : "",
        marginRight: smallScreen ? "1.75em" : "",
      }}
    >
      <HeaderComponent />
      <SidebarComponent />
      <OuterContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        <SpinnerComponent
          isShown={isLoading && !smallScreen && !mediumScreen}
          styles={{
            right: 0,
            left: 0,
            bottom: 0,
            top: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "290px",
          }}
        />
        {isLoading && (smallScreen || mediumScreen) ? (
          <LoadingModalMobile />
        ) : (
          <>
            {!isLoading && session ? (
              <InnerContainer>
                {smallScreen ? (
                  <>
                    {/* <div style={{ display: "flex", alignItems: "center" }}>
                  <BackNavigation onClick={() => navigate(-1)}>
                    <img
                      src={arrow_left_icon_dark}
                      alt="arrow_left_icon_dark"
                      height={13}
                      width={13}
                      style={{
                        objectFit: "scale-down",
                        objectPosition: "left bottom",
                      }}
                    />
                    {`    Back`}
                  </BackNavigation>
                </div> */}
                    <Col style={{ marginBottom: 40 }}>
                      {/* <Col style={{ marginBottom: 30 }}>
                    <PageTitle user_detail={userDetail}>
                      Coaching Session Details
                    </PageTitle>
                  </Col> */}
                      <Col style={{ display: "flex" }}>
                        <div>
                          <StatusComponent
                            status={session && session["status"]}
                            borderRadius="10px"
                            smallScreen={smallScreen}
                          />
                        </div>
                      </Col>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <BackNavigation onClick={() => navigate(-1)}>
                        <img
                          src={arrow_left_icon_dark}
                          alt="arrow_left_icon_dark"
                          height={13}
                          width={13}
                          style={{
                            objectFit: "scale-down",
                            objectPosition: "left bottom",
                          }}
                        />
                        {`    Back`}
                      </BackNavigation>
                    </div>
                    <Row style={{ marginBottom: 64 }}>
                      <Col>
                        <PageTitle user_detail={userDetail}>
                          Coaching Session Details
                        </PageTitle>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div>
                          <StatusComponent
                            status={session && session["status"]}
                            borderRadius="10px"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Row>
                  <Col>
                    {data.map(({ dataKey, dataValue }, index) => (
                      <Row key={index}>
                        <Label user_detail={userDetail}>{dataKey}</Label>
                        <Text>{dataValue ? dataValue : "-"}</Text>
                      </Row>
                    ))}
                    {isTopicEntrepreneurship && (
                      <Row>
                        <Label user_detail={userDetail}>
                          Document(s) uploaded
                        </Label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }}
                        >
                          <p
                            style={{
                              background: "#E5E5E5",
                              border: 0,
                              borderRadius: 10,
                              minWidth: smallScreen ? 255 : 275,
                              padding: "0.5rem",
                              margin: 0,
                              fontSize: 14,
                            }}
                          >
                            {session["document"]?.split("/")[2]}
                          </p>
                          <button
                            style={{
                              background: "transparent",
                              border: 0,
                              padding: "0.5rem",
                              margin: 0,
                              fontSize: 12,
                            }}
                            onClick={openDocument}
                          >
                            View File
                          </button>
                        </div>
                      </Row>
                    )}
                    <Row>
                      <Label user_detail={userDetail}>External Link</Label>
                      <Text>
                        {session && session.external_link
                          ? session.external_link
                          : "-"}
                      </Text>
                    </Row>
                    {session &&
                    session.status === "Completed" &&
                    smallScreen ? (
                      <Row>
                        <Label user_detail={userDetail}>
                          Feedback from Mentor
                        </Label>
                        <Text>
                          {session && session.feedback ? (
                            session.feedback
                          ) : (
                            <span style={{ color: "#C4C4C4" }}>
                              Will be available soon
                            </span>
                          )}
                        </Text>
                      </Row>
                    ) : null}
                  </Col>
                  <Col>
                    {session &&
                    session.status === "Completed" &&
                    !smallScreen ? (
                      <>
                        <Label user_detail={userDetail}>
                          Feedback from Mentor
                        </Label>
                        <Text>
                          {session && session.feedback ? (
                            session.feedback
                          ) : (
                            <span style={{ color: "#C4C4C4" }}>
                              Will be available soon
                            </span>
                          )}
                        </Text>
                      </>
                    ) : (
                      isTopicEntrepreneurship && (
                        <div style={{ padding: "0 2rem" }}>
                          <EntrepreneurshipForm
                            tabs={tabs}
                            form={form}
                            handleChange={handleChange}
                            inputBackgroundColor={
                              userDetail &&
                              userDetail.role &&
                              userDetail.role.id &&
                              +userDetail.role.id === 3
                                ? "#e7eeff"
                                : "rgba(0, 187, 153, 0.1)"
                            }
                            isSessionPage={true}
                            handleChangeDropdown={handleChangeDropdown}
                            handleOnClicKEdit={handleOnClicKEdit}
                            formDisabled={formDisabled}
                            disableEditSaveButton={!isEntreFormAllFilled(form)}
                            isFormChanged={isFormChanged}
                            smallScreen={smallScreen}
                            user_detail={userDetail}
                          />
                        </div>
                      )
                    )}
                  </Col>
                </Row>
                {session &&
                  session.status !== "Completed" &&
                  session.status !== "Declined" &&
                  session.status !== "Cancelled" && (
                    <Buttons smallScreen={smallScreen}>
                      <Button type="cancel" onClick={handleCancelButtonClicked}>
                        <div style={{ margin: "0.5em" }}>Cancel booking</div>
                      </Button>
                      {/* <Button type="reschedule">Request for reschedule</Button> */}
                    </Buttons>
                  )}
              </InnerContainer>
            ) : null}
          </>
        )}
      </OuterContainer>
      <RouteChangeModal
        show={isRouteChange}
        handleClose={handleButtonSecondaryRouteChange}
        body={[
          <p style={{ fontWeight: 700 }} key={0}>
            Are you sure you want to cancel this session?
          </p>,
        ]}
        closeButton={false}
        buttonPrimary="Yes"
        buttonSecondary="No"
        onPrimaryClick={handleButtonPrimaryRouteChange}
        onSecondaryClick={handleButtonSecondaryRouteChange}
      />
    </div>
  );
};

export default SessionDetail;

const OuterContainer = styled.div`
  padding-left: ${({ smallScreen, mediumScreen }) =>
    smallScreen || mediumScreen ? "0px" : "290px"};
  text-align: left;
  width: ${({ smallScreen, mediumScreen }) =>
    smallScreen || mediumScreen ? "100%" : ""};
`;

const InnerContainer = styled.div`
  padding: 80px 0 80px 0;
  @media ${device.tablet} {
    padding: 110px 4vw 100px 4vw;
  }
  @media ${device.laptop} {
    padding: 130px 4vw 100px 4vw;
  }
  @media ${device.laptopM} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopL} {
    padding: 130px 3vw 30px 3vw;
  }
`;

const PageTitle = styled.h1`
  font-size: 36px;
  color: ${({ user_detail }) =>
    user_detail &&
    user_detail.role &&
    user_detail.role.id &&
    +user_detail.role.id === 3
      ? "#2c65f7"
      : "#00bb99"};
`;

const Label = styled.h5`
  font-size: 14px;
  color: ${({ user_detail }) =>
    user_detail &&
    user_detail.role &&
    user_detail.role.id &&
    +user_detail.role.id === 3
      ? "#2c65f7"
      : "#00bb99"};
`;

const Text = styled.p`
  font-size: 18px;
  color: black;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ smallScreen }) => (smallScreen ? "" : "3rem")};
  margin-bottom: ${({ smallScreen }) => (smallScreen ? "3rem" : "")};
`;

const Button = styled.button`
  background: ${({ user_detail }) =>
    user_detail &&
    user_detail.role &&
    user_detail.role.id &&
    +user_detail.role.id === 3
      ? "#00bb99"
      : "#2c65f7"};
  color: white;
  border: 1;
  border-color: ${({ type }) =>
    type === "reschedule" ? "#00BB99" : "transparent"};
  border-radius: 40px;
  border-style: solid;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
`;

const BackNavigation = styled.p`
  padding: 0;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
`;
