import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// reducer
import {
  SET_IS_INTERNAL,
  SET_USER_DETAIL,
  SET_USER_ROLE,
} from "../../store/actions";

// Cookies
import Cookies from "js-cookie";

// Apis
import {
  checkUserSession,
  removeUserOtpToken,
} from "../../apis/authentications";

const LoginGuard = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("ut");
  const userOtpToken = Cookies.get("uto");

  const handleGetUsersProfile = async () => {
    try {
      const result = await checkUserSession();
      if (result && result.data) {
        const { data: { role } = {} } = result || {};
        dispatch(SET_USER_DETAIL(result.data));
        // Set user role
        dispatch(SET_USER_ROLE(role?.name));
        dispatch(SET_IS_INTERNAL(role && role.id && +role.id !== 3));
      } else {
        navigate("/login");
      }
    } catch (err) {
      console.log(err, "error line 40 LoginGuard.js <<");
      navigate("/login");
    }
  };

  const handleRemoveUserOtpToken = async () => {
    try {
      await removeUserOtpToken();
    } catch (err) {
      console.log(err, "error line 49 Loginguard.js <<");
    }
  };

  useEffect(() => {
    if (token) {
      handleGetUsersProfile();
    } else {
      navigate("/login");
    }
  }, [token]);

  useEffect(() => {
    if (userOtpToken) {
      handleRemoveUserOtpToken();
    }
  }, [userOtpToken]);

  if (!token) {
    navigate("/login");
  } else {
    return children;
  }
};

export default LoginGuard;
