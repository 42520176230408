import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoute = ({ requiredRole, userLoginRoleId }) => {
  if (
    requiredRole &&
    Array.isArray(requiredRole) &&
    requiredRole.length > 0 &&
    userLoginRoleId &&
    requiredRole.includes(+userLoginRoleId) === false
  ) {
    // Redirect to a forbidden page or a different route if the user doesn't have the required role
    const userRole = +userLoginRoleId;
    if (userRole !== 3) {
      return <Navigate to="/internal/dashboard" />;
    } else {
      return <Navigate to="/" />;
    }
  } else {
    return <Outlet />;
  }
};

export default PrivateRoute;
