import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarComponent from "../components/SidebarComponent";
import SortModal from "../components/SortModal";
import Pagination from "../components/layout/Pagination";
import TableComponent from "../components/TableComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import FilterModal from "../components/FilterModal";
import FeedbackModalComponent from "../components/FeedbackModalComponent";
import StatusComponent from "../components/StatusComponent";
import GreenButton from "../components/button/GreenButton";
import LoadingModalMobile from "../components/LoadingModalMobile";

// Styles
import "../styles/sessionHistory.css";

// Images & Icons
import empty_session_2_icon from "../assets/icons/empty_session_2_icon.svg";
import sort_icon from "../assets/icons/sort_icon.svg";
import filter_icon from "../assets/icons/filter_icon.svg";
import eye_icon from "../assets/icons/eye_icon.svg";

// Apis
import { getAllUsersSessions } from "../apis/clientApis/sessions";
import { getAllTopics } from "../apis/topics";

// helpers
import { device } from "../helpers/device";
import fontSize from "../helpers/fontSize";

// react-responsive
import { useMediaQuery } from "react-responsive";

// utils
import { dateFormat } from "../utils/common";

// sweetalert
import Swal from "sweetalert2";

// moment.js
import moment from "moment";

const SessionHistory = () => {
  const navigate = useNavigate();

  const userDetail = useSelector((state) => state.userDetail);
  const searchKey = useSelector((state) => state.searchKey);

  const [isLoading, setIsLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [topics, setTopics] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);

  const [perPage, setPerPage] = useState(15);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [isSortModalOpened, setSortModalOpened] = useState(false);
  const [isFilterModalOpened, setFilterModalOpened] = useState(false);
  const [isFilterChanged, setFilterChanged] = useState(false);

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [dataForFeedback, setDataForFeedback] = useState(null);

  const sortKeyInitialState = {
    newest: false,
    oldest: false,
  };

  const [sortKey, setSortKey] = useState(sortKeyInitialState);

  const filterKeyInitialState = {
    status: [],
    topic: [],
    dateTime: {},
    mentor: [],
  };

  const statusList = ["Completed", "Declined", "Cancelled"];

  const [filterKey, setFilterKey] = useState(filterKeyInitialState);

  const initialDataState = sessions
    .sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    })
    .map(
      ({
        booking_id,
        topic_id,
        scheduled_date,
        mentor,
        feedback,
        status,
        user_id,
        createdAt,
      }) => {
        const assignedMentor = mentor
          ? mentor?.user_details?.first_name +
            " " +
            mentor?.user_details?.last_name
          : "-";

        return {
          booking_id,
          topic_id,
          scheduled_date,
          assignedMentor,
          feedback,
          status,
          user_id,
          createdAt,
        };
      }
    )
    .filter(
      (d) =>
        d.status === "Completed" ||
        d.status === "Declined" ||
        d.status === "Cancelled"
    );

  function handlePageClick(e) {
    const newOffset = (e.selected * perPage) % data.length;
    setItemOffset(newOffset);
  }

  useEffect(() => {
    const endOffset = itemOffset + perPage;
    setPaginatedData(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, itemOffset, perPage]);

  useEffect(() => {
    setSortKey((prev) => ({ ...prev, newest: true, oldest: false }));
  }, []);

  function handleOnClickSortButton() {
    setSortModalOpened(true);
  }

  function handleOnClickRow(d, key, value) {
    if (key !== "feedback") {
      if (key === "status" && value === "Complete your data") {
        navigate(`/coaching/book`);
      } else {
        navigate(`/session/${d["booking_id"]}`);
      }
    }
  }

  function handleOnSort(type) {
    setIsLoading(true);
    switch (type) {
      case "newest":
        const sortedData = initialDataState.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setData(sortedData);
        setSortKey((prev) => ({ ...prev, newest: true, oldest: false }));
        break;
      case "oldest":
        const sortedData2 = initialDataState.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        setData(sortedData2);
        setSortKey((prev) => ({ ...prev, newest: false, oldest: true }));
        break;
      default:
        break;
    }
    setSortModalOpened(false);
    setIsLoading(false);
  }

  const handleFetchUsersSessions = async (userId, searchKey) => {
    setIsLoading(true);
    const result = await getAllUsersSessions(userId);
    if (result && result.data) {
      setSessions(result.data);
      let finalMentorList = [];

      let initialDataState = result.data
        .sort((a, b) => {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        })
        .map(
          ({
            booking_id,
            topic_id,
            scheduled_date,
            mentor,
            feedback,
            status,
            user_id,
            createdAt,
          }) => {
            const assignedMentor = mentor
              ? mentor?.user_details?.first_name +
                " " +
                mentor?.user_details?.last_name
              : "-";
            let finalTopicId = topics.filter((topic) => topic_id === topic.id);
            return {
              booking_id,
              topic_id:
                finalTopicId && finalTopicId[0] && finalTopicId[0].title
                  ? finalTopicId[0].title
                  : topic_id,
              scheduled_date,
              assignedMentor,
              feedback,
              status,
              user_id,
              createdAt,
            };
          }
        )
        .filter(
          (d) =>
            d.status === "Completed" ||
            d.status === "Declined" ||
            d.status === "Cancelled"
        );

      initialDataState.forEach((booking) => {
        const { assignedMentor } = booking;
        if (
          assignedMentor &&
          assignedMentor !== "-" &&
          finalMentorList.includes(assignedMentor) === false
        ) {
          finalMentorList.push(assignedMentor);
        }
      });

      setMentors(finalMentorList);

      if (searchKey) {
        initialDataState = initialDataState.filter(
          (data) =>
            data.booking_id.toLowerCase().includes(searchKey.toLowerCase()) ||
            data.assignedMentor
              .toLowerCase()
              .includes(searchKey.toLowerCase()) ||
            data.topic_id
              .toString()
              .toLowerCase()
              .includes(searchKey.toLowerCase())
        );
      }

      setData(initialDataState);
      const endOffset = itemOffset + perPage;
      setPaginatedData(initialDataState.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / perPage));
      setIsLoading(false);
    }
  };

  const handleFetchTopics = async () => {
    const result = await getAllTopics();
    if (result && result.data) {
      setTopics(result.data);
    }
  };

  function handleOnClickFilterButton() {
    setFilterModalOpened(true);
  }

  function handleApplyFilter() {
    if (
      Object.keys(filterKey).some(
        (key) =>
          filterKey[key].length > 0 ||
          (filterKey[key].from && filterKey[key].to)
      )
    ) {
      const filteredData = initialDataState.filter((d) => {
        let isValid = true;
        if (filterKey.status.length > 0) {
          isValid = filterKey.status.includes(d.status);
        }
        if (filterKey.topic.length > 0) {
          isValid = filterKey.topic.includes(d.topic_id.toString());
        }
        if (filterKey.mentor.length > 0) {
          isValid = filterKey.mentor.includes(d.assignedMentor);
        }
        if (filterKey.dateTime.from && filterKey.dateTime.to) {
          let date = moment(new Date(d.scheduled_date));
          let from = moment(new Date(filterKey.dateTime.from));
          let to = moment(new Date(filterKey.dateTime.to)).add(1, "days");

          isValid = moment(date).isBetween(from, to);
        }
        return isValid;
      });
      setData(filteredData);
    } else {
      setData(initialDataState);
    }
    setFilterChanged(false);
  }

  function handleOnFilter(type, name, value) {
    setFilterChanged(true);
    switch (type) {
      case "status":
        if (value) {
          setFilterKey((prev) => ({ ...prev, status: [...prev.status, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            status: prev.status.filter((d) => d !== name),
          }));
        }
        break;
      case "topic":
        if (value) {
          setFilterKey((prev) => ({ ...prev, topic: [...prev.topic, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            topic: prev.topic.filter((d) => d !== name),
          }));
        }
        break;
      case "date-time":
        if (value) {
          setFilterKey((prev) => ({ ...prev, dateTime: value }));
        }
        break;
      case "mentor":
        if (value) {
          setFilterKey((prev) => ({ ...prev, mentor: [...prev.mentor, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            mentor: prev.mentor.filter((d) => d !== name),
          }));
        }
        break;
      case "reset":
        setFilterKey(filterKeyInitialState);
        break;
      case "apply-filter":
        handleApplyFilter();
        setFilterModalOpened(false);
        break;
      default:
        break;
    }
  }

  const handleFilterBySearchKey = (searchKey) => {
    if (!searchKey) {
      handleFetchUsersSessions(userDetail.id);
    } else {
      handleFetchUsersSessions(userDetail.id, searchKey);
    }
  };

  function handleOnClickFeedback(data) {
    setFeedbackModalOpen(true);
    setDataForFeedback(data);
  }

  function handleCloseFeedbackModal() {
    setFeedbackModalOpen(false);
  }

  const handleOnClickBtnBookSession = (userDetail) => {
    if (userDetail && userDetail.id) {
      const { is_validated, is_new_user, user_details } = userDetail || {};
      if (is_validated) {
        if (
          !is_new_user &&
          user_details &&
          user_details.first_name &&
          user_details.last_name &&
          user_details.gender &&
          user_details.phone_number
        ) {
          navigate("/coaching/book");
        } else {
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            position: "top-end",
            icon: "error",
            title: "Please fill your profile first",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          customClass: {
            popup: "mentor-popup",
          },
          position: "top-end",
          icon: "error",
          title: "Please verify your account first",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        customClass: {
          popup: "mentor-popup",
        },
        position: "top-end",
        icon: "error",
        title: "Please login first",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    if (userDetail && userDetail.id) {
      handleFetchUsersSessions(userDetail.id);
      handleFetchTopics();
    }
  }, [userDetail]);

  useEffect(() => {
    handleFilterBySearchKey(searchKey);
  }, [searchKey]);

  return (
    <div
      style={{
        marginLeft: smallScreen ? "1em" : "",
        marginRight: smallScreen ? "1em" : "",
      }}
    >
      <HeaderComponent />
      <SidebarComponent />
      <SpinnerComponent
        isShown={isLoading && !smallScreen && !mediumScreen}
        styles={{
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "290px",
        }}
      />
      {isLoading && (smallScreen || mediumScreen) ? (
        <LoadingModalMobile />
      ) : (
        <>
          {!isLoading && (
            <SessionContainer>
              <MyBookingInnerContainer className="myBookingInnerContainer">
                <ButtonContainer>
                  <FilterSortButton onClick={handleOnClickSortButton}>
                    <img
                      src={sort_icon}
                      alt="sort_icon"
                      height={15}
                      width={15}
                      style={{ objectFit: "scale-down" }}
                    />
                    Sort
                  </FilterSortButton>
                  <FilterSortButton onClick={handleOnClickFilterButton}>
                    <img
                      src={filter_icon}
                      alt="filter_icon"
                      height={15}
                      width={15}
                      style={{ objectFit: "scale-down" }}
                    />
                    Filter
                  </FilterSortButton>
                </ButtonContainer>
                <FilterModal
                  show={isFilterModalOpened.toString()}
                  handleClose={() => {
                    setFilterModalOpened(false);
                    setFilterChanged(false);
                  }}
                  topics={topics}
                  mentorList={mentors}
                  filterKey={filterKey}
                  statusList={statusList}
                  handleOnFilter={handleOnFilter}
                  dateTimeButtonList={["This Week", "This Month"]}
                  isFilterChanged={isFilterChanged}
                  user_detail={userDetail}
                  filterStatus={true}
                  filterTopic={true}
                  filterDateTime={true}
                  filterMentor={true}
                  smallScreen={smallScreen}
                />
                <SortModal
                  show={isSortModalOpened.toString()}
                  handleClose={() => {
                    setSortModalOpened(false);
                  }}
                  handleOnSort={handleOnSort}
                  sortKey={sortKey}
                  user_detail={userDetail}
                />
                {smallScreen ? (
                  <GreenButton
                    width={"100%"}
                    height={"40px"}
                    textSize={fontSize.title4}
                    text="Book a session"
                    action={() => handleOnClickBtnBookSession(userDetail)}
                    cursor="pointer"
                    style={{ marginTop: "1em" }}
                    bgcolor={"#2c65f7"}
                  />
                ) : null}
                <Pagination
                  handlePageClick={handlePageClick}
                  pageCount={pageCount}
                  itemOffset={itemOffset + 1}
                  endOffset={itemOffset + perPage}
                  totalItems={initialDataState.length}
                  smallScreen={smallScreen}
                  hide={data.length <= perPage}
                >
                  {paginatedData &&
                  Array.isArray(paginatedData) &&
                  paginatedData.length > 0 ? (
                    <div>
                      {smallScreen ? (
                        <div>
                          {paginatedData.map((data) => {
                            const {
                              id,
                              booking_id,
                              topic_id,
                              scheduled_date,
                              assignedMentor,
                              status,
                            } = data || {};
                            return (
                              <div key={id}>
                                <EachMobileDataMainContainer
                                  onClick={() =>
                                    handleOnClickRow(data, "detail-session")
                                  }
                                >
                                  <EachMobileDataContainer>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "0.5em",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "0.5em",
                                          width: "50%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "10px",
                                          }}
                                        >
                                          Booking ID:
                                        </div>
                                        <div style={{ fontSize: "10px" }}>
                                          {booking_id}
                                        </div>
                                      </div>
                                      <div
                                        style={{ display: "flex", gap: "1em" }}
                                      >
                                        <StatusComponent
                                          status={status}
                                          border_radius="40px"
                                          fontSize="10px"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{ display: "flex", gap: "0.5em" }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Description:
                                      </div>
                                      <div style={{ fontSize: "10px" }}>
                                        {topic_id}
                                      </div>
                                    </div>
                                    <div
                                      style={{ display: "flex", gap: "0.5em" }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Session date/time:
                                      </div>
                                      <div style={{ fontSize: "10px" }}>
                                        {scheduled_date
                                          ? dateFormat(scheduled_date)
                                          : "Not available"}
                                      </div>
                                    </div>
                                    <div
                                      style={{ display: "flex", gap: "0.5em" }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Assigned mentor:
                                      </div>
                                      <div style={{ fontSize: "10px" }}>
                                        {assignedMentor || "Not available"}
                                      </div>
                                    </div>
                                    <div
                                      style={{ display: "flex", gap: "0.5em" }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Feedback:
                                      </div>
                                      <div
                                        onClick={() =>
                                          handleOnClickFeedback(data)
                                        }
                                        style={{
                                          display: "flex",
                                          gap: "0.25rem",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={eye_icon}
                                          alt="eye_icon"
                                          width={15}
                                          height={15}
                                        />
                                        <div style={{ fontSize: "11px" }}>
                                          View
                                        </div>
                                      </div>
                                    </div>
                                  </EachMobileDataContainer>
                                </EachMobileDataMainContainer>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <TableComponent
                          table_data={paginatedData}
                          topics={topics}
                          onClickRow={handleOnClickRow}
                          user_detail={userDetail}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="sessionHistoryInnerContainerEmptySession">
                      <img
                        src={empty_session_2_icon}
                        alt="empty_session_2_icon"
                      />
                      <h4 style={{ fontSize: "22px" }}>
                        No Booking Session found.
                      </h4>
                      {/* <p style={{ fontSize: "18px" }}>
                    Only completed booking session will appear here
                  </p> */}
                    </div>
                  )}
                </Pagination>
              </MyBookingInnerContainer>
              {isFeedbackModalOpen ? (
                <FeedbackModalComponent
                  show={isFeedbackModalOpen}
                  handleClose={handleCloseFeedbackModal}
                  booking_id={dataForFeedback["booking_id"]}
                  mentor_name={dataForFeedback["assignedMentor"]}
                  topic={dataForFeedback["topic_id"]}
                  body={[dataForFeedback["feedback"]]}
                  smallScreen={smallScreen}
                />
              ) : null}
            </SessionContainer>
          )}
        </>
      )}
    </div>
  );
};

export default SessionHistory;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FilterSortButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: black solid 1px;
  background-color: #f0f8ff;
  color: black;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  user-select: none;
  border-radius: 10px;
  :hover {
    filter: brightness(95%);
  }
  z-index: ${({ show }) => (show ? "2000" : "1")};
`;

const SessionContainer = styled.div`
  @media ${device.laptopM} {
    padding-left: 290px;
  }
  @media ${device.laptopL} {
    padding-left: 290px;
  }
`;

const MyBookingInnerContainer = styled.div`
  padding: 80px 3vw 90px 3vw;
  text-align: left;
  @media ${device.tablet} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptop} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptopM} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopL} {
    padding: 130px 3vw 30px 3vw;
  }
`;

const EachMobileDataMainContainer = styled.div`
  border-radius: 10px;
  width: 100%;
  border: 1px solid #2c65f7;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const EachMobileDataContainer = styled.div`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
`;
