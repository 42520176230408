import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

// user login pages
import userLoginRoutes from "../userLoginRoutes";
import PrivateRoute from "./PrivateRoute";
import { useSelector } from "react-redux";

const AppContent = () => {
  const userLoginDetail = useSelector((state) => state.userDetail);

  if (userLoginDetail && userLoginDetail.role_id) {
    return (
      <Suspense>
        <Routes>
          {userLoginRoutes.map((route, idx) => {
            const { requiredRole } = route || {};
            return (
              <Route
                key={idx}
                exact
                path={route.path}
                element={
                  <PrivateRoute
                    requiredRole={requiredRole}
                    userLoginRoleId={userLoginDetail.role_id}
                  />
                }
              >
                <Route
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              </Route>
            );
          })}
        </Routes>
      </Suspense>
    );
  } else {
    return null;
  }
};

export default AppContent;
