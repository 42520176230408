import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
// Styled components
import styled from "styled-components";

// Font size helpers
import fontSize from "../lib/styling/font_size";

// Helpers
import getServerURL from "../helpers/get-server-url";

// Icons & Images
import download_icon from "../assets/icons/download_icon.svg";
import trash_icon from "../assets/icons/trash_icon.svg";
import file_green_icon from "../assets/icons/file_green_icon.png";
import file_icon from "../assets/icons/file_icon.png";
import eye_icon from "../assets/icons/eye_icon.svg";

function FileUpload({ files, setFiles, uploadKey, session }) {
  const maxFiles = 1;
  const minSize = 0;
  const maxSize = 5242880;
  const acceptedFiles = ".doc, .docx, .pdf, .zip, .rar";

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Set file to state on drop
      let newFiles = JSON.parse(JSON.stringify(files));
      newFiles = {
        ...newFiles,
        [uploadKey]: acceptedFiles,
      };
      setFiles(newFiles);
    },
    [files, setFiles, uploadKey]
  );

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    draggedFiles,
    fileRejections,
    getInputProps,
    getRootProps,
  } = useDropzone({
    onDrop,
    maxFiles,
    minSize,
    maxSize,
    accept: acceptedFiles,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        {errors.map((e, index) => (
          <ErrorMessage key={index}>{e.message}</ErrorMessage>
        ))}
      </li>
    );
  });

  function handleChange() {}

  function handleDelete() {
    setFiles({});
  }

  // Check platform to open document
  function openDocument() {
    if (session && session.document) {
      const url = getServerURL();
      let docFilePath = session.document.replace(/^public\//, "");
      return window.open(`${url}/${docFilePath}`);
    }
  }

  function renderFileComponents() {
    // On drag and drop only
    if (isDragActive && isDragAccept) {
      // TODO: Render if file is on dragging process
      return (
        <Container
          isActive={isDragActive}
          isReject={isDragReject}
          {...getRootProps()}
        >
          <NoFileContainer>
            <input {...getInputProps()} type="file" accept={acceptedFiles} />
            <div>
              <Text>Drop your file here</Text>
            </div>
          </NoFileContainer>
        </Container>
      );
    } else if (isDragActive && isDragReject) {
      // Render on dropzone reject the files
      if (draggedFiles.length > 1) {
        // Reject on file more than one
        return (
          <Container
            isActive={isDragActive}
            isReject={isDragReject}
            {...getRootProps()}
          >
            <NoFileContainer>
              <input {...getInputProps()} type="file" accept={acceptedFiles} />
              <div>
                <Text color="red">
                  You cannot upload more than 1 file on this section
                </Text>
              </div>
            </NoFileContainer>
          </Container>
        );
      } else {
        // Reject on file not supported
        return (
          <Container
            isActive={isDragActive}
            isReject={isDragReject}
            {...getRootProps()}
          >
            <NoFileContainer>
              <input {...getInputProps()} type="file" accept={acceptedFiles} />
              <div>
                <Text color="red">File not supported</Text>
              </div>
            </NoFileContainer>
          </Container>
        );
      }
    } else if (
      (files && files[uploadKey] && files[uploadKey].length > 0) ||
      (session && session.document)
    ) {
      // Render files on drop
      return (
        <FileContainer>
          {files && files[uploadKey] && files[uploadKey].length > 0 ? (
            files[uploadKey].map((file, index) => {
              return (
                <div key={index}>
                  <FileThumbnail
                    src={file_icon}
                    alt="file_icon"
                    width="40px"
                    height="40px"
                  />
                  <Text>{file.name}</Text>
                  <SubtitleText>
                    (file size: {file.size / 1000000} MB)
                  </SubtitleText>
                </div>
              );
            })
          ) : (
            <div>
              <FileThumbnail
                src={file_icon}
                alt="file_icon"
                width="40px"
                height="40px"
              />
              <div style={{ display: "flex", gap: "0.5em" }}>
                <Text>{`${session.booking_id}'s document`}</Text>
                <ButtonPrimary onClick={openDocument}>
                  <img src={eye_icon} alt="eye_icon" width={15} height={15} />
                  <div style={{marginLeft: '2px'}}>View File</div>
                </ButtonPrimary>
              </div>
            </div>
          )}
          <ButtonGroup>
            <div
              isActive={isDragActive}
              isReject={isDragReject}
              {...getRootProps()}
            >
              <ButtonPrimary onClick={handleChange}>
                <input
                  {...getInputProps()}
                  type="file"
                  accept={acceptedFiles}
                />
                <img
                  src={download_icon}
                  alt="download_icon"
                  height={15}
                  width={15}
                  style={{ objectFit: "scale-down" }}
                />
                Change
              </ButtonPrimary>
            </div>
            <ButtonSecondary onClick={handleDelete}>
              <img
                src={trash_icon}
                alt="trash_icon"
                height={15}
                width={15}
                style={{ objectFit: "scale-down" }}
              />
              Delete
            </ButtonSecondary>
          </ButtonGroup>
        </FileContainer>
      );
    } else {
      // TODO: Render on dropzone idle
      return (
        <Container
          isActive={isDragActive}
          isReject={isDragReject}
          {...getRootProps()}
        >
          <NoFileContainer>
            <input {...getInputProps()} type="file" accept={acceptedFiles} />
            <div>
              <FileIcon
                src={file_green_icon}
                alt="file_green_icon"
                width="60px"
                height="48px"
              />
              <Text>
                Drag and drop your file here
                <br />
                (.zip, .doc, .docx, .pdf, .rar)
              </Text>
            </div>
          </NoFileContainer>
        </Container>
      );
    }
  }

  return (
    <>
      {renderFileComponents()}
      <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
        {fileRejectionItems}
      </ul>
    </>
  );
}

export default FileUpload;

const Container = styled.div`
  height: 160px;
  border-color: ${({ isActive, isReject }) =>
    !isActive ? "#00BB99" : isActive && !isReject ? "green" : "red"};
`;
const FileContainer = styled.div`
  border: 2px solid #00bb99;
  padding: 10px 15px;
  border-radius: 10px;
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
`;
const NoFileContainer = styled.div`
  border: 2px solid #00bb99;
  padding: 10px 15px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
`;
const FileIcon = styled.img`
  object-fit: contain;
  margin-bottom: 10px;
`;
const Text = styled.div`
  font-size: ${fontSize.title4};
  font-weight: 500;
  color: ${({ color }) => color || "black"};
`;
const SubtitleText = styled.div`
  font-size: 9px;
  color: #aaaaaa;
`;
const FileThumbnail = styled.img`
  object-fit: contain;
  margin-bottom: 10px;
`;
const ErrorMessage = styled.p`
  font-size: 12px;
  font-weight: normal;
  color: #eb4335;
  padding: 0;
  margin: 0;
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  justify-content: center;
  z-index: 3000;
  padding: 10px;
  padding-bottom: 0;
`;

const ButtonPrimary = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #00bb99;
  cursor: pointer;
  :hover {
    font-weight: bold;
  }
`;

const ButtonSecondary = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  :hover {
    font-weight: bold;
  }
`;
