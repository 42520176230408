import Axios from "../helpers/axios";

export const saveFileVideoToServer = (body) => {
  return Axios.post(`/files/upload/video`, body);
};

export const saveFileToServer = (body) => {
  return Axios.post(`/files/upload/file`, body);
};

export const createVideoToGetVideoId = (body) => {
  return Axios.post(`/files/create/video`, body);
};
