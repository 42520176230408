import React, { Suspense, useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// Styles
import "./App.css";
import "./styles/globals.css";
import "./styles/DatePicker.css";
import "./styles/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-day-picker/dist/style.css";
import "suneditor/dist/css/suneditor.min.css";

// Pages
import Login from "./pages/login";
import Register from "./pages/register";
import VerifyAccountRegistered from "./pages/verifyAccountRegistered";
import LoginVerification from "./pages/loginVerification";
import DefaultLayout from "./components/layout/DefaultLayout";
import TermsOfService from "./pages/termOfServicePage";
import PrivacyPolicy from "./pages/privacyPolicyPage";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import CheckInternetConnection from "./components/CheckInternetConnection";

// sweetalert
import Swal from "sweetalert2";

// socket.io
import { io } from "socket.io-client";

// react notification toast
import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

// helpers
import getServerURL from "./helpers/get-server-url";

// Components
import MobileBottomNavigationBar from "./components/mobileBottomNavigationBar";
// import MobileFloatingButton from "./components/button/MobileFloatingButton";

const socket = io(getServerURL());

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const userDetail = useSelector((state) => state.userDetail);

  window.addEventListener("beforeunload", function (event) {
    if (location) {
      const { pathname } = location || {};
      if (
        pathname.includes("/internal/courses/add") ||
        pathname.includes("/internal/courses/edit")
      ) {
        const message =
          "Are you sure you want to leave? Any unsaved data will be lost.";
        event.returnValue = message;
        return message;
      }
    }
  });

  const showPopUpNotification = (userDetail, data, isBanner) => {
    let showNotificationPopUp = data.recipientIds.some(
      (recipientId) => recipientId === userDetail.id
    );
    if (showNotificationPopUp) {
      if (isBanner) {
        // handle upload file course notification
        Store.addNotification({
          title: `${
            data.notification.type === "success"
              ? "Upload File Success"
              : "Upload File Failed"
          }!`,
          message: data.notification.text,
          type: data.notification.type === "success" ? "success" : "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      } else {
        Swal.fire({
          customClass: {
            popup: "mentor-popup",
          },
          position: "top-end",
          icon: "info",
          title: "You got a new notification",
          text: data.notification.title,
          showConfirmButton: true,
        });
      }
    }
  };

  const handleOnClickBtnBookSession = (userDetail) => {
    if (userDetail && userDetail.id) {
      const { is_validated, is_new_user, user_details } = userDetail || {};
      if (is_validated) {
        if (
          !is_new_user &&
          user_details &&
          user_details.first_name &&
          user_details.last_name &&
          user_details.gender &&
          user_details.phone_number
        ) {
          navigate("/coaching/book");
        } else {
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            position: "top-end",
            icon: "error",
            title: "Please fill your profile first",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          customClass: {
            popup: "mentor-popup",
          },
          position: "top-end",
          icon: "error",
          title: "Please verify your account first",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        customClass: {
          popup: "mentor-popup",
        },
        position: "top-end",
        icon: "error",
        title: "Please login first",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    socket.on("new-notification-user-register-for-admin", (data) => {
      if (
        data &&
        data.recipientIds &&
        userDetail &&
        userDetail.id &&
        userDetail.role &&
        userDetail.role.id &&
        (userDetail.role.id === 1 || userDetail.role.id === 2) // for admin only
      ) {
        showPopUpNotification(userDetail, data);
      }
    });
    return () => socket.off("new-notification-user-register-for-admin");
  }, [userDetail]);

  useEffect(() => {
    socket.on(
      "new-notification-success-verify-and-assign-role-to-new-user-for-admin",
      (data) => {
        if (
          data &&
          data.recipientIds &&
          userDetail &&
          userDetail.id &&
          userDetail.role &&
          userDetail.role.id &&
          (userDetail.role.id === 1 || userDetail.role.id === 2) // for admin only
        ) {
          showPopUpNotification(userDetail, data);
        }
      }
    );
    return () =>
      socket.off(
        "new-notification-success-verify-and-assign-role-to-new-user-for-admin"
      );
  }, [userDetail]);

  useEffect(() => {
    socket.on(
      "new-notification-success-assign-role-to-new-user-for-admin",
      (data) => {
        if (
          data &&
          data.recipientIds &&
          userDetail &&
          userDetail.id &&
          userDetail.role &&
          userDetail.role.id &&
          (userDetail.role.id === 1 || userDetail.role.id === 2) // for admin only
        ) {
          showPopUpNotification(userDetail, data);
        }
      }
    );
    return () =>
      socket.off("new-notification-success-assign-role-to-new-user-for-admin");
  }, [userDetail]);

  useEffect(() => {
    socket.on("new-notification-new-role-assigned-for-user", (data) => {
      if (
        data &&
        data.recipientIds &&
        userDetail &&
        userDetail.id &&
        userDetail.role &&
        userDetail.role.id
      ) {
        showPopUpNotification(userDetail, data);
      }
    });
    return () => socket.off("new-notification-new-role-assigned-for-user");
  }, [userDetail]);

  useEffect(() => {
    socket.on(
      "upload-file-notification-to-user",
      (data) => {
        if (data && data.recipientIds && userDetail && userDetail.id) {
          showPopUpNotification(userDetail, data, true);
        }
      }
    );
    return () =>
      socket.off("upload-file-notification-to-user");
  }, [userDetail]);

  return (
    <div className="App">
      {userDetail && userDetail.id && <ReactNotifications />}
      {userDetail &&
        userDetail.id &&
        userDetail.role &&
        userDetail.role.id &&
        location &&
        location.pathname && (
          <MobileBottomNavigationBar userDetail={userDetail} />
        )}
      <Suspense>
        <Routes>
          <Route path="/register" name="Register page" element={<Register />} />
          <Route
            path="/verify"
            name="Verify Account Registered page"
            element={<VerifyAccountRegistered />}
          />
          <Route path="/login" name="Login page" element={<Login />} />
          <Route
            path="/login-verification"
            name="Login Verification page"
            element={<LoginVerification />}
          />
          <Route
            path="/forgot-password"
            name="Forgot password page"
            element={<ForgotPassword />}
          />
          <Route
            path="/reset-password/:url_code"
            name="Reset password page"
            element={<ResetPassword />}
          />
          <Route
            path="/term-of-services"
            name="Term of services page"
            element={<TermsOfService />}
          />
          <Route
            path="/privacy-policy"
            name="Privacy policy page"
            element={<PrivacyPolicy />}
          />
          <Route path="*" name="User login pages" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
      <CheckInternetConnection />
      {/* {userDetail &&
        userDetail.id &&
        userDetail.role &&
        userDetail.role.id &&
        userDetail.role.id === 3 &&
        location &&
        location.pathname &&
        location.pathname.includes("/coaching/book") === false && (
          <MobileFloatingButton
            handleOnClickBtnBookSession={() =>
              handleOnClickBtnBookSession(userDetail)
            }
          />
        )} */}
    </div>
  );
}

export default App;
