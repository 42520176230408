import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

// date-fns
import { format } from "date-fns";

// react responsive
import { useMediaQuery } from "react-responsive";

// reducer
import {
  SET_APPROVAL_MODAL_FALSE,
  SET_ASSIGN_MENTOR_MODAL_FALSE,
  SET_ASSIGN_SCHEDULE_MODAL_FALSE,
  SET_DECLINE_MODAL_FALSE,
  SET_INTERNAL_TABLE_VALUE,
  SET_SCHEDULE_MODAL_FALSE,
} from "../store/actions";

// lib
import fontSize from "../lib/styling/font_size";

// images & icons
import assigned_mentor_icon from "../assets/icons/assigned_mentor_icon.png";
import add_mentor_icon from "../assets/icons/add_mentor_icon.png";
import empty_session_1_icon from "../assets/icons/empty_session_1_icon.svg";
import sort_icon from "../assets/icons/sort_icon.svg";
import filter_icon from "../assets/icons/filter_icon.svg";
import date_icon from "../assets/icons/date_icon.svg";

// sweetalert
import Swal from "sweetalert2";

// moment.js
import moment from "moment";

// components
import HeaderComponent from "../components/HeaderComponent";
import Pagination from "../components/layout/Pagination";
import TableInternalComponent from "../components/TableInternalComponent";
import ModalComponent from "../components/ModalComponent";
import CustomDropdown from "../components/input/CustomDropdown";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import FilterModal from "../components/FilterModal";
import SortModal from "../components/SortModal";
import DateTimeInput from "../components/input/DateTimeInput";

// Apis
import {
  getAllSessions,
  getDetailInternalBooking,
  updateSession,
} from "../apis/internalApis/sessions";
import {
  getAllMentors,
  getAllTimeMentorSchedules,
} from "../apis/clientApis/mentors";
import { getAllTopics } from "../apis/topics";

const InternalBookings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });

  const filterKeyInitialState = {
    status: [],
    topic: [],
    dateTime: {},
    mentor: [],
  };

  const scheduleModal = useSelector((state) => state.scheduleModal);
  const approvalModal = useSelector((state) => state.approvalModal);
  const declineModal = useSelector((state) => state.declineModal);
  const assignmentMentorModal = useSelector(
    (state) => state.assignmentMentorModal
  );
  const assignmentScheduleModal = useSelector(
    (state) => state.assignmentScheduleModal
  );
  const table_ID_internal = useSelector((state) => state.internal_table_value);
  const userDetail = useSelector((state) => state.userDetail);
  const searchKey = useSelector((state) => state.searchKey);

  const [isLoading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [topics, setTopics] = useState([]);
  const [mentors, setMentors] = useState([]);

  const [isFilterModalOpened, setFilterModalOpened] = useState(false);
  const [isSortModalOpened, setSortModalOpened] = useState(false);
  const [isFilterChanged, setFilterChanged] = useState(false);
  const [filterKey, setFilterKey] = useState(filterKeyInitialState);
  const [sortKey, setSortKey] = useState({ newest: false, oldest: false });

  const [data, setData] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [pageCount, setPageCount] = useState(0);

  const [assignedMentor, setAssignedMentor] = useState("");
  const [handleMentor, setHandleMentor] = useState(false);
  const [isMentorAssigned, setMentorAssigned] = useState(false);
  const [onlineMeeting, setOnlineMeeting] = useState("");
  const [isOnlineMeeting, setIsOnlineMeeting] = useState(false);

  const [selectedTopicId, setSelectedTopicId] = useState(null);
  const [combined_mentors_name, setCombinedMentorsName] = useState([]);
  const [scheduleValueDate, setScheduleValueDate] = useState(null);

  // Change route to details
  function handleOnClickRow(d, key, value) {
    if (key !== "feedback") {
      if (d.status === "Complete your data") {
        // navigate(`/coaching/book?booking_id=${d.booking_id}`);
      } else {
        navigate(`/internal/bookings/${d["booking_id"]}`);
      }
    }
  }

  // Set Table ID for modal
  function setTableID(d, key, value) {
    dispatch(SET_INTERNAL_TABLE_VALUE(d["booking_id"]));
  }

  // Close modal
  function handleShowModalFalse() {
    dispatch(SET_SCHEDULE_MODAL_FALSE());
    dispatch(SET_APPROVAL_MODAL_FALSE());
    dispatch(SET_DECLINE_MODAL_FALSE());
    dispatch(SET_ASSIGN_MENTOR_MODAL_FALSE());
    dispatch(SET_ASSIGN_SCHEDULE_MODAL_FALSE());
  }

  // Handle topic Entrepreneurship
  async function handleApprovalPrimary(
    isMentorAssignment,
    isScheduleAssignment
  ) {
    setLoading(true);
    try {
      const response = await getDetailInternalBooking(table_ID_internal);
      if (
        response &&
        response.data &&
        response.data.session &&
        response.data.session.id
      ) {
        const sessionId = response.data.session.id;

        let data;
        if (isMentorAssignment || isScheduleAssignment) {
          if (isMentorAssignment) {
            // assign coaching session's mentor & update coaching session's status to waiting for schedule
            let mentor_id = combined_mentors_name.filter((mentor) => {
              if (mentor.assignedMentor === assignedMentor) return mentor;
            });
            mentor_id = mentor_id[0].id;
            data = { mentor_id, status: "Waiting for schedule" };
          } else {
            // assign coaching session's schedule & update coaching session's status to Completed
            data = {
              scheduled_date: new Date(scheduleValueDate.valueToSend),
              status: "Completed",
            };
          }
        } else {
          // approve coaching session & update coaching session's status to awaiting for mentor assignment
          data = { status: "Awaiting mentor assignment" };
        }

        const result = await updateSession(sessionId, data);
        if (result) {
          setLoading(false);
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: "Successfully update session",
            width: "20em",
            heightAuto: "0.5em",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    } catch (err) {
      console.log(err, "error line 198 internalBookings.js <<");
    } finally {
      setLoading(false);
      handleFetchUsersSessions();
      handleShowModalFalse();
    }
  }

  // Handle decline
  async function handleDeclinePrimary() {
    setLoading(true);
    try {
      const response = await getDetailInternalBooking(table_ID_internal);
      if (
        response &&
        response.data &&
        response.data.session &&
        response.data.session.id
      ) {
        const sessionId = response.data.session.id;
        const data = { status: "Declined" };
        const result = await updateSession(sessionId, data);
        if (result) {
          setLoading(false);
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: "Successfully update session",
            width: "20em",
            heightAuto: "0.5em",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    } catch (err) {
      console.log(err, "error line 238 internalBookings.js <<");
    } finally {
      setLoading(false);
      handleFetchUsersSessions();
      handleShowModalFalse();
    }
  }

  // Cancel modal
  async function handleApprovalSecondary() {
    try {
      dispatch(SET_SCHEDULE_MODAL_FALSE());
      dispatch(SET_APPROVAL_MODAL_FALSE());
      dispatch(SET_DECLINE_MODAL_FALSE());
    } catch (err) {
      console.log(err, "error line 253 internalBookings.js <<");
    }
  }

  // Handle change dropdown on Schedule Approval
  async function handleChangeDropdown(value, key, isEntrepreneurship) {
    // Assign mentor into state
    setAssignedMentor(value);
    if (!isEntrepreneurship) {
      setLoading(true);
      try {
        if (key) {
          // Check if the name is the same as value
          const mentorID = combined_mentors_name.filter((mentor) => {
            if (mentor.assignedMentor === value) return mentor.id;
          });

          // Get selected sessions
          const response = await getDetailInternalBooking(table_ID_internal);

          // Check if both request is valid
          if (
            response &&
            response.data &&
            response.data.session &&
            response.data.session.id
          ) {
            const sessionId = response.data.session.id;
            const data = { mentor_id: mentorID[0].id };
            // Update session with mentor ID
            const result = await updateSession(sessionId, data);
            if (result) {
              setLoading(false);
              Swal.fire({
                customClass: {
                  popup: "mentor-popup",
                },
                imageUrl:
                  "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
                position: "top-end",
                text: "Successfully update session",
                width: "20em",
                heightAuto: "0.5em",
                showConfirmButton: false,
                timer: 1500,
              });
              return setHandleMentor(true);
            }
          } else {
            setLoading(false);
            console.log("Request not valid");
          }
        }
      } catch (err) {
        console.log(err, "error line 307 internalBookings.js <<");
        setLoading(false);
      }
    }
  }

  // Handle onChange online meeting
  async function handleOnlineMeeting(event) {
    try {
      // Check link with pattern
      const pattern =
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
      const isUrl = pattern.test(event.target.value);

      // Check if online meeting has been filled
      if (
        event.target.value !== "" ||
        event.target.value !== undefined ||
        event.target.value !== null
      ) {
        if (isUrl) {
          setIsOnlineMeeting(true);
          setOnlineMeeting(event.target.value);
        } else {
          setIsOnlineMeeting(false);
          console.log("Please insert your online meeting link - 2");
        }
      } else {
        setIsOnlineMeeting(false);
        console.log("Please insert your online meeting link - 1");
      }
    } catch (err) {
      console.log(err, "error line 339 internalBookings.js <<");
    }
  }

  // Handle next button 'Schedule Approval'
  function handleNextModal() {
    if (handleMentor) {
      setMentorAssigned(true);
    } else {
      console.log("Fail to click next");
    }
  }

  // Handle back buton 'Schedule Approval'
  function handleBackButton() {
    setMentorAssigned(false);
  }

  // Handle approve topic Non-Entrepreneurship
  async function handleSubmitButton() {
    // Handle submit button 'Schedule Approval'
    setLoading(true);
    try {
      if (isOnlineMeeting) {
        // Get selected sessions
        const response = await getDetailInternalBooking(table_ID_internal);

        if (
          response &&
          response.data &&
          response.data.session &&
          response.data.session.id
        ) {
          const sessionId = response.data.session.id;
          const data = {
            external_link: onlineMeeting,
            status: "Confirmed",
          };
          const result = await updateSession(sessionId, data);
          if (result) {
            Swal.fire({
              customClass: {
                popup: "mentor-popup",
              },
              imageUrl:
                "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
              position: "top-end",
              text: "Successfully update session",
              width: "20em",
              heightAuto: "0.5em",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Failed to submit",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Failed to find session",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Please insert your link",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      console.log(err, "error line 420 internalBookings.js <<");
    } finally {
      setLoading(false);
      handleFetchUsersSessions();
      handleShowModalFalse();
    }
  }

  // Pagination - 1
  function handlePageClick(e) {
    const newOffset = (e.selected * perPage) % data.length;
    setItemOffset(newOffset);
  }

  const handleFetchUsersSessions = async () => {
    setLoading(true);
    const result = await getAllSessions();
    if (result && result.data) {
      let arrSessions = result.data;
      setSessions(arrSessions);
      handleFormatFirstFetchPaginatedData(arrSessions);
    }
    setLoading(false);
  };

  const handleFetchTopics = async () => {
    setLoading(true);
    const result = await getAllTopics();
    if (result && result.data) {
      setTopics(result.data);
    }
    setLoading(false);
  };

  const handleFetchMentors = async () => {
    setLoading(true);
    const isMentor =
      userDetail &&
      userDetail.role &&
      userDetail.role.id &&
      +userDetail.role.id === 4;

    let finalMentors;
    if (isMentor) {
      const mentor = userDetail;
      finalMentors = [mentor];
    } else {
      const result = await getAllMentors();
      if (result && result.data) {
        finalMentors = result && result.data;
      }
    }
    setMentors(finalMentors);
    setLoading(false);
  };

  const handleFormatFirstFetchPaginatedData = (sessions, searchKey) => {
    let initialDataState = sessions
      .sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      })
      .map(
        ({
          booking_id,
          topic_id,
          scheduled_date,
          mentor,
          status,
          user_id,
          createdAt,
        }) => {
          const assignedMentor = mentor
            ? mentor?.user_details?.first_name +
              " " +
              mentor?.user_details?.last_name
            : "Not available";
          let finalTopicId = topics.filter((topic) => topic_id === topic.id);
          return {
            booking_id,
            topic_id:
              finalTopicId && finalTopicId[0] && finalTopicId[0].title
                ? finalTopicId[0].title
                : topic_id,
            scheduled_date,
            assignedMentor,
            duration: "60",
            booking_created: createdAt,
            status,
            user_id,
            isOpenApproval: false,
          };
        }
      );
    if (searchKey) {
      initialDataState = initialDataState.filter(
        (data) =>
          data.booking_id.toLowerCase().includes(searchKey.toLowerCase()) ||
          data.assignedMentor.toLowerCase().includes(searchKey.toLowerCase()) ||
          data.topic_id
            .toString()
            .toLowerCase()
            .includes(searchKey.toLowerCase())
      );
    }
    setData(initialDataState);
    const endOffset = itemOffset + perPage;
    setPaginatedData(initialDataState.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
  };

  function handleOnClickFilterButton() {
    setFilterModalOpened(true);
  }

  function handleOnClickSortButton() {
    setSortModalOpened(true);
  }

  function handleOnFilter(type, name, value) {
    setFilterChanged(true);
    switch (type) {
      case "status":
        if (value) {
          setFilterKey((prev) => ({ ...prev, status: [...prev.status, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            status: prev.status.filter((d) => d !== name),
          }));
        }
        break;
      case "topic":
        if (value) {
          setFilterKey((prev) => ({ ...prev, topic: [...prev.topic, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            topic: prev.topic.filter((d) => d !== name),
          }));
        }
        break;
      case "date-time":
        if (value) {
          setFilterKey((prev) => ({ ...prev, dateTime: value }));
        }
        break;
      case "mentor":
        if (value) {
          setFilterKey((prev) => ({ ...prev, mentor: [...prev.mentor, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            mentor: prev.mentor.filter((d) => d !== name),
          }));
        }
        break;
      case "reset":
        setFilterKey(filterKeyInitialState);
        break;
      case "apply-filter":
        handleApplyFilter();
        setFilterModalOpened(false);
        break;
      default:
        break;
    }
  }

  function handleOnSort(type) {
    switch (type) {
      case "newest":
        const sortedData = initialDataState.sort((a, b) => {
          return new Date(b.booking_created) - new Date(a.booking_created);
        });
        setData(sortedData);
        setSortKey((prev) => ({ ...prev, newest: true, oldest: false }));
        break;
      case "oldest":
        const sortedData2 = initialDataState.sort((a, b) => {
          return new Date(a.booking_created) - new Date(b.booking_created);
        });
        setData(sortedData2);
        setSortKey((prev) => ({ ...prev, newest: false, oldest: true }));
        break;
      default:
        break;
    }
    setSortModalOpened(false);
  }

  function handleApplyFilter() {
    if (
      Object.keys(filterKey).some(
        (key) =>
          filterKey[key].length > 0 ||
          (filterKey[key].from && filterKey[key].to)
      )
    ) {
      const filteredData = initialDataState.filter((d) => {
        let isValid = true;
        if (filterKey.status.length > 0) {
          isValid = filterKey.status.includes(d.status);
        }
        if (filterKey.topic.length > 0) {
          isValid = filterKey.topic.includes(d.topic_id.toString());
        }
        if (filterKey.mentor.length > 0) {
          isValid = filterKey.mentor.includes(d.assignedMentor);
        }
        if (filterKey.dateTime.from && filterKey.dateTime.to) {
          let date = moment(new Date(d.scheduled_date));
          let from = moment(new Date(filterKey.dateTime.from));
          let to = moment(new Date(filterKey.dateTime.to)).add(1, "days");

          isValid = moment(date).isBetween(from, to);
        }
        return isValid;
      });
      setData(filteredData);
    } else {
      setData(initialDataState);
    }
    setFilterChanged(false);
  }
  const handleFilterBySearchKey = (searchKey) => {
    if (!searchKey) {
      handleFormatFirstFetchPaginatedData(sessions);
    } else {
      handleFormatFirstFetchPaginatedData(sessions, searchKey);
    }
  };

  const getBookingTopic = async (table_ID_internal) => {
    const response = await getDetailInternalBooking(table_ID_internal);
    if (
      response &&
      response.data &&
      response.data.session &&
      response.data.session.id
    ) {
      const { topic_id } = response.data.session;
      if (topic_id) {
        setSelectedTopicId(topic_id);

        const combined_mentors_name = mentors
          .filter((mentor) => mentor.user_details)
          .filter((mentor) => {
            const { topics } = mentor || {};
            let arrMentorTopicIds =
              topics && Array.isArray(topics) && topics.length > 0
                ? topics.map((topic) => topic.id)
                : [];
            if (arrMentorTopicIds.includes(topic_id)) {
              return mentor;
            }
          })
          .map((mentor) => {
            const { id, user_details: { first_name, last_name } = {} } =
              mentor || {};
            const assignedMentor =
              first_name && last_name ? first_name + " " + last_name : null;

            return {
              id,
              assignedMentor,
              first_name,
              last_name,
            };
          });
        setCombinedMentorsName(combined_mentors_name);
      }
    }
  };

  const handleOpenModalApproval = async (selectedData) => {
    let finalPaginatedData = paginatedData.map((data) => {
      if (data.booking_id === selectedData.booking_id) {
        data.isOpenApproval = !data.isOpenApproval;
      } else {
        data.isOpenApproval = false;
      }
      return data;
    });
    setPaginatedData(finalPaginatedData);
  };

  const handleOnChangeSchedule = (value) => {
    setScheduleValueDate({
      valueToSend: value,
      valueToView: format(value, "dd-MM-y", new Date()),
    });
  };

  useEffect(() => {
    if (userDetail && userDetail.id) {
      handleFetchUsersSessions();
      handleFetchTopics();
      handleFetchMentors();
    }
  }, [userDetail]);

  useEffect(() => {
    if (sessions && Array.isArray(sessions) && sessions.length > 0) {
      handleFormatFirstFetchPaginatedData(sessions);
    }
  }, [sessions]);

  // Pagination - 2
  useEffect(() => {
    const endOffset = itemOffset + perPage;
    setPaginatedData(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, itemOffset, perPage]);

  useEffect(() => {
    handleFilterBySearchKey(searchKey);
  }, [searchKey]);

  useEffect(() => {
    if (table_ID_internal) {
      getBookingTopic(table_ID_internal);
    }
  }, [table_ID_internal]);

  const initialDataState = sessions
    .sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    })
    .map(
      ({
        booking_id,
        topic_id,
        scheduled_date,
        mentor,
        duration,
        status,
        user_id,
        createdAt,
      }) => {
        const assignedMentor = mentor
          ? mentor?.user_details?.first_name +
            " " +
            mentor?.user_details?.last_name
          : "Not available";
        return {
          booking_id,
          topic_id,
          scheduled_date,
          assignedMentor,
          duration: "60",
          booking_created: createdAt,
          status,
          user_id,
          isOpenApproval: false,
        };
      }
    );

  const statusList = [
    "Confirmed",
    "Waiting for Confirmation",
    "Complete your data",
    "Cancelled",
    "In review",
    "Confirm your schedule",
    "Completed",
  ];

  const mentorList = mentors
    .filter((mentor) => mentor.user_details)
    .map((mentor) => {
      const { user_details } = mentor || {};
      return user_details.first_name + " " + user_details.last_name;
    });

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      <SpinnerComponent
        isShown={isLoading}
        styles={{
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "290px",
        }}
      />
      {!isLoading &&
      sessions &&
      Array.isArray(sessions) &&
      sessions.length > 0 ? (
        <>
          <OuterContainer>
            <InnerContainer>
              <ButtonContainer>
                <FilterSortButton onClick={handleOnClickSortButton}>
                  <img
                    src={sort_icon}
                    alt="sort_icon"
                    height={15}
                    width={15}
                    style={{ objectFit: "scale-down" }}
                  />
                  Sort
                </FilterSortButton>
                <FilterSortButton onClick={handleOnClickFilterButton}>
                  <img
                    src={filter_icon}
                    alt="filter_icon"
                    height={15}
                    width={15}
                    style={{ objectFit: "scale-down" }}
                  />
                  Filter
                </FilterSortButton>
              </ButtonContainer>
              <FilterModal
                show={isFilterModalOpened.toString()}
                handleClose={() => {
                  setFilterModalOpened(false);
                  setFilterChanged(false);
                }}
                statusList={statusList}
                topics={topics}
                mentorList={mentorList}
                filterKey={filterKey}
                handleOnFilter={handleOnFilter}
                dateTimeButtonList={["This Week", "This Month"]}
                isFilterChanged={isFilterChanged}
                user_detail={userDetail}
                filterStatus
                filterTopic
                filterDateTime
                filterMentor
              />
              <SortModal
                show={isSortModalOpened.toString()}
                handleClose={() => {
                  setSortModalOpened(false);
                }}
                handleOnSort={handleOnSort}
                sortKey={sortKey}
                user_detail={userDetail}
              />
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                itemOffset={itemOffset + 1}
                endOffset={itemOffset + perPage}
                totalItems={initialDataState.length}
                hide={data.length <= perPage}
              >
                <TableInternalComponent
                  table_data={paginatedData}
                  table_name={"internalBooking"}
                  topics={topics}
                  onClickRow={handleOnClickRow}
                  setTableID={setTableID}
                  handleOpenModalApproval={handleOpenModalApproval}
                />
              </Pagination>
            </InnerContainer>
          </OuterContainer>
          <ModalComponent
            show={scheduleModal}
            handleClose={handleShowModalFalse}
            body={
              isMentorAssigned
                ? [
                    <p style={{ fontWeight: 700 }} key={0}>
                      {table_ID_internal} has been successfully approved
                    </p>,
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: "25px",
                        color: "#007C7C",
                      }}
                      key={1}
                    >
                      Add online meeting link
                    </p>,
                    <p style={{ fontSize: "14px", color: "#00BB99" }} key={2}>
                      Step 2/2
                    </p>,
                    <textarea
                      key={3}
                      onChange={handleOnlineMeeting}
                      placeholder="Please input the online meeting link here..."
                      style={{
                        padding: "0.3em",
                        width: "70%",
                        minHeight: "150px",
                        border: "2px solid #00BB99",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                    />,
                    <div key={4}>
                      {isOnlineMeeting && (
                        <ModalSuccessAlert>
                          <img
                            src={assigned_mentor_icon}
                            alt="assigned_mentor_icon"
                            height={25}
                            width={25}
                            style={{ objectFit: "scale-down" }}
                          />
                          <ModalSuccessText>
                            Online meeting link is valid. Click submit to
                            continue
                          </ModalSuccessText>
                        </ModalSuccessAlert>
                      )}
                    </div>,
                    <ModalButtonContainer key={5}>
                      <ModalButtonCancel onClick={handleBackButton}>
                        Back
                      </ModalButtonCancel>
                      <ModalButton
                        onClick={handleSubmitButton}
                        disabled={!isOnlineMeeting}
                      >
                        Submit
                      </ModalButton>
                    </ModalButtonContainer>,
                  ]
                : [
                    <p style={{ fontWeight: 700 }} key={0}>
                      {table_ID_internal} has been successfully approved
                    </p>,
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: "25px",
                        color: "#007C7C",
                      }}
                      key={1}
                    >
                      Assign mentor
                    </p>,
                    <p style={{ fontSize: "14px", color: "#00BB99" }} key={2}>
                      Step 1/2
                    </p>,
                    <CustomDropdown
                      key={3}
                      value={assignedMentor}
                      placeholder={
                        combined_mentors_name.length > 0
                          ? "Select one of mentor available"
                          : "No mentor available for this topic"
                      }
                      padding="50px"
                      titleKey="assignedMentor"
                      options={combined_mentors_name}
                      action={(value) =>
                        handleChangeDropdown(value, "assignedMentor")
                      }
                      selectedKey="assignedMentor"
                      imageFile={add_mentor_icon}
                      disabled={combined_mentors_name.length <= 0}
                    />,
                    <div key={4}>
                      {assignedMentor && (
                        <ModalSuccessAlert>
                          <img
                            src={assigned_mentor_icon}
                            alt="assigned_mentor_icon"
                            height={25}
                            width={25}
                            style={{ objectFit: "scale-down" }}
                          />
                          <ModalSuccessText>
                            Succesfully assigned &quot;{assignedMentor}&quot; as
                            the mentor
                          </ModalSuccessText>
                        </ModalSuccessAlert>
                      )}
                    </div>,
                    <ModalButtonContainer key={5}>
                      <ModalButtonCancel onClick={handleShowModalFalse}>
                        Cancel
                      </ModalButtonCancel>
                      <ModalButton
                        onClick={handleNextModal}
                        disabled={
                          assignedMentor === "" ||
                          assignedMentor === undefined ||
                          assignedMentor === null
                        }
                      >
                        Next
                      </ModalButton>
                    </ModalButtonContainer>,
                  ]
            }
          />
          <ModalComponent
            show={approvalModal}
            handleClose={handleShowModalFalse}
            body={[
              <p style={{ fontWeight: 700 }} key={0}>
                Are you sure you want to approve {table_ID_internal}?
              </p>,
            ]}
            buttonPrimary="Yes"
            buttonSecondary="No"
            onPrimaryClick={() => handleApprovalPrimary(false, false)}
            onSecondaryClick={handleApprovalSecondary}
          />
          <ModalComponent
            show={declineModal}
            handleClose={handleShowModalFalse}
            body={[
              <p style={{ fontWeight: 700 }} key={0}>
                Are you sure you want to decline {table_ID_internal}?{" "}
              </p>,
              <p style={{ fontWeight: 700 }} key={1}>
                <strong>The change can&apos; be reverted</strong>
              </p>,
            ]}
            buttonPrimary="Yes"
            buttonSecondary="No"
            onPrimaryClick={handleDeclinePrimary}
            onSecondaryClick={handleApprovalSecondary}
          />
          <ModalComponent
            show={assignmentMentorModal}
            handleClose={handleShowModalFalse}
            body={[
              <p style={{ fontWeight: 700 }} key={0}>
                {table_ID_internal} has been successfully approved
              </p>,
              <p
                style={{
                  fontWeight: 700,
                  fontSize: "25px",
                  color: "#007C7C",
                }}
                key={1}
              >
                Assign mentor
              </p>,
              <CustomDropdown
                key={3}
                value={assignedMentor}
                placeholder={
                  combined_mentors_name.length > 0
                    ? "Select one of mentor available"
                    : "No mentor available for this topic"
                }
                padding="50px"
                titleKey="assignedMentor"
                options={combined_mentors_name}
                action={(value) =>
                  handleChangeDropdown(value, "assignedMentor", true)
                }
                selectedKey="assignedMentor"
                imageFile={add_mentor_icon}
                disabled={combined_mentors_name.length <= 0}
              />,
              <ModalButtonContainer key={5}>
                <ModalButtonCancel onClick={handleShowModalFalse}>
                  Cancel
                </ModalButtonCancel>
                <ModalButton
                  onClick={() => handleApprovalPrimary(true, false)}
                  disabled={
                    assignedMentor === "" ||
                    assignedMentor === undefined ||
                    assignedMentor === null
                  }
                >
                  Submit
                </ModalButton>
              </ModalButtonContainer>,
            ]}
          />
          <ModalComponent
            show={assignmentScheduleModal}
            handleClose={handleShowModalFalse}
            body={[
              <p style={{ fontWeight: 700 }} key={0}>
                {table_ID_internal}
              </p>,
              <p
                style={{
                  fontWeight: 700,
                  fontSize: "25px",
                  color: "#007C7C",
                }}
                key={1}
              >
                Assign schedule
              </p>,
              <div
                key={3}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  marginLeft: "2em",
                  marginRight: "2em",
                }}
              >
                <InputLabel>Select Date</InputLabel>
                <DateTimeInput
                  data={[]}
                  placeholder="DD-MM-YYYY"
                  icon={date_icon}
                  value="date"
                  handleSelectDate={(value) => handleOnChangeSchedule(value)}
                  inputValueDate={
                    (scheduleValueDate && scheduleValueDate.valueToView) || null
                  }
                  smallScreen={smallScreen}
                  customInputWidth={"20vw"}
                />
              </div>,
              <ModalButtonContainer key={5}>
                <ModalButtonCancel onClick={handleShowModalFalse}>
                  Cancel
                </ModalButtonCancel>
                <ModalButton
                  onClick={() => handleApprovalPrimary(false, true)}
                  disabled={!scheduleValueDate}
                >
                  Submit
                </ModalButton>
              </ModalButtonContainer>,
            ]}
          />
        </>
      ) : !isLoading ? (
        <div style={{ paddingLeft: "290px" }}>
          <div className="myBookingInnerContainerEmptySession">
            <img src={empty_session_1_icon} alt="empty_session_1_icon" />
            <h4 style={{ fontSize: "22px" }}>There is no data.</h4>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InternalBookings;

const OuterContainer = styled.div`
  padding-left: 290px;
`;

const InnerContainer = styled.div`
  padding: 130px 3vw 30px 3vw;
  text-align: left;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3em 2em 0em 2em;
`;

const ModalButtonCancel = styled.p`
  font-size: 14px;
  cursor: pointer;
`;

const ModalButton = styled.button`
  background: #00bb99;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  border-radius: 40px;
  border: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  padding: 0.5em 1.5em;
`;

const ModalSuccessAlert = styled.div`
  display: flex;
  align-items: center;
  margin: 2em;
  font-weight: 700;
  padding: 1em;
  background: #89e9d7;
  text-align: left;
`;

const ModalSuccessText = styled.p`
  margin-left: 0.5em;
  margin-bottom: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FilterSortButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: black solid 1px;
  background-color: #f0f8ff;
  color: black;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  user-select: none;
  border-radius: 10px;
  :hover {
    filter: brightness(95%);
  }
  z-index: ${({ show }) => (show ? "2000" : "1")};
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
  font-size: ${fontSize.title2};
  font-weight: bold;
`;
